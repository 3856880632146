import { notification } from "antd";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";

interface IProps {
    time: string;
    setTime: (newTime: string) => void;
}
const AthleteTime: React.FC<IProps> = ({ time, setTime }) => {
    const [editMode, setEditMode] = useState(false);
    const [inputTime, setInputTime] = useState(time);
    let myRef: React.RefObject<HTMLInputElement> = useRef(null); // инпут
    const caretPosition = useRef(0); // для передачи позиции курсора при перерендере
    let isEdit = useRef(false); // определяет позицию курсора внутри или в конце ввода
    const [counter, setCounter] = useState(0); // нужен для перерендера если ввод совпал, чтоб курсор не улетал
    let keyPress: string;
    let newValue;

    const regex = new RegExp(/^\d{0,6}$/g);

    // после рендера в input сбрасывается в конец курсор, поэтому мы его устанавливаем тут
    useEffect(() => {
        if (myRef?.current && isEdit.current) {
            myRef?.current.setSelectionRange(
                caretPosition.current,
                caretPosition.current
            );
        }
    }, [counter]);

    const mask = (str: string) => {
        let maskStr = "";
        for (let i = 0; i < str.length; i++) {
            if (i % 2 === 0 && i !== 0) {
                maskStr = maskStr.concat(`:`);
            }
            maskStr = maskStr.concat(str[i]);
        }
        if (str.length === 2 || str.length === 4) maskStr = maskStr.concat(`:`);
        return maskStr;
    };

    const unMask = (str: string) => {
        let unMaskStr = str.replace(new RegExp(/[^\d]/, "g"), ""); // Remove every non-digit character
        return unMaskStr;
    };

    const removeCharAt = function (str: string, i: number) {
        // i - порядковый номер символа в строке(не индекс а на 1 больше)
        let tmp = str.split(""); // convert to an array
        tmp.splice(i - 1, 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        return tmp.join(""); // reconstruct the string
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        keyPress = e.key;
        if (e.key === "Enter" || e.key === "Escape") {
            e.preventDefault();
            if (validateInput(inputTime)) {
                setTime(inputTime);
                setEditMode(false);
            } else {
                notification.warn({
                    message: "Время не соответствует формату ЧЧ:ММ:СС",
                });
                if (e.key === "Escape") {
                    setInputTime(time);
                    setEditMode(false);
                }
            }
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (validateInput(inputTime)) {
            setTime(inputTime);
            setEditMode(false);
        } else {
            notification.warn({
                message: "Время не соответствует формату ЧЧ:ММ:СС",
            });
        }
    };

    const validateInput = (value: string) => {
        return /^\d\d:\d\d:\d\d$/.test(value);
    };

    const handleTimeInput = (e: ChangeEvent<HTMLInputElement>): void => {
        newValue = e.target.value;

        // Если позиция не в конце, то редактирование - надо сохранять позицию.
        if (myRef?.current?.selectionStart) {
            isEdit.current = myRef?.current.selectionStart <= inputTime.length;
        }

        if (isEdit.current && myRef?.current?.selectionStart) {
            // Если стираем то
            if (keyPress === "Backspace") {
                setInputTime(newValue);
                caretPosition.current = myRef.current.selectionStart;
                setCounter((c) => c + 1);
                return;
            }

            // Если не цифра то ничего не делаем, просто сбрасываем позицию курсора на 1.
            if (!/[\d]/.test(newValue[myRef.current.selectionStart - 1])) {
                caretPosition.current = myRef.current.selectionStart - 1;
                setCounter((c) => c + 1);
                return;
            }

            // Если цифра то удаляем следующую, оставшуюся от предыдущего значения
            if (inputTime[myRef.current.selectionStart - 1] === ":") {
                myRef.current.selectionStart += 1;
            }
            newValue = removeCharAt(newValue, myRef.current.selectionStart + 1);
            caretPosition.current = myRef.current.selectionStart;
            newValue = unMask(newValue);

            if (isValidTime(newValue)) {
                // Если ввод валидный (минуты меньше 60 и тд) сохраняем
                newValue = mask(newValue);
                setInputTime(newValue);
            } else {
                // Иначе не даем вводить
                caretPosition.current = myRef.current.selectionStart - 1;
            }
            setCounter((c) => c + 1);
        } else {
            newValue = unMask(newValue);
            if (isValidTime(newValue)) {
                newValue = mask(newValue);
                // setCounter((c) => c + 1); здесь нам не нужен ререндер так как курсор в конце.
                setInputTime(newValue);
            }
        }
    };

    const isValidTime = (time: string): boolean => {
        console.log("ISVALIDTIME ", time, time.slice(4, 6));
        if (
            time.match(regex) &&
            (time.slice(0, 2) === "" || Number(time.slice(0, 2)) < 24) &&
            (time.slice(2, 4) === "" || Number(time.slice(2, 4)) < 60) &&
            (time.slice(4, 6) === "" || Number(time.slice(4, 6)) < 60)
        )
            return true;
        return false;
    };

    return editMode ? (
        <div className={styles.wrapper}>
            {/* <label htmlFor="athlete-result-change">Результат: </label> */}
            <input
                type="text"
                id="athlete-result-change"
                autoFocus
                ref={myRef}
                value={inputTime}
                onChange={handleTimeInput}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
            />
        </div>
    ) : (
        <div
            className={styles.wrapper}
            onClick={() => {
                setEditMode(true);
            }}
        >
            {time}
        </div>
    );
};

export default AthleteTime;
