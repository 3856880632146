import React from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { setCurrentRow, setEditRow } from "../../../store/results/slice";
import {
    getCurrentRow,
    getEditRow,
    getIsResultCountExceeded,
    getIsUniqueID,
} from "../../../store/results/selectors";
import { TResultType } from "../../../store/results/types";
import EditRow from "../EditRow/EditRow";
import CurrentRow from "../CurrentRow/CurrentRow";
import styles from "./index.module.scss";
import { ID_UNKNOWN } from "../../../const";
import { isTimeGTMintime } from "../../../utils/addTime";

interface IProps {
    result: TResultType;
}

export enum ColorStatus {
    green = "green",
    yellow = "yellow",
    red = "red",
}

const Row: React.FC<IProps> = ({ result }) => {
    const currentRow = useAppSelector(getCurrentRow);
    const editRow = useAppSelector(getEditRow);
    const isUniqeId = useAppSelector((state) =>
        getIsUniqueID(state, result.athlete_id)
    );
    const isAllowedResultCountExceeded = useAppSelector((state) =>
        getIsResultCountExceeded(state, result.athlete_id)
    );
    const dispatch = useAppDispatch();
    const isCurrentRow = currentRow === result.position - 1;
    const isEditRow = editRow === result.position - 1;

    let colorStatus: ColorStatus;

    if (isAllowedResultCountExceeded || !isTimeGTMintime(result.finish_time)) {
        colorStatus = ColorStatus.red;
    } else if (
        result.finish_time &&
        result.athlete_id &&
        result.athlete_id !== ID_UNKNOWN
    ) {
        colorStatus = ColorStatus.green;
    } else if (result.finish_time) {
        colorStatus = ColorStatus.yellow;
    } else {
        colorStatus = ColorStatus.red;
    }

    if (result.athlete_id && result.athlete_id !== ID_UNKNOWN && !isUniqeId)
        colorStatus = ColorStatus.red;

    const classNames = `${styles.resultRow} ${colorStatus}`;

    const handleRowClick = () => {
        dispatch(setCurrentRow(result.position - 1));
        if (editRow !== null) {
            dispatch(setEditRow(null));
        }
    };

    if (isEditRow) return <EditRow result={result} colorStatus={colorStatus} />;
    if (isCurrentRow)
        return <CurrentRow result={result} colorStatus={colorStatus} />;

    return (
        <tr className={classNames} onClick={handleRowClick}>
            <td className={styles.position}>{result.position}</td>
            <td className={styles.result}>{result.finish_time}</td>
            <td className={styles.id}>
                {result.athlete_id ? `A${result.athlete_id}` : null}
            </td>
            <td className={styles.name}>{result.full_name}</td>
        </tr>
    );
};

export default Row;
