import { State } from "../../types/state";
import { NameSpace } from "../root-reducer";

export const getCancelEventLoading = (state: State) =>
  state[NameSpace.cancelEvent].loading;
export const getCancelEventDate = (state: State) =>
  state[NameSpace.cancelEvent].event_date;
export const getCancelEventId = (state: State) =>
  state[NameSpace.cancelEvent].event_id;
export const getCancelReason = (state: State) =>
  state[NameSpace.cancelEvent].cancelReason;
export const getEventStatus = (state: State) =>
  state[NameSpace.cancelEvent].eventStatus;
