import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  APIRoute,
  DATE_FORMAT,
  EventStatus,
  VolunteersStatusId,
  VOLUNTEERS_CLEAR_MESSAGE,
  VOLUNTEERS_SAVE_MESSAGE,
  VOLUNTEERS_SAVE_WARNING,
  VOLUNTEERS_UPLOAD_MESSAGE,
  WarningMessage,
} from "../../const";
import { api, RootState } from "../root-reducer";
import { notification } from "antd";
import {
  TLoadData,
  TLoadVolunteersResponse,
  TResponse,
  TRolesResponse,
  TSaveDataArg,
  TSaveVolunteersResponse,
  TVolunteerSummaryResponse,
} from "./types";
import {
  resetErrorMessage,
  setErrorMessage,
  setIsResultsOnSite,
  setShowSaveResultsModal,
  setTotalEventCount,
  setVolunteerMessage,
  setVolunteersStatus,
} from "./slice";
import { combineVolunteersLists } from "../../components/Volunteers/service";
import {
  compareStrings,
  getNextSaturday,
  getSaturday,
  isToday,
} from "../../utils";
import moment from "moment";
import {
  getInitialVolunteers,
  getIsResultsOnSite,
  getRoles,
} from "./selectors";
import { TLoadResultsResponse } from "../results/types";

export const loadRolesAction = createAsyncThunk(
  "volunteers/loadRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.post<TRolesResponse>(APIRoute.VolunteerRoles);
      return response.data.result.roles.sort((a, b) =>
        compareStrings(a.name, b.name)
      );
    } catch (error: any) {
      notification.error({ message: WarningMessage.RolesLoadFail });
      return rejectWithValue(error.message);
    }
  }
);

export const initVolunteersDateAction = createAsyncThunk(
  "volunteers/initVolunteersDate",
  async (eventId: number, { rejectWithValue, dispatch }) => {
    try {
      // Сначала прошлая суббота, если волонтеры не загружены на сайт
      let initialDate = moment(getSaturday()).format(DATE_FORMAT);
      // Если сегодня день мероприятия то открываем его. Потому что надо править волонтеров
      if (isToday(initialDate)) return initialDate;
      const response = await api.post<TLoadVolunteersResponse>(
        APIRoute.VolunteersLoad,
        { event_id: eventId, event_date: initialDate }
      );
      // Если загружены или была отмена, то выбираем следующую субботу
      if (
        response.data.result.upload_status_id === VolunteersStatusId.Final ||
        response.data.result.status_id === EventStatus.Cancel
      ) {
        return moment(getNextSaturday()).format(DATE_FORMAT);
      } else {
        return initialDate;
      }
    } catch (error: any) {
      console.log("🚀 ~ file: thunk.ts ~ line 68 ~ error", error.message);

      dispatch(setErrorMessage(error.response.data));
      notification.error({ message: WarningMessage.VolunteersLoadFail });
      return rejectWithValue(error.message);
    }
  }
);

export const checkResultsOnSite = createAsyncThunk(
  "volunteers/checkResultsOnSite",
  async (data: TLoadData, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post<TLoadResultsResponse>(
        APIRoute.ResultsLoad,
        {
          event_id: data.event_id,
          date: data.event_date,
        }
      );
      if (response.status === 200) {
        if (
          response.data.result?.results?.length &&
          response.data.result?.results?.length > 0
        ) {
          dispatch(setIsResultsOnSite(true));
        } else {
          dispatch(setIsResultsOnSite(false));
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const loadVolunteersAction = createAsyncThunk(
  "volunteers/loadVolunteers",
  async (data: TLoadData, { rejectWithValue, getState, dispatch }) => {
    try {
      const volResponse = await api.post<TLoadVolunteersResponse>(
        APIRoute.VolunteersLoad,
        data
      );
      const resResponse = await api.post<TLoadResultsResponse>(
        APIRoute.ResultsLoad,
        {
          event_id: data.event_id,
          date: data.event_date,
        }
      );
      const [volunteersResponse, resultResponse] = await Promise.all([
        volResponse,
        resResponse,
      ]);

      dispatch(resetErrorMessage());
      const volunteerPositions = getRoles(getState() as RootState);
      if (volunteerPositions.length === 0) {
        await dispatch(loadRolesAction());
      }
      const initialList = getInitialVolunteers(getState() as RootState);

      if (!volunteersResponse.data.result?.volunteer_list) {
        volunteersResponse.data.result.volunteer_list = initialList;
      } else {
        volunteersResponse.data.result.volunteer_list = combineVolunteersLists(
          volunteersResponse.data.result.volunteer_list,
          initialList
        );
      }
      if (resultResponse.status === 200) {
        if (resultResponse.data.result?.total_count) {
          dispatch(setTotalEventCount(resultResponse.data.result?.total_count));
        } else {
          dispatch(setTotalEventCount(null));
        }
        if (
          resultResponse.data.result?.results?.length &&
          resultResponse.data.result?.results?.length > 0
        ) {
          dispatch(setIsResultsOnSite(true));
        } else {
          dispatch(setIsResultsOnSite(false));
        }
      }

      return volunteersResponse.data.result;
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data));
      notification.error({ message: WarningMessage.VolunteersLoadFail });
      return rejectWithValue(error.message);
    }
  }
);

export const saveVolunteersAction = createAsyncThunk(
  "volunteers/saveVolunteers",
  async (arg: TSaveDataArg, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await api.post<TSaveVolunteersResponse>(
        APIRoute.VolunteersSave,
        arg.data
      );

      if (response.status === 200) {
        if (arg.data.upload_status_id === VolunteersStatusId.Draft) {
          dispatch(
            setVolunteerMessage(
              arg.warning ? VOLUNTEERS_SAVE_WARNING : VOLUNTEERS_SAVE_MESSAGE
            )
          );
          notification.success({
            message: "Список волонтеров сохранён",
          });
        } else {
          dispatch(setVolunteerMessage(VOLUNTEERS_UPLOAD_MESSAGE));
          dispatch(
            loadVolunteersAction({
              event_id: arg.data.event_id,
              event_date: arg.data.date,
            })
          );

          const resultsOnSite = getIsResultsOnSite(getState() as RootState);
          if (!resultsOnSite) {
            dispatch(setShowSaveResultsModal(true));
          } else {
            notification.success({
              message: "Список волонтеров загружен на сайт",
            });
          }
        }
        dispatch(setVolunteersStatus(arg.data.upload_status_id));

        return response.data;
      }
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        if (
          error.response.data.errorMessage ===
          'pq: duplicate key value violates unique constraint "ux__event_volunteers_items__event_volunteers_id__athlete_id__vo"'
        ) {
          notification.error({
            message: "Ошибка: дублирование волонтера",
          });
          return rejectWithValue("Дублирование волонтера");
        } else {
          notification.error({
            message: error.response.data.errorMessage,
          });
          return rejectWithValue(error.response.data.errorMessage);
        }
      }
      notification.error({ message: WarningMessage.VolunteerSaveFail });
      return rejectWithValue(error.message);
    }
  }
);

export const clearVolunteersAction = createAsyncThunk(
  "volunteers/clearVolunteers",
  async (args: TLoadData, { rejectWithValue }) => {
    try {
      const response = await api.post<TResponse>(
        APIRoute.VolunteersClear,
        args
      );
      notification.success({ message: VOLUNTEERS_CLEAR_MESSAGE });
      return response.data;
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        notification.error({
          message: error.response.data.errorMessage,
        });
        return rejectWithValue(error.response.data.errorMessage);
      } else notification.error({ message: error.message });
      return rejectWithValue(error.message);
    }
  }
);

export const getVolunteerSummary = createAsyncThunk(
  "volunteers/getSummary",
  async (verst_id: number, { rejectWithValue }) => {
    try {
      const response = await api.post<TVolunteerSummaryResponse>(
        APIRoute.VolunteerSummary,
        { verst_id: verst_id }
      );
      // notification.success({ message: WarningMessage.VolunteerSummarySuccess });
      return response.data;
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        notification.error({
          message: error.response.data.errorMessage,
        });
        return rejectWithValue(error.response.data.errorMessage);
      } else notification.error({ message: error.message });
      return rejectWithValue(error.message);
    }
  }
);
