import { UnorderedListOutlined } from "@ant-design/icons";
import { Button, notification, Popconfirm } from "antd";
import React from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { saveIdList } from "../../../services/volunteersIdList";
import { getVolunteers } from "../../../store/volunteers/selectors";
import { setInitialPositions } from "../../../store/volunteers/slice";

const ListSetup = () => {
    const volunteers = useAppSelector(getVolunteers);
    const dispatch = useAppDispatch();

    /*   const handleClear = () => {
    dropIdList();
    dispatch(setInitialPositions(INITIAL_POSITIONS));
    notification.success({ message: "Список очищен" });
    setOpen(false);
  }; */
    const handleOk = () => {
        const idList = volunteers.map((volunteer) => volunteer.role_id);
        if (idList.length === 0) {
            notification.error({ message: "Нельзя сохранить пустой список" });
            return;
        }
        saveIdList(idList);
        dispatch(setInitialPositions(idList));
        notification.success({
            message: "Шаблон сохранён на данном устройстве",
        });
    };
    return (
        <>
            <Popconfirm
                title="Сохранить текущий список позиций как шаблон?"
                okText="Да"
                cancelText="Нет"
                placement="leftTop"
                onConfirm={handleOk}
                okButtonProps={{ size: "large" }}
                cancelButtonProps={{ size: "large" }}
            >
                <Button
                    type="text"
                    title="Сохранить шаблон"
                    icon={
                        <UnorderedListOutlined
                            style={{ fontSize: "24px", color: "#444" }}
                        />
                    }
                ></Button>
            </Popconfirm>
        </>
    );
};

export default ListSetup;
