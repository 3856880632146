import { NameSpace } from "../root-reducer";
import { State } from "../../types/state";
import { TVolunteer } from "./types";
import { createSelector } from "@reduxjs/toolkit";
import { EventStatus } from "../../const";
import { isFutureDate } from "../../utils";

export const getVolunteersEventDate = (state: State) =>
    state[NameSpace.volunteers].event_date;
export const getVolunteersLoading = (state: State) =>
    state[NameSpace.volunteers].loading;
export const getVolunteersEventId = (state: State) =>
    state[NameSpace.volunteers].event_id;
export const getRoles = (state: State) => state[NameSpace.volunteers].roles;
export const getVolunteers = (state: State) =>
    state[NameSpace.volunteers].volunteers;
export const getCurrentRow = (state: State) =>
    state[NameSpace.volunteers].currentRow;
export const getVolunteerMessage = (state: State) =>
    state[NameSpace.volunteers].saveMessage;
export const getScrollToIndex = (state: State) =>
    state[NameSpace.volunteers].scrollToIndex;
export const getVolunteersStatus = (state: State) =>
    state[NameSpace.volunteers].upload_status_id;
export const getErrorMessage = (state: State) =>
    state[NameSpace.volunteers].errorMessage;
export const getError = (state: State) => state[NameSpace.volunteers].error;
export const getNeedLoadVolunteers = (state: State) =>
    state[NameSpace.volunteers].needLoadVolunteers;
export const getIsResultsOnSite = (state: State) =>
    state[NameSpace.volunteers].isResultsOnSite;
export const getTotalEventCount = (state: State) =>
    state[NameSpace.volunteers].totalEventCount;
export const getIsEventCanceled = (state: State) =>
    state[NameSpace.volunteers].eventStatusId === EventStatus.Cancel;
export const getEventStatusId = (state: State) =>
    state[NameSpace.volunteers].eventStatusId;
export const getIsInitialVolunteersList = (state: State) =>
    state[NameSpace.volunteers].isInitialVolunteersList;
export const getShowSaveResultsModal = (state: State) =>
    state[NameSpace.volunteers].showSaveResultsModal;
export const getInitialPositions = (state: State) =>
    state[NameSpace.volunteers].initialPositions; //Список ID для инициализации.
export const volunteerSummary = (state: State) =>
    state[NameSpace.volunteers].volunteerSummary;
export const getShowSummary = (state: State) =>
    state[NameSpace.volunteers].showSummary;

/** true когда парк выбран, не будущее время и нет результатов на сайте */
export const getShowResultsNotSavedMessage = (state: State) => {
    return (
        state[NameSpace.volunteers].event_id &&
        state[NameSpace.volunteers].event_date &&
        !isFutureDate(state[NameSpace.volunteers].event_date) &&
        !state[NameSpace.volunteers].isResultsOnSite
    );
};

export const getRoleById = createSelector(
    [getRoles, (_, id: number | null) => id],
    (roles, id) => (id ? roles.find((role) => role.id === id) : null)
);

export const getClearedVolunteers = createSelector(
    getVolunteers,
    (volunteers) =>
        volunteers
            .filter(
                (volunteer) =>
                    volunteer.role_id !== null && volunteer.verst_id !== null
            )
            .map((volunteer) => {
                return {
                    verst_id: volunteer.verst_id,
                    role_id: volunteer.role_id,
                };
            })
);

/** false если id волонтера и id позиции не уникально */
export const getIsUniqueVolunteer = createSelector(
    [getVolunteers, (_, volunteer: TVolunteer) => volunteer],
    (volunteers, volunteer): boolean => {
        if (!volunteer.verst_id) return true;
        if (volunteer) {
            return (
                volunteers.filter(
                    (el) =>
                        volunteer.verst_id === el.verst_id &&
                        volunteer.role_id === el.role_id
                ).length === 1
            );
        } else return false;
    }
);

/* export const getInitialVolunteers = createSelector(getRoles, (roles) =>
  // добавить сортировку позиций?
  INITIAL_POSITIONS.map((positionId) => {
    const positionName = roles.find((role) => role.id === positionId)?.name;
    return {
      role_id: positionId,
      verst_id: null,
      full_name: "",
      role_name: positionName,
    };
  })
); */
export const getInitialVolunteers = createSelector(
    getInitialPositions,
    getRoles,
    (positions, roles) => {
        const list: TVolunteer[] = positions.map((positionId) => {
            const positionName = roles.find(
                (role) => role.id === positionId
            )?.name;
            return {
                role_id: positionId,
                verst_id: null,
                full_name: "",
                role_name: positionName,
            };
        });

        return list;
    }
);
export const getUniqueVolunteersQtt = createSelector(
    getVolunteers,
    (volunteers) => {
        const uniqueVolunteers: TVolunteer[] = [];
        volunteers.forEach((volunteer) => {
            if (
                volunteer.verst_id &&
                uniqueVolunteers.findIndex(
                    (v) => v.verst_id === volunteer.verst_id
                ) === -1
            )
                uniqueVolunteers.push(volunteer);
        });
        return uniqueVolunteers.length;
    }
);
