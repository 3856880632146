import { Button } from "antd";
import React from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { setResults } from "../../../store/results/slice";
import { getResults } from "../../../store/results/selectors";
import {
  deleteCurrentAthleteTime,
  deleteCurrentAthletID,
  deleteCurrentLine,
  InsertAthletID,
  InsertAthletTime,
  InsertLine,
} from "../service";
import { TResultType } from "../../../store/results/types";
import styles from "./index.module.scss";
import DeleteRows from "./DeleteRows/DeleteRows";
import DeleteAthletes from "./DeleteAthletes/DeleteAthletes";
import ResultRow from "./ResultRow/ResultRow";
import { ColorStatus } from "../Row/Row";
import DeleteTimes from "./DeleteTimes/DeleteTimes";
import AddTimes from "./AddTimes/AddTimes";

interface IProps {
  result: TResultType;
  colorStatus: ColorStatus;
}

const EditRow: React.FC<IProps> = ({ result, colorStatus }) => {
  const dispatch = useAppDispatch();
  const results = useAppSelector(getResults);

  const classNames = `${styles.wrapper} ${colorStatus}`;

  // const handleClose = () => {
  //   dispatch(setCurrentRow(editRow));
  //   dispatch(setEditRow(null));
  // };
  const handleDeleteTime = () => {
    const conf = window.confirm(`Удалить время?`);
    if (conf)
      dispatch(setResults(deleteCurrentAthleteTime(results, result.position)));
  };
  const handleDeleteAthlete = () => {
    // dispatch(addHistory()); историю пока не используем
    const conf = window.confirm(`Удалить участника?`);
    if (conf)
      dispatch(setResults(deleteCurrentAthletID(results, result.position)));
    // dispatch(deleteAthleteResult({ position: result.position }));
  };
  const handleDeleteLine = () => {
    const conf = window.confirm(`Удалить строку?`);
    if (conf) dispatch(setResults(deleteCurrentLine(results, result.position)));
  };

  return (
    <tr className={classNames}>
      <td colSpan={4}>
        <ResultRow result={result} />
        <div className="">
          Вы можете изменить время или участника вверху или:
        </div>
        <div className="functions">
          <div>
            {" "}
            <AddTimes />
            <div className="item">
              <span>Удалить данную строку </span>
              <Button size="small" onClick={handleDeleteLine}>
                Выполнить
              </Button>
            </div>
            <DeleteRows />
            <div className="item">
              Добавить строку со сдвигом вниз{" "}
              <Button
                size="small"
                onClick={() => {
                  dispatch(setResults(InsertLine(results, result.position)));
                }}
              >
                Выполнить
              </Button>
            </div>
          </div>
          <div>
            <div className="item">
              Удалить время со сдвигом вверх{" "}
              <Button size="small" onClick={handleDeleteTime}>
                Выполнить
              </Button>
            </div>
            <DeleteTimes />
            <div className="item">
              Добавить время со сдвигом вниз{" "}
              <Button
                size="small"
                onClick={() => {
                  dispatch(
                    setResults(InsertAthletTime(results, result.position))
                  );
                }}
              >
                Выполнить
              </Button>
            </div>
            <div className="item">
              Удалить участника со сдвигом вверх{" "}
              <Button size="small" onClick={handleDeleteAthlete}>
                Выполнить
              </Button>
            </div>
            <DeleteAthletes />

            <div className="item">
              Добавить участника со сдвигом вниз{" "}
              <Button
                size="small"
                onClick={() => {
                  dispatch(
                    setResults(InsertAthletID(results, result.position))
                  );
                }}
              >
                Выполнить
              </Button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default EditRow;
