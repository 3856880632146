import { useEffect } from "react";
import { Link } from "react-router-dom";
import { AppRoute, EXTRA_EVENTS_DATES } from "../../const";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { getIsResultsActive } from "../../store/results/selectors";
import styles from "./index.module.scss";
import { isAnyActiveExtraEvent } from "../../utils/extraEvents";

function MainContent(): JSX.Element {
  const isResultsActive = useAppSelector(getIsResultsActive);
  const showExtraEventsButton = isAnyActiveExtraEvent(EXTRA_EVENTS_DATES);
  useEffect(() => {
    document.title = "NRMS - система обработки результатов";
  }, []);

  return (
    <section className={styles.wrapper}>
      {isResultsActive ? (
        <Link to={AppRoute.Results} className={styles.link}>
          Результаты
        </Link>
      ) : (
        <Link to={AppRoute.ResultsFilesUpload} className={styles.link}>
          Результаты
        </Link>
      )}
      <Link to={AppRoute.Volunteers} className={styles.link}>
        Волонтёры
      </Link>
      <Link to={AppRoute.CancelEvent} className={styles.link}>
        Отмена
      </Link>
      <Link
        to={AppRoute.Incident}
        className={`${styles.link} ${styles.incident}`}
      >
        Инцидент
      </Link>
      {showExtraEventsButton && (
        <Link to={AppRoute.ExtraEvents} className={styles.link}>
          Дополнительные мероприятия
        </Link>
      )}
    </section>
  );
}

export default MainContent;
