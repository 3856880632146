// Локализация момент и календаря через него
export const LOCAL_SETUP = {
  week: {
    dow: 1,
  },
  weekdaysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  monthsShort: {
    format: [
      "янв",
      "февр",
      "мар",
      "апр",
      "мая",
      "юня",
      "юля",
      "вг",
      "сент",
      "окт",
      "нояб",
      "дек.",
    ],
    standalone: [
      "Янв",
      "Февр",
      "Март",
      "Апр",
      "Май",
      "Июнь",
      "Июль",
      "Авг",
      "Сент",
      "Окт",
      "Нояб",
      "Дек",
    ],
  },
};
