import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { EMPTY_ATHLETE } from "../../../const";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getAthleteSearch } from "../../../store/search/selectors";
import { clearSearch } from "../../../store/search/slice";
import { searchByIdPart } from "../../../store/search/thunk";
import { TAthlete } from "../../../store/types";
import { TSearchAthleteOption } from "../../../types/commonTypes";
import { getAthleteSearchOptions } from "../../../utils";
import styles from "./index.module.scss";

interface IProps {
  id: number | null;
  onClose?: () => void;
  onPickAthlete: (athlete: TAthlete) => void;
}

const SearchAthleteById: React.FC<IProps> = ({
  id,
  onClose = () => {},
  onPickAthlete,
}) => {
  const [value, setValue] = useState<string>(id ? id.toString() : "");
  const [options, setOptions] = useState<TSearchAthleteOption[]>([]);
  // Возвращает массив найденных атлетов
  const search = useAppSelector(getAthleteSearch);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (search) {
      setOptions(getAthleteSearchOptions(search));
    } else setOptions([]);
  }, [search]);
  //  удалить?
  useEffect(() => {
    setValue(id?.toString() || "");
  }, [id]);

  const onSearch = (searchText: string) => {
    if (searchText && searchText.length > 3) {
      dispatch(searchByIdPart(parseInt(searchText)));
    } else {
      dispatch(clearSearch());
    }
  };

  const onSelect = (data: string) => {
    const athlete: TAthlete | undefined = search.find(
      (el) => el.id === parseInt(data)
    );
    setValue(data);
    if (athlete) onPickAthlete(athlete);
    onClose();
  };

  const onChange = (data: string) => {
    if (validateIdInput(data)) setValue(data);
  };

  const onBlur = () => {
    dispatch(clearSearch());
    setValue("");
    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (value?.trim().length === 0) {
        // Если пусто то при вводе пустой атлет
        onPickAthlete(EMPTY_ATHLETE);
        onClose();
      } else if (options.length === 1) {
        // Если один выбот то при вводе выбираем его
        onSelect(options[0].value);
      }
    }
    if (e.key === "Escape") {
      setValue(id ? id.toString() : "");
      onClose();
    }
  };

  const validateIdInput = (value: string) => {
    if (value === "") {
      return true;
    }
    return /^\d{1,9}$/.test(value);
  };

  return (
    <div className={styles.idInputWrapper}>
      <AutoComplete
        className={styles.idInput}
        autoFocus
        value={value}
        options={options}
        dropdownMatchSelectWidth={false}
        // style={{ minWidth: 150 }}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        placeholder="1234567"
      />
    </div>
  );
};

export default SearchAthleteById;
