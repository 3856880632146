import { CANCEL_EVENT_REASON_MAX_LENGTH } from "../../const";
import { useAppSelector } from "../../customHooks/useAppSelector";
import {
  getCancelEventDate,
  getCancelEventId,
  getCancelReason,
} from "../../store/cancelEvent/selectors";
import { isValidCancelDate } from "../../utils";

const useCancelData = () => {
  const eventId = useAppSelector(getCancelEventId);
  const eventDate = useAppSelector(getCancelEventDate);
  const cancelReason = useAppSelector(getCancelReason);
  let error = "";
  let isValidCancelData = false;

  if (!eventId || eventId <= 0) {
    error = error.concat("Не выбрано мероприятие");
  }
  if (!eventDate) {
    error = error.concat(
      `${
        error.length > 0
          ? ", не выбрана дата мероприятия"
          : "Не выбрана дата мероприятия"
      }`
    );
  }
  if (isValidCancelDate(eventDate)) {
    error = error.concat(
      `${
        error.length > 0
          ? ", регистрация отмены на прошлые даты невозможна, если вам нужно отменить прошедшее мероприятия, обратитесь на eventsupport@5verst.ru"
          : "Регистрация отмены на прошлые даты невозможна, если вам нужно отменить прошедшее мероприятия, обратитесь на eventsupport@5verst.ru"
      }`
    );
  }
  if (!cancelReason) {
    error = error.concat(
      `${
        error.length > 0
          ? ", не указана причина отмены"
          : "Не указана причина отмены"
      }`
    );
  }
  if (cancelReason.length > CANCEL_EVENT_REASON_MAX_LENGTH) {
    error = error.concat(
      `${
        error.length > 0
          ? ", причина отмены не должна быть длиннее 140 символов"
          : "Причина отмены не должна быть длиннее 140 символов"
      }`
    );
  }
  isValidCancelData = error.length > 0 ? false : true;

  return { isValidCancelData, error };
};

export default useCancelData;
