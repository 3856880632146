import React, { useState } from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getResultsEventId } from "../../../store/results/selectors";
import { setAthlete } from "../../../store/results/slice";
import { TAthlete } from "../../../store/types";
import SearchAthleteByName from "../../Common/SearchAthleteByName/SearchAthleteByName";
import styles from "./index.module.scss";

interface IProps {
  name: string | undefined;
  index: number;
}
const AthleteName: React.FC<IProps> = ({ name, index }) => {
  const [editMode, setEditMode] = useState(false);
  const eventId = useAppSelector(getResultsEventId);
  const dispatch = useAppDispatch();

  const handlePickAthlete = (athlete: TAthlete): void => {
    dispatch(setAthlete({ index, ...athlete }));
  };

  const handleClose = () => setEditMode(false);

  return editMode ? (
    <div className={styles.wrapper}>
      <SearchAthleteByName
        name={name}
        event_id={eventId}
        registered_only={true}
        onClose={handleClose}
        onPickAthlete={handlePickAthlete}
      />
    </div>
  ) : (
    <div
      className={styles.wrapper}
      onClick={() => {
        setEditMode(true);
      }}
    >
      {name}
    </div>
  );
};

export default AthleteName;
