import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    loadResultsAction,
    saveResultsAction,
    uploadResultsAction,
} from "./thunk";
import {
    EventId,
    ResultsReducer,
    TResultsType,
    TDeleteAthletePayload,
    TSetResultIdPayload,
    TSetResultTimePayload,
    TSetAthletePayload,
    TLoadResultsPayload,
    TSaveResultsResponse,
} from "./types";

const HISTORY_LENGTH = 10;

const initialState: ResultsReducer = {
    loading: false,
    error: null,
    event_id: null,
    event_date: "",
    results: [],
    status: null,
    is_agree: false,
    resultsHistory: [],
    historyPosition: 0,
    needWriteHistory: true,
    isDataLoaded: false, // Определяет, нужна ли загрузка файлов. Сбрасываем при смене даты и парка, ставим при загрузке файлов и загрузке данных.
    resultsNavigate: false, // При поднятии флага переадресация с загрузки на таблицу
    editRow: null,
    currentRow: null,
    resultsActive: false, // Определяет навигацию. Если файлы не загружены то ссылки на загрузку, а если загружены, то ссылки на таблицу результатов
    showWebsiteLink: false,
    showLoadVolunteersModal: false,
    doubleResults: [], //Если есть двойные результаты атлетов в других парках, приходит список таких атлетов сюда
};

export const resultsSlice = createSlice({
    name: "ResultsSlice",
    initialState,
    reducers: {
        resetResults: () => initialState,
        loadResults: (
            state: ResultsReducer,
            action: PayloadAction<TResultsType>
        ) => {
            state.results = action.payload;
            state.isDataLoaded = true;
            state.needWriteHistory = true;
            state.resultsHistory = [];
            state.historyPosition = 0;
        },
        setResults: (
            state: ResultsReducer,
            action: PayloadAction<TResultsType>
        ) => {
            // addHistory();
            state.results = action.payload;
            state.editRow = null;
            state.needWriteHistory = true;
        },
        setResultsEventId: (
            state: ResultsReducer,
            action: PayloadAction<EventId>
        ) => {
            state.event_id = action.payload;
            state.isDataLoaded = false;
        },
        setResultsEventDate: (
            state: ResultsReducer,
            action: PayloadAction<string>
        ) => {
            state.event_date = action.payload;
            state.isDataLoaded = false;
        },
        resetNavigateFlag: (state: ResultsReducer) => {
            state.resultsNavigate = false;
        },
        setResultTime: (
            state: ResultsReducer,
            action: PayloadAction<TSetResultTimePayload>
        ) => {
            state.results[action.payload.index].finish_time =
                action.payload.newTime;
            state.needWriteHistory = true;
        },
        setResultId: (
            state: ResultsReducer,
            action: PayloadAction<TSetResultIdPayload>
        ) => {
            state.results[action.payload.index].athlete_id =
                action.payload.newId;
            state.needWriteHistory = true;
        },
        setAthlete: (
            state: ResultsReducer,
            action: PayloadAction<TSetAthletePayload>
        ) => {
            state.results[action.payload.index].athlete_id = action.payload.id;
            state.results[action.payload.index].full_name =
                action.payload.full_name;
            state.needWriteHistory = true;
        },
        setEditRow: (
            state: ResultsReducer,
            action: PayloadAction<number | null>
        ) => {
            state.editRow = action.payload;
        },
        setCurrentRow: (
            state: ResultsReducer,
            action: PayloadAction<number | null>
        ) => {
            state.currentRow = action.payload;
        },
        setResultsActive: (
            state: ResultsReducer,
            action: PayloadAction<boolean>
        ) => {
            state.resultsActive = action.payload;
        },
        addHistory: (state: ResultsReducer) => {
            // Если мы в истории внутри, то переписываем историю начиная с позиции где мы
            if (!state.needWriteHistory) {
                state.needWriteHistory = true;
                return;
            }
            if (state.historyPosition < state.resultsHistory.length) {
                state.resultsHistory = [...state.resultsHistory].slice(
                    0,
                    state.historyPosition
                );
            }
            state.resultsHistory.push(state.results);
            if (state.resultsHistory.length > HISTORY_LENGTH) {
                state.resultsHistory.shift();
            } else {
                state.historyPosition++;
            }
        },
        stepHistoryBack: (state: ResultsReducer) => {
            if (state.historyPosition > 1) {
                state.results = state.resultsHistory[state.historyPosition - 2];
                state.historyPosition--;
                state.needWriteHistory = false;
            }
        },
        stepHistoryForward: (state: ResultsReducer) => {
            if (state.historyPosition < state.resultsHistory.length) {
                state.results = state.resultsHistory[state.historyPosition];
                state.historyPosition++;
                state.needWriteHistory = false;
            }
        },
        deleteAthleteResult: (
            state: ResultsReducer,
            action: PayloadAction<TDeleteAthletePayload>
        ) => {
            state.results.forEach((result) => {
                if (result.position < action.payload.position) return;
                if (result.position < state.results.length) {
                    // Поскольку position на 1 больше индекса в массиве, берем results[result.position]
                    result.athlete_id =
                        state.results[result.position].athlete_id;
                    result.full_name = state.results[result.position].full_name;
                } else if (result.position === state.results.length) {
                    // Поскольку position на 1 больше индекса в массиве, берем results[result.position]
                    if (result.finish_time === "") {
                        state.results.pop();
                    } else {
                        result.athlete_id = null;
                        result.full_name = "";
                    }
                }
            });
            state.editRow = null;
        },
        setShowWebsiteLink: (
            state: ResultsReducer,
            { payload }: PayloadAction<boolean>
        ) => {
            state.showWebsiteLink = payload;
        },
        setShowLoadVolunteersModal: (
            state: ResultsReducer,
            { payload }: PayloadAction<boolean>
        ) => {
            state.showLoadVolunteersModal = payload;
        },
    },
    extraReducers: {
        [uploadResultsAction.pending.type]: (state: ResultsReducer) => {
            state.loading = true;
            state.error = null;
        },
        [uploadResultsAction.fulfilled.type]: (
            state: ResultsReducer,
            { payload }: PayloadAction<TResultsType>
        ) => {
            state.results = payload || [];
            state.loading = false;
            state.isDataLoaded = true;
            state.resultsNavigate = true;
            state.needWriteHistory = true;
            state.resultsHistory = [];
            state.historyPosition = 0;
        },
        [uploadResultsAction.rejected.type]: (
            state: ResultsReducer,
            { payload }: PayloadAction<AxiosError>
        ) => {
            state.results = [];
            state.loading = false;
            state.error = payload;
        },
        [loadResultsAction.pending.type]: (state: ResultsReducer) => {
            state.loading = true;
            state.error = null;
        },
        [loadResultsAction.fulfilled.type]: (
            state: ResultsReducer,
            { payload }: PayloadAction<TLoadResultsPayload>
        ) => {
            state.results = payload.results || [];
            state.status = payload.status;
            state.loading = false;
            state.isDataLoaded = true;
            state.needWriteHistory = true;
            state.resultsHistory = [];
            state.historyPosition = 0;
            state.currentRow = null;
            state.editRow = null;
            state.doubleResults = [];
        },
        [loadResultsAction.rejected.type]: (
            state: ResultsReducer,
            { payload }: PayloadAction<AxiosError>
        ) => {
            state.results = [];
            state.loading = false;
            state.error = payload;
        },
        [saveResultsAction.pending.type]: (state: ResultsReducer) => {
            state.loading = true;
            state.error = null;
        },
        [saveResultsAction.fulfilled.type]: (
            state: ResultsReducer,
            { payload }: PayloadAction<TSaveResultsResponse>
        ) => {
            state.loading = false;
            state.resultsNavigate = true;
            state.showWebsiteLink = true;
            state.doubleResults = [];
            if (payload.errorCode === 0) {
                state.error = null;
            } else if (payload.result.data) {
                for (const [key, value] of Object.entries(
                    payload.result.data
                )) {
                    if (key) {
                        state.doubleResults.push(value);
                    }

                    // console.log(value);
                }
            }
        },
        [saveResultsAction.rejected.type]: (
            state: ResultsReducer,
            { payload }: PayloadAction<AxiosError>
        ) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const {
    setResultsEventDate,
    setResultsEventId,
    loadResults,
    resetNavigateFlag,
    addHistory,
    stepHistoryBack,
    stepHistoryForward,
    deleteAthleteResult,
    setEditRow,
    setCurrentRow,
    setResults,
    setResultTime,
    setResultId,
    setAthlete,
    resetResults,
    setResultsActive,
    setShowWebsiteLink,
    setShowLoadVolunteersModal,
} = resultsSlice.actions;
export default resultsSlice.reducer;
