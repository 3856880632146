import { createAsyncThunk } from "@reduxjs/toolkit";
// import { createBrowserHistory } from "history";
import {
  APIRoute,
  ErrorCodes,
  VolunteersStatusId,
  WarningMessage,
} from "../../const";
import { api } from "../root-reducer";
import { notification } from "antd";
import {
  TResultsLoadData,
  TLoadResultsResponse,
  TResultsSaveData,
  TUploadResultsResponse,
  TSaveResultsResponse,
} from "./types";
import { setResultsActive, setShowLoadVolunteersModal } from "./slice";
import { TLoadVolunteersResponse } from "../volunteers/types";

export const uploadResultsAction = createAsyncThunk(
  "results/uploadFiles",
  async (formData: FormData, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const response = await api.post<TUploadResultsResponse>(
        APIRoute.ResultsUpload,
        formData,
        config
      );
      if (response.status === 200) {
        notification.success({ message: WarningMessage.UploadSuccess });
        dispatch(setResultsActive(true));
      }

      return response.data.result.data;
    } catch (error: any) {
      if (error.response.status === 500) {
        notification.error({ message: "Ошибка на сервере 500" });
      } else {
        notification.error({ message: error.response.data.errorMessage });
      }
      // console.log("ERROR RESPONSE STATUS", error.response.status);

      return rejectWithValue(error.message);
    }
  }
);

export const loadResultsAction = createAsyncThunk(
  "results/loadResults",
  async (data: TResultsLoadData, { rejectWithValue }) => {
    try {
      const response = await api.post<TLoadResultsResponse>(
        APIRoute.ResultsLoad,
        data
      );

      return response.data.result;
    } catch (error: any) {
      notification.error({ message: WarningMessage.ResultsLoadFail });
      return rejectWithValue(error.message);
    }
  }
);

export const saveResultsAction = createAsyncThunk(
  "results/saveResults",
  async (data: TResultsSaveData, { rejectWithValue, dispatch }) => {
    try {
      const res1 = api.post<TSaveResultsResponse>(APIRoute.ResultsSave, data);
      // проверяем статус волонтеров.
      const res2 = api.post<TLoadVolunteersResponse>(APIRoute.VolunteersLoad, {
        event_id: data.event_id,
        event_date: data.event_date,
      });
      const [response, volunteersResponse] = await Promise.all([res1, res2]);

      if (response.data.errorCode === ErrorCodes.DoubleResults) {
        notification.error({
          message: WarningMessage.ResultsDoubleResults,
          placement: "topLeft",
        });
      } else if (response.status === 200) {
        notification.success({
          message: WarningMessage.SaveSuccess,
          placement: "topLeft",
        });
        if (
          volunteersResponse.data.result.upload_status_id !==
          VolunteersStatusId.Final
        ) {
          dispatch(setShowLoadVolunteersModal(true));
        }
      }

      return response.data;
    } catch (error: any) {
      notification.error({ message: error.response.data.errorMessage });
      // notification.error({ message: WarningMessage.UploadFail });
      return rejectWithValue(error.message);
    }
  }
);
