import {
  INITIAL_POSITIONS,
  TVolunteer,
  TVolunteerPosition,
} from "../../store/volunteers/types";

export const roleByRoleId = (
  roles: TVolunteerPosition[],
  roleId: number
): TVolunteerPosition | undefined => {
  return roles?.find((role) => role.id === roleId);
};

/* Получение списка волонтеров из массива ID позиций и словаря позиций */
// Заменить на селектор ? Селектор есть, но как его тут достать...
export const initialVolunteers = (
  positions: number[],
  roles: TVolunteerPosition[]
): TVolunteer[] => {
  return positions.map((position) => ({
    role_id: position,
    verst_id: null,
    full_name: "",
    role_name: roleByRoleId(roles, position)?.name,
  }));
};

// Заменить на селектор
export const combineVolunteers = (
  startList: TVolunteer[],
  roles: TVolunteerPosition[]
): TVolunteer[] => {
  // Создаем пустой список волонтеров из стартовых позиций
  let initialList = initialVolunteers(
    INITIAL_POSITIONS.sort((a, b) => a - b),
    roles
  );
  // Пробегаемся по загруженному списку волонтеров
  startList.forEach((volunteer) => {
    // Если в списке позиций есть строка с таким id то удаляем её.
    let index = initialList.findIndex(
      (position) => position.role_id === volunteer.role_id && !position.verst_id
    );
    if (index >= 0) {
      initialList.splice(index, 1);
    }
  });
  // После того как вычеркнуты все занятые позиции, добавляем незанятые позиции к списку волонтеров
  let list = [...startList, ...initialList];
  // Сортируем список по id и возвращаем.
  return list.sort((a: TVolunteer, b: TVolunteer) => {
    if (a.role_id && b.role_id) {
      return a.role_id - b.role_id;
    } else return 0;
  });
};

// Заменяет или дополняет initialList загруженными позициями из startList
export const combineVolunteersLists = (
  startList: TVolunteer[],
  initialList: TVolunteer[]
): TVolunteer[] => {
  let initialListCopy = [...initialList];
  // Пробегаемся по загруженному списку волонтеров
  startList.forEach((volunteer) => {
    // Если в списке позиций есть строка с таким id то удаляем её.
    let index = initialListCopy.findIndex(
      (position) => position.role_id === volunteer.role_id && !position.verst_id
    );
    if (index >= 0) {
      initialListCopy.splice(index, 1);
    }
  });
  // После того как вычеркнуты все занятые позиции, добавляем незанятые позиции к списку волонтеров
  let list = [...startList, ...initialListCopy];
  // Сортируем список по id и возвращаем.
  return list.sort((a: TVolunteer, b: TVolunteer) => {
    if (a.role_id && b.role_id) {
      return a.role_id - b.role_id;
    } else return 0;
  });
};
