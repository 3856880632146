import React from "react";
import { useAppSelector } from "../../../../customHooks/useAppSelector";
import { getIsResultCountExceeded } from "../../../../store/results/selectors";
import { TDoubleResultAthlete } from "../../../../store/results/types";

interface IProps {
  athlete: TDoubleResultAthlete;
}

const DoubleResultAthlete: React.FC<IProps> = ({ athlete }) => {
  const isAllowedResultCountExceeded = useAppSelector((state) =>
    getIsResultCountExceeded(state, athlete.athlete_id)
  );
  return isAllowedResultCountExceeded ? (
    <li>
      {athlete.athlete_name}: {athlete.event_names.join(", ")}
    </li>
  ) : null;
};

export default DoubleResultAthlete;
