import { notification } from "antd";
import {
    INITIAL_POSITIONS,
    RoasterLocalstorageKey,
} from "../store/volunteers/types";

/** Читает начальный список позиций волонтеров из локального хранилища и возвращает его */
export const getIdList = (): number[] => {
    try {
        const list = localStorage.getItem(RoasterLocalstorageKey);
        if (list && list.length > 0) {
            return JSON.parse(list);
        }
        return INITIAL_POSITIONS;
    } catch (error: any) {
        dropIdList();
        notification.warn({
            message: "Ошибка чтения шаблона волонтеров. Шаблон сброшен.",
        });
        return INITIAL_POSITIONS;
    }
};

/** Сохраняет список волонтерских позиций в локальное хранилище */
export const saveIdList = (list: number[]): void => {
    localStorage.setItem(RoasterLocalstorageKey, JSON.stringify(list));
};

/** Удаляет список волонтерских позиций из локального хранилища (очистка) */
export const dropIdList = (): void => {
    localStorage.removeItem(RoasterLocalstorageKey);
};
