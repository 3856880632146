import React, { useState } from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { setAthlete } from "../../../store/results/slice";
import { TAthlete } from "../../../store/types";
import SearchAthleteById from "../../Common/SearchAthleteById/SearchAthleteById";
import styles from "./index.module.scss";

interface IProps {
  id: number | null;
  // setAthleteId: (newId: number | null) => void;
  index: number;
}
const AthleteId: React.FC<IProps> = ({ id, index }) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();

  const handlePickAthlete = (athlete: TAthlete): void => {
    dispatch(setAthlete({ index, ...athlete }));
  };

  const handleClose = () => setEditMode(false);

  return editMode ? (
    <div className={styles.wrapper}>
      <SearchAthleteById
        id={id}
        onClose={handleClose}
        onPickAthlete={handlePickAthlete}
      />
    </div>
  ) : (
    <div
      className={styles.wrapper}
      onClick={() => {
        setEditMode(true);
      }}
    >
      {id ? `A${id}` : ""}
    </div>
  );
};

export default AthleteId;
