import { Modal } from "antd";
import styles from "./index.module.scss";

type VolunteerNotAdultModalProps = {
  isModalOpen: boolean,
  handleVolunteerNotAdultModal: (isModalOpen: boolean) => void;
}

const VolunteerNotAdultModal = (props: VolunteerNotAdultModalProps) => {
  const { handleVolunteerNotAdultModal, isModalOpen } = props;
  

  const handleCancel = () => {
    handleVolunteerNotAdultModal(false);
  };

  return (
    <Modal visible={isModalOpen} onCancel={handleCancel} footer={""} className={styles.modal}>
      <p>Пожалуйста, обратите внимание, роль связана с безопасностью и волонтёр младше 18 лет может 
        занимать её только в паре с совершеннолетним волонтёром!</p>
    </Modal>
  );
};

export default VolunteerNotAdultModal;
