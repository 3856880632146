import React from "react";
import styles from "./index.module.scss";

interface IProps {
  onClick?: () => void;
  onRed?: boolean; //true если на красном фоне
}

const DeleteButton: React.FC<IProps> = ({
  onClick = () => {},
  onRed = false,
}) => {
  const classNames = `${styles.delete} ${onRed ? styles.onRed : ""}`;
  return (
    <span className={classNames} onClick={onClick}>
      &times;
    </span>
  );
};

export default DeleteButton;
