import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { EMPTY_ATHLETE } from "../../../const";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getAthleteSearch } from "../../../store/search/selectors";
import { clearSearch } from "../../../store/search/slice";
import { searchByIdPart, searchByNamePart } from "../../../store/search/thunk";
import { TAthlete } from "../../../store/types";
import { TSearchAthleteOption } from "../../../types/commonTypes";
import {
  getAthleteSearchOptions,
  isValidId,
  isValidName,
} from "../../../utils";
import styles from "./index.module.scss";

interface IProps {
  athlete: TAthlete | null;
  event_id?: number | null;
  autoFocus?: boolean;
  registeredOnly?: boolean;
  onClose?: () => void;
  onPickAthlete: (athlete: TAthlete) => void;
}

// метод поиска в универсальном поле поиска по ID или имени атлета.
// unset для пустой строки, id и name соответственно. error когда строка не подходит формально.
enum SearchMethod {
  unset,
  id,
  name,
  error,
}

const SearchAthleteByNameOrId: React.FC<IProps> = ({
  athlete,
  event_id = null,
  autoFocus = true,
  registeredOnly = false,
  onClose = () => {},
  onPickAthlete,
}) => {
  const [value, setValue] = useState(athlete?.full_name || "");
  // const [searchMethod, setSearchMethod] = useState(SearchMethod.unset);
  const [options, setOptions] = useState<TSearchAthleteOption[]>([]);
  // Возвращает массив найденных атлетов
  const search = useAppSelector(getAthleteSearch);
  const dispatch = useAppDispatch();

  // вынести в функцию SearchList(search);
  useEffect(() => {
    if (search) {
      setOptions(getAthleteSearchOptions(search));
    } else setOptions([]);
  }, [search]);

  useEffect(() => {
    setValue(athlete?.full_name || "");
  }, [athlete]);

  const getSearchMethod = (searchString: string): SearchMethod => {
    if (searchString.length === 0) {
      return SearchMethod.unset;
    } else if (isValidId(searchString)) {
      return SearchMethod.id;
    } else if (isValidName(searchString)) {
      return SearchMethod.name;
    }
    return SearchMethod.error;
  };

  const searchAthlete = (searchText: string) => {
    const searchMethod = getSearchMethod(searchText);
    if (
      searchMethod === SearchMethod.name &&
      searchText &&
      searchText.length >= 3
    ) {
      const searchArg = event_id
        ? { name: searchText, event_id, registered_only: registeredOnly }
        : { name: searchText, registered_only: registeredOnly };
      dispatch(searchByNamePart(searchArg));
    } else if (
      searchMethod === SearchMethod.id &&
      searchText &&
      searchText.length > 3
    ) {
      dispatch(searchByIdPart(parseInt(searchText)));
    } else {
      // чистим если не айди и не name, то есть когда пусто.
      dispatch(clearSearch());
      setOptions([]);
      // console.log("CLEAR SEARCH");
    }
  };

  const onSearch = (searchText: string) => {
    searchAthlete(searchText);
  };

  const onSelect = (data: string) => {
    // При выборе атлета нам попадает ID строкой. А нужен атлет.
    const athlete: TAthlete | undefined = search.find(
      (el) => el.id === parseInt(data)
    );
    setValue(data);
    if (athlete) onPickAthlete(athlete);
    onClose();
  };

  const onChange = (data: string) => {
    if (getSearchMethod(data) === SearchMethod.error) return;
    setValue(data);
  };

  const onBlur = () => {
    dispatch(clearSearch());
    setValue(athlete?.full_name || "");
    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (value?.trim().length === 0) {
        // Если пусто то при вводе пустой атлет
        onPickAthlete(EMPTY_ATHLETE);
        onClose();
      } else if (options.length === 1) {
        // Если один выбор то при вводе выбираем его
        onSelect(options[0].value);
      }
    }
    if (e.key === "Escape") {
      setValue(athlete?.full_name || "");
      onClose();
    }
  };

  return (
    <div className={styles.nameInputWrapper}>
      <AutoComplete
        id={styles.nameInput}
        className={styles.nameInput}
        autoFocus={autoFocus}
        value={value}
        options={options}
        dropdownMatchSelectWidth={false}
        // style={{ minWidth: 350 }}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        placeholder="Имя или ID участника"
      />
    </div>
  );
};

export default SearchAthleteByNameOrId;
