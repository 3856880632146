import { CheckSquareOutlined, WarningOutlined } from "@ant-design/icons";
import React from "react";
import { MessageType, VolunteersStatusId } from "../../../const";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getVolunteersStatus } from "../../../store/volunteers/selectors";
import Message from "../../Common/Message/Message";
import styles from "./index.module.scss";

const VolunteersStatus = () => {
  const volunteers_status = useAppSelector(getVolunteersStatus);

  if (!volunteers_status) return null;

  return volunteers_status === VolunteersStatusId.Draft ? (
    <Message type={MessageType.warning}>
      <span className={styles.message}>
        <WarningOutlined style={{ fontSize: 24, color: "orange" }} />
        <span>предварительный график волонтёров</span>
      </span>
    </Message>
  ) : (
    <Message type={MessageType.success}>
      <span className={styles.message}>
        <CheckSquareOutlined style={{ fontSize: 24, color: "green" }} />
        <span>список волонтёров загружен на сайт</span>
      </span>
    </Message>
  );
};

export default VolunteersStatus;
