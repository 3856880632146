import React, { useState } from "react";
import { TAthlete } from "../../../../store/types";
import styles from "./index.module.scss";
import SearchAthleteById from "../../../Common/SearchAthleteById/SearchAthleteById";

interface IProps {
  id: number | null;
  onPickAthlete: (athlete: TAthlete) => void;
}

const SearchId: React.FC<IProps> = ({ id, onPickAthlete }) => {
  const [showInput, setShowInput] = useState(false);
  return showInput ? (
    <SearchAthleteById
      id={id}
      onClose={() => setShowInput(false)}
      onPickAthlete={onPickAthlete}
    />
  ) : (
    <div
      className={`${styles.id} ${id ? styles.value : ""}`}
      onClick={() => setShowInput(true)}
    >
      {id ? `A ${id}` : "ID"}
    </div>
  );
};

export default SearchId;
