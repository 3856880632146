import React, { useState } from "react";
import styles from "./index.module.scss";
import EventName from "../../Common/EventName/EventName";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import {
    getIncidentEventId,
    getIncidentReportData,
} from "../../../store/incident/selectors";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import {
    setIncidentDate,
    setIncidentEventId,
    setIncidentReportMessage,
} from "../../../store/incident/slice";
import EventDate from "../../Common/EventDate/EventDate";
import { Button, Spin, notification } from "antd";
import IncidentRegisterHeader from "./incident-register-header/IncidentRegisterHeader";
import { getIsEmergencyLoading } from "../../../store/incident/selectors";
import { SendIncidentReportArg } from "../../../store/incident/types";
import { getUserId, getUserName } from "../../../store/user/selectors";
import { getParkNameById } from "../../../store/parks/selectors";
import { sendIncidentReport } from "../../../store/incident/thunk";
import Message from "../../Common/Message/Message";
import { MessageType } from "../../../const";

const IncidentRegister = () => {
    const [showReportData, setShowReportData] = useState(false);
    const dispatch = useAppDispatch();
    const loading = useAppSelector(getIsEmergencyLoading);
    const eventId = useAppSelector(getIncidentEventId);
    const userId = useAppSelector(getUserId);
    const userFullName = useAppSelector(getUserName);
    const eventName = useAppSelector((state) =>
        getParkNameById(state, eventId)
    );

    const { incidentDate, reportMessage } = useAppSelector(
        getIncidentReportData
    );

    const setReportMessage: React.ChangeEventHandler<HTMLTextAreaElement> = (
        event
    ) => {
        dispatch(setIncidentReportMessage(event.target.value));
    };

    const sendReport = () => {
        if (eventId && incidentDate && reportMessage) {
            console.log("REPORT SENT", newReportMessage());
            dispatch(sendIncidentReport(newReportMessage()));
        } else {
            notification.warn({ message: "Заполните все поля" });
        }
    };

    const newReportMessage = (): SendIncidentReportArg => {
        const message = `Мероприятие: ${eventId} - ${eventName}. Дата инцидента: ${incidentDate}. Пользователь: ${userFullName} (ID: ${userId}) ===> ${reportMessage}. `;
        return { id: userId as number, event_name: eventName, event_date: incidentDate, message };
    };

    const isFormInvalid = () => !(eventId && incidentDate && reportMessage);

    return (
        <div className={styles.wrapper}>
            <IncidentRegisterHeader
                isOpen={showReportData}
                setIsOpen={setShowReportData}
            />
            {showReportData && (
                <>
                    <EventName
                        eventId={eventId}
                        setEventId={setIncidentEventId}
                    />
                    <div className={styles.controlGroup}>
                        Дата инцидента:{" "}
                        <EventDate
                            eventDate={incidentDate}
                            setEventDate={setIncidentDate}
                        />
                    </div>
                    <div className={styles.controlGroup}>
                        <div>Описание инцидента:</div>
                        <textarea
                            rows={10}
                            className={styles.incidentReportTextarea}
                            value={reportMessage}
                            onChange={setReportMessage}
                        />
                    </div>
                    <Message type={MessageType.error}>Описание инцидента будет передано в штаб-квартиру и амбассадору по безопасности</Message>
                    <div className={styles.controlGroup}>
                        <Button
                            type="primary"
                            size={"large"}
                            onClick={sendReport}
                            disabled={isFormInvalid() || loading}
                        >
                            Отправить
                        </Button>
                        {loading ? (
                            <span className={styles.spinner}>
                                <Spin size="large" />
                            </span>
                        ) : null}
                    </div>
                </>
            )}
        </div>
    );
};

export default IncidentRegister;
