import { PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

interface IProps {
    onPrint: () => void;
}

const PrintButton: React.FC<IProps> = ({ onPrint }) => {
    const handlePrint = () => {
        onPrint();
    };
    return (
        <Button
            type="text"
            title="Напечатать список и брифинг"
            icon={
                <PrinterOutlined style={{ fontSize: "24px", color: "#444" }} />
            }
            onClick={handlePrint}
        />
    );
};

export default PrintButton;
