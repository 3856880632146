import { notification } from "antd";
import React, { useState } from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { TAthlete } from "../../../store/types";
import { setVolunteer } from "../../../store/volunteers/slice";
import SearchAthleteById from "../../Common/SearchAthleteById/SearchAthleteById";
import styles from "./index.module.scss";

interface IProps {
  volunteerId: number | null;
  index: number;
}

const VolunteerId: React.FC<IProps> = ({ volunteerId, index }) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setEditMode(false);
  };

  const onPickAthlete = (athlete: TAthlete): void => {
    if (athlete && athlete.is_record_confirmed === false) {
      notification.warn({
        message: "Попросите волонтера зарегистрироваться!!!",
      });
    }
    dispatch(setVolunteer({ index, ...athlete }));
  };
  return editMode ? (
    <SearchAthleteById
      id={volunteerId}
      onClose={handleClose}
      onPickAthlete={onPickAthlete}
    />
  ) : (
    // <VolunteerIdEdit id={volunteerId} index={index} onClose={handleClose} />
    <div
      className={styles.id}
      onClick={() => {
        setEditMode(true);
      }}
    >
      {volunteerId ? (
        `A${volunteerId}`
      ) : (
        <span className={styles.idPlaceholder}>ID</span>
      )}
    </div>
  );
};

export default VolunteerId;
