import { Button, Input, Modal, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CancelEventMessage from "../../components/Common/CancelEventMessage/CancelEventMessage";
import EventDate from "../../components/Common/EventDate/EventDate";
import EventName from "../../components/Common/EventName/EventName";
import Message from "../../components/Common/Message/Message";
import TestWrapper from "../../components/Common/TestWrapper/TestWrapper";
import { EventStatus, MessageType } from "../../const";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { useAppSelector } from "../../customHooks/useAppSelector";
import {
    getCancelEventDate,
    getCancelEventId,
    getCancelReason,
    getEventStatus,
} from "../../store/cancelEvent/selectors";
import {
    resetCancelData,
    setCancelEventDate,
    setCancelEventId,
    setCancelReason,
} from "../../store/cancelEvent/slice";
import {
    cancelEventAction,
    getEventStatusAction,
    restoreEventAction,
} from "../../store/cancelEvent/thunk";
import { getParkNameById } from "../../store/parks/selectors";
import CancelReasonCounter from "./CancelReasonCounter/CancelReasonCounter";
import styles from "./index.module.scss";
import useCancelData from "./useCancelData";

const { TextArea } = Input;

const CancelEvent = () => {
    const dispatch = useAppDispatch();
    const eventId = useAppSelector(getCancelEventId);
    const eventName = useAppSelector((state) =>
        getParkNameById(state, eventId)
    );
    const eventDate = useAppSelector(getCancelEventDate);
    const eventStatus = useAppSelector(getEventStatus);
    const cancelReason = useAppSelector(getCancelReason);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const { isValidCancelData, error } = useCancelData();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [validateError, setValidateError] = useState("");

    useEffect(() => {
        document.title = "NRMS - отмена мероприятия";
    }, []);

    useEffect(() => {
        setValidateError("");
        if (eventId && eventId > 0 && eventDate) {
            dispatch(resetCancelData());

            dispatch(
                getEventStatusAction({ event_id: eventId, date: eventDate })
            );
        }
    }, [eventId, eventDate, dispatch]);

    const handleCancelReasonChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setValidateError("");
        dispatch(setCancelReason(e.target.value));
    };

    const handleReasonKeydown = (
        e: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
        if (e.key === "Tab" && buttonRef.current) {
            e.preventDefault();
            buttonRef.current.focus();
        }
    };

    const handleCancelEvent = () => {
        if (eventStatus === EventStatus.Test) {
            notification.warn({
                message:
                    "Ваше мероприятие только готовится к запуску. Отмена не предусмотрена",
            });
            return;
        }
        if (isValidCancelData) {
            setIsModalVisible(false);
            const cancelData = {
                event_id: eventId,
                event_date: eventDate,
                reason: cancelReason,
            };
            dispatch(cancelEventAction(cancelData));
        }
    };

    const handleFormCheck = () => {
        if (isValidCancelData) {
            setIsModalVisible(true);
        } else setValidateError(error);
    };

    return (
        <div className={styles.wrapper}>
            <h1>Отмена мероприятия</h1>
            <div className={styles.form}>
                <div className={styles.event}>
                    <div className={styles.eventName}>
                        <EventName
                            eventId={eventId}
                            setEventId={setCancelEventId}
                        />
                    </div>

                    <EventDate
                        eventDate={eventDate}
                        setEventDate={setCancelEventDate}
                    />
                </div>
                {eventStatus === EventStatus.Cancel ? (
                    <>
                        <CancelEventMessage />
                        <TestWrapper>
                            <div className={styles.restore}>
                                <Button
                                    type="primary"
                                    className="submit-button"
                                    onClick={() =>
                                        dispatch(
                                            restoreEventAction({
                                                event_id: eventId,
                                                event_date: eventDate,
                                            })
                                        )
                                    }
                                >
                                    Восстановить мероприятие
                                </Button>
                            </div>
                        </TestWrapper>
                    </>
                ) : eventStatus === EventStatus.Test ? (
                    <Message type={MessageType.error}>
                        Ваше мероприятие только готовится к запуску. Отмена не
                        предусмотрена
                    </Message>
                ) : (
                    <>
                        <div className={styles.reason}>
                            <TextArea
                                rows={5}
                                placeholder="Причина отмены"
                                value={cancelReason}
                                onChange={handleCancelReasonChange}
                                onKeyDown={handleReasonKeydown}
                            />
                            <CancelReasonCounter
                                reasonLength={cancelReason.length}
                            />
                        </div>
                        <Message type={MessageType.warning}>
                            Пожалуйста, внимательно проверьте текст причины
                            отмены, так как он будет отображаться на сайте для
                            всех участников
                        </Message>

                        {validateError.length > 0 && (
                            <div className={styles.error}>{validateError}</div>
                        )}
                        <Modal
                            visible={isModalVisible}
                            okText="Да"
                            cancelText="Нет"
                            onOk={handleCancelEvent}
                            okButtonProps={{ size: "large" }}
                            cancelButtonProps={{ size: "large" }}
                            onCancel={() => setIsModalVisible(false)}
                        >
                            <p>{`Зарегистрировать отмену ${"\n"} мероприятия ${eventName} на ${eventDate}?`}</p>
                        </Modal>
                        <Button
                            className={styles.submitButton}
                            ref={buttonRef}
                            type="primary"
                            size="large"
                            onClick={handleFormCheck}
                        >
                            Зарегистрировать отмену
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default CancelEvent;
