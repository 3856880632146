import { Modal } from "antd";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import {
  getShowSummary,
  volunteerSummary,
} from "../../../store/volunteers/selectors";
import { clearVolunteerSummary } from "../../../store/volunteers/slice";

// interface IProps {
//   volunteer: TVolunteer;
// }
// Если надо будет внутрь класть что за волонтер, можно getShowSummary сделать не булеан а id
const VolunteerSummary = () => {
  const isModalOpen = useAppSelector(getShowSummary);
  const summary = useAppSelector(volunteerSummary);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(clearVolunteerSummary());
  };
  return (
    <Modal
      title="Опыт волонтерства"
      visible={isModalOpen}
      onOk={handleClose}
      onCancel={handleClose}
      footer={""}
    >
      {/* <h2>{volunteer.full_name}</h2> */}
      <ul>
        {summary.map((item) => (
          <li key={item.role_id}>
            {item.role} - {item.count}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default VolunteerSummary;
