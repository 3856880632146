import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PasswordReducer } from "./types";
import {
  requestPasswordAction,
  setPasswordAction,
  updatePasswordAction,
} from "./thunk";
import { AxiosError } from "axios";

const initialState: PasswordReducer = {
  isLoading: false,
  error: null,
  isPasswordChanged: false,
};

export const passwordSlice = createSlice({
  name: "PasswordSlice",
  initialState,
  reducers: {
    setPasswordChangeStatus: (
      state: PasswordReducer,
      action: PayloadAction<boolean>
    ) => {
      state.isPasswordChanged = action.payload;
    },
  },
  extraReducers: {
    [setPasswordAction.fulfilled.type]: (state: PasswordReducer) => {
      state.isPasswordChanged = true;
    },
    [requestPasswordAction.pending.type]: (state: PasswordReducer) => {
      state.isLoading = true;
      state.error = null;
    },
    [requestPasswordAction.fulfilled.type]: (state: PasswordReducer) => {
      state.isLoading = false;
    },
    [requestPasswordAction.rejected.type]: (
      state: PasswordReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.isLoading = false;
      state.error = payload;
    },
    [updatePasswordAction.pending.type]: (state: PasswordReducer) => {
      state.isLoading = true;
      state.error = null;
    },
    [updatePasswordAction.fulfilled.type]: (state: PasswordReducer) => {
      state.isLoading = false;
      state.isPasswordChanged = true;
    },
    [updatePasswordAction.rejected.type]: (
      state: PasswordReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { setPasswordChangeStatus } = passwordSlice.actions;
export default passwordSlice.reducer;
