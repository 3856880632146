import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import {
  checkResultsOnSite,
  initVolunteersDateAction,
  loadVolunteersAction,
} from "../../store/volunteers/thunk";
import { useAppSelector } from "../../customHooks/useAppSelector";
import {
  getVolunteersEventDate,
  getVolunteersEventId,
  getVolunteerMessage,
  getVolunteers,
  getNeedLoadVolunteers,
  getIsEventCanceled,
  getIsInitialVolunteersList,
  getShowSaveResultsModal,
} from "../../store/volunteers/selectors";
import {
  setVolunteersEventDate,
  setVolunteersEventId,
  setVolunteerMessage,
  setIsInitialVolunteersList,
  clearError,
  setShowSaveResultsModal,
} from "../../store/volunteers/slice";
import styles from "./index.module.scss";
import EventName from "../../components/Common/EventName/EventName";
import EventDate from "../../components/Common/EventDate/EventDate";
import VolunteersTable from "../../components/Volunteers/VolunteersTable";
import AddVolunteer from "../../components/Volunteers/AddVolunteer/AddVolunteer";
import VolunteersStatus from "../../components/Volunteers/VolunteersStatus/VolunteersStatus";
import VolunteersLink from "../../components/Volunteers/VolunteersLink/VolunteersLink";
import CopyList from "../../components/Volunteers/CopyList/CopyList";
import CancelEventMessage from "../../components/Common/CancelEventMessage/CancelEventMessage";
import Errors from "../../components/Volunteers/Errors/Errors";
import VolunteersSaveButtons from "../../components/Volunteers/VolunteersSaveButtons/VolunteersSaveButtons";
import { Modal } from "antd";
import {
  setResultsEventDate,
  setResultsEventId,
} from "../../store/results/slice";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../const";
import ListSetup from "../../components/Volunteers/ListSetup/ListSetup";
import PrintButton from "../../components/Volunteers/PrintButton/PrintButton";
import PrintVolunteers from "../../components/Volunteers/PrintVolunteers/PrintVolunteers";
import { getEventData } from "../../store/results/selectors";
import VolunteersQuantity from "../../components/Volunteers/VolunteersQuantity/VolunteersQuantyty";
import VolunteerSummary from "../../components/Volunteers/VolunteerSummary/VolunteerSummary";

const Volunteers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eventDate = useAppSelector(getVolunteersEventDate);
  const eventId = useAppSelector(getVolunteersEventId);
  const isEventCanceled = useAppSelector(getIsEventCanceled);
  const needLoadVolunteers = useAppSelector(getNeedLoadVolunteers);
  const volunteers = useAppSelector(getVolunteers);
  const saveVolunteerMessage = useAppSelector(getVolunteerMessage);
  const isInitialVolunteersList = useAppSelector(getIsInitialVolunteersList);
  const showSaveResultsModal = useAppSelector(getShowSaveResultsModal);
  const [showPrintVersion, setShowPrintVersion] = useState(false);

  // Данные результатов
  const eventData = useAppSelector(getEventData);

  useEffect(() => {
    document.title = "NRMS - волонтеры";
  }, []);

  // Инициализация даты
  useEffect(() => {
    if (eventId && eventId > 0 && !eventDate) {
      dispatch(initVolunteersDateAction(eventId));
    }
    // Если поменялся парк или дата то обнуляем сообщения и ставим флаг начальных результатов
    if (eventId && eventId > 0 && eventDate) {
      dispatch(setVolunteerMessage(""));
      dispatch(setIsInitialVolunteersList(true));
    }
  }, [eventId, eventDate, dispatch]);

  useEffect(() => {
    // Если не начальная загруза и изменились волонтеры то выводим сообщение.
    if (!isInitialVolunteersList) {dispatch(setVolunteerMessage("Изменения не сохранены!"));}
      
    dispatch(clearError());
    // eslint-disable-next-line
  }, [volunteers, dispatch]);

  // Перезагружаем волонтеров если поменяли дату или парк (если нужно)
  useEffect(() => {
    if (needLoadVolunteers && eventDate && eventId && eventId > 0) {
      dispatch(
        loadVolunteersAction({ event_id: eventId, event_date: eventDate })
      );
    }
  }, [needLoadVolunteers, eventId, eventDate, dispatch]);

  // Если ушли со страницы, сохранили результаты и вернулись, то ссылка должна поменяться
  // Поэтому проверяем при загрузке страницы результаты
  useEffect(() => {
    if (eventDate && eventId && eventId > 0) {
      dispatch(
        checkResultsOnSite({ event_id: eventId, event_date: eventDate })
      );
    }
  }, [eventId, eventDate, dispatch]);

  if (showPrintVersion)
    return (
      <PrintVolunteers
        onClose={() => {
          setShowPrintVersion(false);
        }}
      />
    );

  const navigateResults = () => {
    if (
      eventData.event_date === eventDate &&
      eventData.event_id === eventId &&
      eventData.results.length > 0
    ) {
      dispatch(setShowSaveResultsModal(false));
      navigate(AppRoute.Results);
    } else {
      dispatch(setResultsEventId(eventId));
      dispatch(setResultsEventDate(eventDate));
      dispatch(setShowSaveResultsModal(false));
      navigate(AppRoute.ResultsFilesUpload);
    }
  };

  const hideModal = () => {
    dispatch(setShowSaveResultsModal(false));
  };

  return (
    <div className={styles.wrapper}>
      <h1>
        Волонтёры
        <VolunteersQuantity />
      </h1>
      <div className={styles.eventData}>
        <div className={styles.eventName}>
          <EventName eventId={eventId} setEventId={setVolunteersEventId} />
        </div>

        <EventDate
          eventDate={eventDate}
          setEventDate={setVolunteersEventDate}
        />
      </div>

      {isEventCanceled ? (
        <>
          <CancelEventMessage />
          <VolunteersLink />
        </>
      ) : (
        <>
          <div className={styles.tableTop}>
            {volunteers.length > 0 && (
              <>
                <VolunteersStatus />
                <div className={styles.buttons}>
                  <PrintButton
                    onPrint={() => {
                      setShowPrintVersion(true);
                    }}
                  />
                  <ListSetup />
                  <CopyList />
                </div>
              </>
            )}
          </div>
          <Errors />
          <VolunteersTable />
          <AddVolunteer />
          {saveVolunteerMessage ? (
            <div className={styles.message}>{saveVolunteerMessage}</div>
          ) : null}
          <VolunteersLink />
          <VolunteersSaveButtons />
          <Modal
            wrapClassName="results-modal"
            visible={showSaveResultsModal}
            onOk={navigateResults}
            onCancel={hideModal}
            okText="Да"
            cancelText="Нет"
          >
            <h2>
              Список волонтеров загружен на сайт. Результаты на {eventDate} не
              загружены. Перейти к загрузке результатов?
            </h2>
          </Modal>
          <VolunteerSummary />
        </>
      )}
    </div>
  );
};

export default Volunteers;
