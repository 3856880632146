import React, { ReactElement } from "react";
import { TEST_MODE } from "../../../const";

interface IProps {
  children: ReactElement | Array<ReactElement> | string;
}

const TestWrapper: React.FC<IProps> = ({ children }) => {
  return TEST_MODE ? <>{children}</> : null;
};

export default TestWrapper;
