import { Link, useNavigate } from "react-router-dom";
import { AppRoute } from "../../../const";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { dropToken } from "../../../services/token";
import { resetResults } from "../../../store/results/slice";
import { userLogout } from "../../../store/user/slice";
import styles from "./index.module.scss";

function LogoutLink(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Link
      to={AppRoute.Home}
      className={styles.link}
      onClick={(evt) => {
        evt.preventDefault();
        dropToken();
        dispatch(resetResults());
        dispatch(userLogout());
        navigate(AppRoute.Home);
      }}
    >
      Выйти
    </Link>
  );
}

export default LogoutLink;
