import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { APIRoute, WarningMessage } from "../../const";
import { setCancelEventId } from "../cancelEvent/slice";
import { setExtraEventsEventId } from "../extraEvents/slice";
import { setIncidentEventId } from "../incident/slice";
import { setResultsEventId } from "../results/slice";
import { api } from "../root-reducer";
import { setVolunteersEventId } from "../volunteers/slice";
import { AuthParkResponse } from "./types";

export const loadAuthParksAction = createAsyncThunk(
  "parks/loadAuthParks",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post<AuthParkResponse>(APIRoute.AuthParks);
      if (response.data.result.event_list.length === 1) {
        const eventId = response.data.result.event_list[0].id;
        dispatch(setResultsEventId(eventId));
        dispatch(setVolunteersEventId(eventId));
        dispatch(setCancelEventId(eventId));
        dispatch(setExtraEventsEventId(eventId));
        dispatch(setIncidentEventId(eventId));
      }
      return response.data.result.event_list;
    } catch (error: any) {
      console.log("ERROR: ", error.response);
      if (error.response?.status === 401)
        notification.error({ message: WarningMessage.AuthRequired });
      return rejectWithValue(error.message);
    }
  }
);
