import { notification } from "antd";
import React, { useState } from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { TAthlete } from "../../../store/types";
import { getVolunteersEventId } from "../../../store/volunteers/selectors";
import { setVolunteer } from "../../../store/volunteers/slice";
import SearchAthleteByName from "../../Common/SearchAthleteByName/SearchAthleteByName";
import styles from "./index.module.scss";

interface IProps {
  name: string | undefined;
  index: number;
}

const Name: React.FC<IProps> = ({ name, index }) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch = useAppDispatch();
  const eventId = useAppSelector(getVolunteersEventId);

  const handleClose = () => {
    setEditMode(false);
  };

  const onPickAthlete = (athlete: TAthlete): void => {
    if (athlete && athlete.is_record_confirmed === false) {
      notification.warn({
        message: "Попросите волонтера зарегистрироваться!!!",
      });
    }
    dispatch(setVolunteer({ index, ...athlete }));
  };

  return editMode ? (
    <SearchAthleteByName
      name={name}
      event_id={eventId}
      registered_only={true}
      onClose={handleClose}
      onPickAthlete={onPickAthlete}
    />
  ) : // <VolunteerNameEdit name={name} index={index} onClose={handleClose} />
  name ? (
    <div className={styles.name} onClick={() => setEditMode(true)}>
      {name}
    </div>
  ) : (
    <span className={styles.namePlaceholder} onClick={() => setEditMode(true)}>
      Имя
    </span>
  );
};

export default Name;
