import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddExtraEventAction,
  DeleteExtraEventAction,
  getCalendarAction,
} from "./thunk";
import {
  TExtraEvent,
  TExtraEventsReducer,
  TgetCalendarResponse,
  TSetExtraEventPayload,
  TSetAdditionalEventPayload,
  TAdditionalEvents,
} from "./types";

const initialState: TExtraEventsReducer = {
  loading: false,
  error: null,
  error_code: 0,
  error_message: "",
  event_id: null,
  eventsToChoose: [],
  calendar: [],
  additional_events: [],
};

export const extraEventsSlice = createSlice({
  name: "extraEventsSlice",
  initialState,
  reducers: {
    clearExtraEvents: () => initialState,
    setExtraEventsEventId: (
      state: TExtraEventsReducer,
      { payload }: PayloadAction<number | null>
    ) => {
      state.event_id = payload;
    },
    setExtraEvents: (
      state: TExtraEventsReducer,
      { payload }: PayloadAction<TExtraEvent[]>
    ) => {
      state.eventsToChoose = payload;
    },
    setAdditionalEvents: (
      state: TExtraEventsReducer,
      { payload }: PayloadAction<TAdditionalEvents>
    ) => {
      state.additional_events = payload;
    },
    setExtraEvent: (
      state: TExtraEventsReducer,
      { payload }: PayloadAction<TSetExtraEventPayload>
    ) => {
      state.eventsToChoose[payload.index] = payload.event;
    },
    setAdditionalEvent: (
      state: TExtraEventsReducer,
      { payload }: PayloadAction<TSetAdditionalEventPayload>
    ) => {
      state.additional_events[payload.index] = payload.event;
    },
  },
  extraReducers: {
    [AddExtraEventAction.pending.type]: (state: TExtraEventsReducer) => {
      state.loading = true;
      state.error = null;
    },
    [AddExtraEventAction.fulfilled.type]: (state: TExtraEventsReducer) => {
      state.loading = false;
    },
    [AddExtraEventAction.rejected.type]: (
      state: TExtraEventsReducer,
      { payload }: PayloadAction<string>
    ) => {
      state.loading = false;
      state.error = payload;
    },
    [DeleteExtraEventAction.pending.type]: (state: TExtraEventsReducer) => {
      state.loading = true;
      state.error = null;
    },
    [DeleteExtraEventAction.fulfilled.type]: (state: TExtraEventsReducer) => {
      state.loading = false;
    },
    [DeleteExtraEventAction.rejected.type]: (
      state: TExtraEventsReducer,
      { payload }: PayloadAction<string>
    ) => {
      state.loading = false;
      state.error = payload;
    },
    [getCalendarAction.pending.type]: (state: TExtraEventsReducer) => {
      state.loading = true;
      state.error = null;
    },
    [getCalendarAction.fulfilled.type]: (
      state: TExtraEventsReducer,
      { payload }: PayloadAction<TgetCalendarResponse>
    ) => {
      state.loading = false;
      state.calendar = payload.result.calendar;
    },
    [getCalendarAction.rejected.type]: (
      state: TExtraEventsReducer,
      { payload }: PayloadAction<string>
    ) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  clearExtraEvents,
  setExtraEventsEventId,
  setExtraEvents,
  setExtraEvent,
  setAdditionalEvents,
  setAdditionalEvent,
} = extraEventsSlice.actions;

export default extraEventsSlice.reducer;
