import { Button, notification } from "antd";
import React, { useState } from "react";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getVolunteersEventId } from "../../../store/volunteers/selectors";
import AddVolunteerForm from "../AddVolunteerForm/AddVolunteerForm";

const AddVolunteer = () => {
  const eventId = useAppSelector(getVolunteersEventId);
  const [showForm, setShowForm] = useState(false);

  const closeForm = (): void => {
    setShowForm(false);
  };

  const handleClick = () => {
    if (eventId) {
      setShowForm(true);
    } else {
      notification.warn({ message: "Выберите старт" });
    }
  };

  if (showForm) {
    return <AddVolunteerForm onClose={closeForm} />;
  }

  return (
    <div className="table-buttons">
      <Button type="primary" onClick={handleClick}>
        Добавить волонтёра
      </Button>
    </div>
  );
};

export default AddVolunteer;
