import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { APIRoute, EventStatus } from "../../const";
import { getParkNameById } from "../parks/selectors";
import { api, RootState } from "../root-reducer";
import { TCancelArgs, TGetStatusArgs, TRestoreArgs } from "./types";

export const cancelEventAction = createAsyncThunk(
  "cancelEvent/Cancel",
  async (args: TCancelArgs, { rejectWithValue, getState }) => {
    try {
      const response = await api.post(APIRoute.CancelEvent, args);
      if (response.status === 200) {
        const eventName = getParkNameById(
          getState() as RootState,
          args.event_id
        );
        notification.success({
          message: `Мероприятие ${eventName} на ${args.event_date} отменено`,
        });
      }

      return EventStatus.Cancel;
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        notification.error({ message: error.response.data.errorMessage });
      } else {
        notification.error({ message: "Произошла ошибка запроса" });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const restoreEventAction = createAsyncThunk(
  "cancelEvent/Restore",
  async (args: TRestoreArgs, { rejectWithValue, getState }) => {
    try {
      const response = await api.post(APIRoute.CancelRestore, args);
      if (response.status === 200) {
        const eventName = getParkNameById(
          getState() as RootState,
          args.event_id
        );
        notification.success({
          message: `Мероприятие ${eventName} на ${args.event_date} восстановлено`,
        });
      }

      return EventStatus.Open;
    } catch (error: any) {
      notification.error({ message: "Произошла ошибка запроса" });
      return rejectWithValue(error.message);
    }
  }
);

export const getEventStatusAction = createAsyncThunk(
  "cancelEvent/getEventStatus",
  async (args: TGetStatusArgs, { rejectWithValue }) => {
    try {
      const response = await api.post(APIRoute.EventStatus, args);
      return response.data.result.status_id;
    } catch (error: any) {
      if (error.response.data.errorMessage) {
        notification.error({ message: error.response.data.errorMessage });
      } else {
        notification.error({ message: "Произошла ошибка запроса" });
      }
      return rejectWithValue(error.message);
    }
  }
);
