import { CopyOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import React from "react";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getVolunteers } from "../../../store/volunteers/selectors";
import { getVolunteersList } from "../../../utils";

const CopyList = () => {
    const volunteers = useAppSelector(getVolunteers);
    const handleCopyList: React.MouseEventHandler = (event) => {
        event.stopPropagation();
        // работает только на https:

        navigator.clipboard.writeText(getVolunteersList(volunteers));
        notification.info({
            message: "Список волонтеров скопирован в буфер обмена",
        });
    };
    return (
        <Button
            type="text"
            title="Скопировать список волонтеров"
            icon={<CopyOutlined style={{ fontSize: "24px", color: "#444" }} />}
            onClick={handleCopyList}
        />
    );
};

export default CopyList;
