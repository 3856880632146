import { Button, Select, TimePicker, notification } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useAppDispatch } from "../../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../../customHooks/useAppSelector";
import { getResults } from "../../../../store/results/selectors";
import { setResults } from "../../../../store/results/slice";
import { addTimes, isValidSubstraction, subtractTimes } from "../../service";
import styles from "./index.module.scss";

const TimeFormat = "mm:ss";

const AddTimes = () => {
  const dispatch = useAppDispatch();
  const results = useAppSelector(getResults);
  const [addedTime, setAddedTime] = useState("00:00:00");
  const [doSubtractTime, setDoSubstractTime] = useState(false);

  const handleSubtractTime = (value: string) => {
    if (value === "add") {
      setDoSubstractTime(false);
    } else if (value === "subtract") {
      setDoSubstractTime(true);
    }
  };

  const notifiSubstractionError = () => {
    notification.error({
      message: "Вычитаемое время больше времени участника. Операция отменена.",
    });
  };

  const handleAddTime = () => {
    if (doSubtractTime) {
      if (isValidSubstraction(results, addedTime)) {
        dispatch(setResults(subtractTimes(results, addedTime)));
      } else notifiSubstractionError();
    } else {
      dispatch(setResults(addTimes(results, addedTime)));
    }
  };

  const handleTimeChange = (time: moment.Moment | null) => {
    setAddedTime("00:" + moment(time).format(TimeFormat));
  };

  return (
    <div className={styles.wrapper}>
      <Select
        defaultValue="add"
        // style={{ width: 220 }}
        onChange={handleSubtractTime}
        options={[
          { value: "add", label: "Добавить всем" },
          { value: "subtract", label: "Вычесть у всех" },
        ]}
      />
      (мин:с){" "}
      <TimePicker
        value={moment(addedTime.slice(-5), TimeFormat)}
        format={TimeFormat}
        showNow={false}
        allowClear={false}
        onChange={handleTimeChange}
      />{" "}
      <Button size="small" onClick={handleAddTime}>
        Выполнить
      </Button>
    </div>
  );
};

export default AddTimes;
