import { FormEvent, ChangeEvent, useRef, useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { requestPasswordAction } from "../../store/password/thunk";
import { notification, Spin } from "antd";
import { AppRoute, emailRegex } from "../../const";
import { getIsLoading } from "../../store/password/selectors";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { Link } from "react-router-dom";

function PasswordForgot(): JSX.Element {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getIsLoading);
  const inputEmailRef = useRef<HTMLInputElement | null>(null);
  const buttonSendRequestRef = useRef<HTMLButtonElement | null>(null);
  const linkLoginRef = useRef<HTMLAnchorElement | null>(null);
  const [email, setEmail] = useState("");

  useEffect(() => inputEmailRef.current?.focus(), []);

  const getFocus = () => {
    const focusableElements = [
      inputEmailRef.current,
      buttonSendRequestRef.current,
      linkLoginRef.current,
    ];
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    document.addEventListener("keydown", (evt: KeyboardEvent) => {
      const isTabPressed = evt.key === "Tab";

      if (!isTabPressed) {
        return;
      }

      if (evt.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement?.focus();
          evt.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement?.focus();
          evt.preventDefault();
        }
      }
    });
  };

  useEffect(() => {
    getFocus();
  });

  const handleEmailChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setEmail(evt.target.value);
  };

  // const handleIdChange = (evt: ChangeEvent<HTMLInputElement>): void => {
  //   setVerstId(evt.target.value);
  // };

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (email.match(emailRegex)) {
      dispatch(requestPasswordAction(email.toLowerCase()));
    } else {
      notification.warn({
        message: "Запрос не отправлен: Неверный формат Email",
      });
    }
  };

  return (
    <section className={styles.page}>
      <form className="password-reminder" onSubmit={handleSubmit}>
        {/* По E-mail */}
        <div className={styles.wrapper}>
          <label className={styles.label} htmlFor="email">
            Введите E-mail для смены пароля:
          </label>
          {/* <span className={styles.letter}>A</span> */}
          <input
            className={styles.input}
            type="text"
            name="password-email"
            id="email"
            ref={inputEmailRef}
            value={email}
            placeholder="user@email.ru"
            onChange={handleEmailChange}
          />
          <div className={styles.loader}>{isLoading ? <Spin /> : " "}</div>
        </div>

        <button className={styles.button} ref={buttonSendRequestRef}>
          Отправить
        </button>
        <div>
          <Link className={styles.back} to={AppRoute.Login} ref={linkLoginRef}>
            Вернуться
          </Link>
        </div>
      </form>
    </section>
  );
}

export default PasswordForgot;
