import { Button } from "antd";
import React from "react";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getParkNameById } from "../../../store/parks/selectors";
import {
    getTotalEventCount,
    getVolunteers,
    getVolunteersEventDate,
    getVolunteersEventId,
} from "../../../store/volunteers/selectors";
import styles from "./index.module.scss";

interface IProps {
    onClose: () => void;
}

const PrintVolunteers: React.FC<IProps> = ({ onClose }) => {
    const volunteers = useAppSelector(getVolunteers);
    const eventId = useAppSelector(getVolunteersEventId);
    const eventDate = useAppSelector(getVolunteersEventDate);
    const eventName = useAppSelector((state) =>
        getParkNameById(state, eventId)
    );
    const eventCount = useAppSelector(getTotalEventCount);
    /*  const [printed, setPrinted] = useState(false);
  useEffect(() => setPrinted(true), []);
  useEffect(() => {
    if (printed) return;
    console.log("PRINT, printed: ", printed);
    onClose();
    setPrinted(true);
    window.print();
    console.log(volunteers);
    return () => setPrinted(true);
  }, [printed]); */
    return (
        <div className={styles.wrapper}>
            <Button className={styles.closeButton} onClick={onClose}>
                Назад
            </Button>
            <Button
                className={styles.closeButton}
                onClick={() => window.print()}
            >
                Печать
            </Button>
            <div className={styles.eventData}>
                {eventName}, {eventDate}
                {eventCount ? ` №${eventCount + 1}` : ""}
            </div>
            <h1>Образец брифинга</h1>
            <section>
                <ul>
                    <li>
                        Добро пожаловать на 5 вёрст! Большое спасибо волонтерам
                        этой субботы. Только благодаря им сегодняшнее
                        мероприятие состоялось.
                    </li>
                    <li>
                        Представьте волонтеров, поблагодарите их, пригласите
                        всех желающих записываться в волонтеры на следующую
                        неделю прямо сейчас. Волонтерить совсем не сложно и даже
                        весело!
                    </li>
                    <li>
                        Сообщения по безопасности: состояние трассы (ремонт,
                        гололед, опасные участки)
                    </li>
                    <li>
                        На трассе работает парковая техника, будьте осторожны!{" "}
                    </li>
                    <li>
                        5 вёрст - это не соревнование! Будьте внимательны к себе
                        и к другим! В случае необходимости окажите первую
                        помощь, вызовите скорую, сообщите команде мероприятия
                    </li>
                    <li>
                        У нас нет приоритета перед другими посетителями парка.
                    </li>
                    <li>
                        Дети должны находиться под присмотром взрослых. Команда
                        5 вёрст не несет ответственности за детей. Возможно
                        участие с детской коляской (один участник - одна
                        коляска)
                    </li>
                    <li>
                        Просьба занять место на старте, чтобы не мешать
                        остальным
                    </li>
                    <li>
                        Участие с собаками разрешается при использовании
                        коротких поводков (1 участник - 1 собака)
                    </li>
                    <li>
                        Приглашаем всех сделать общее фото. Фотографии будут
                        доступны в наших социальных сетях
                    </li>
                </ul>
            </section>
            <table>
                <thead>
                    <tr>
                        <th className={styles.position}>Позиция</th>
                        <th className={styles.name}>Имя</th>
                    </tr>
                </thead>
                <tbody>
                    {volunteers.map((volunteer) => {
                        /* if (!volunteer.verst_id) return; */
                        return (
                            <tr key={volunteer.verst_id}>
                                <td className={styles.position}>
                                    {volunteer.role_name}
                                </td>
                                <td className={styles.name}>
                                    {volunteer.full_name}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PrintVolunteers;
