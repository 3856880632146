import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import {
    getCurrentRow,
    getIsUniqueVolunteer,
    getScrollToIndex,
} from "../../../store/volunteers/selectors";
import {
    clearVolunteer,
    deleteVolunteer,
    resetScrollToIndex,
    setCurrentRow,
} from "../../../store/volunteers/slice";
import { TVolunteer } from "../../../store/volunteers/types";
import Role from "../Role/Role";
import VolunteerId from "../Id/Id";
import Name from "../Name/Name";
import styles from "./index.module.scss";
import DeleteVolunteer from "../DeleteVolunteer/DeleteVolunteer";
import { getVolunteerSummary } from "../../../store/volunteers/thunk";
import { ONLY_ADULT_VOLUNTEER_ROLES } from "../../../const";
import VolunteerNotAdultModal from "../VolunteerNotAdultModal/VolunteerNotAdultModal";

interface IProps {
    volunteer: TVolunteer;
    index: number;
}

const Row: React.FC<IProps> = ({ volunteer, index }) => {
    const dispatch = useAppDispatch();
    const currentRow = useAppSelector(getCurrentRow);
    const pageRef = useRef<HTMLTableRowElement>(null);
    const scrollToIndex = useAppSelector(getScrollToIndex);
    const isEmptyVolunteer = volunteer.full_name === "" && !volunteer.verst_id;
    const isUniqueVolunteer = useAppSelector((state) =>
        getIsUniqueVolunteer(state, volunteer)
    );

    const [showUnregisteredId, setShowUnregisteredId] = useState(false); //Показать ID незарегистрированного для редактирования
    const [ isModalVolunteerNotAdult, setIsModalVolunteerNotAdult ] = useState(false);

    useEffect(() => {
        if (scrollToIndex !== null && pageRef.current) {
            pageRef.current.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
            dispatch(resetScrollToIndex());
        }
    }, [scrollToIndex, dispatch]);

    useEffect(() => {
        // При смене фокуса не показывать Id незарегистрированного
        if (currentRow !== index) {
            setShowUnregisteredId(false);
        }
    }, [currentRow, index]);

    const handleRowClick = () => {
        // выделяем строку
        if (currentRow !== index) {
            dispatch(setCurrentRow(index));
        }
    };

    const handleDelete = () => {
        if (isEmptyVolunteer) {
            dispatch(deleteVolunteer(index));
        } else {
            dispatch(clearVolunteer(index));
        }
    };

    const handleGetVolunteerSummary = () => {
        if (volunteer.verst_id) {
            dispatch(getVolunteerSummary(volunteer.verst_id));
            console.log("SHOW SUMMARY FOR ID ", volunteer.verst_id);
        }
    };

    const toggleShowUnregisteredId = () => {
        setShowUnregisteredId((show) => !show);
    };

    const handleNotAdultIconClick = (evt: React.MouseEvent) => {
      evt.preventDefault();
      setIsModalVolunteerNotAdult(true);
    }

    const classNames = `${
        volunteer.verst_id && volunteer.is_record_confirmed === false
            ? styles.warn
            : ""
    } ${currentRow === index ? styles.currentRow : ""} ${
        isUniqueVolunteer ? "" : styles.error
    }`;

    return (
      <>
        <tr
          ref={index === scrollToIndex ? pageRef : null}
          onClick={handleRowClick}
          className={classNames}
        >
          <td className={styles.role}>
              <Role
                  roleId={volunteer.role_id}
                  index={index}
                  roleName={volunteer.role_name}
              />
          </td>
            <td className={styles.id}>
              {volunteer.verst_id &&
              volunteer.is_record_confirmed === false &&
              !showUnregisteredId ? (
                  <span onClick={toggleShowUnregisteredId}>
                      Не зарегистрирован
                  </span>
              ) : (
                  <VolunteerId
                      volunteerId={volunteer.verst_id}
                      index={index}
                  />
              )}
            </td>
            <td className={styles.name}>
              <div className={styles.nameContent}>
                <Name name={volunteer.full_name} index={index} />{" "}
                {volunteer.is_adult === false && (volunteer.role_name && ONLY_ADULT_VOLUNTEER_ROLES.includes(volunteer.role_name)) ? (
                    <span
                        className={styles.isAdultFlag}
                        onClick={handleNotAdultIconClick}
                    >
                        {volunteer.is_adult === false ? "<18!" : ""}
                    </span>
                ) : null}
                {volunteer.volunteering_count ? (
                    <span
                        className={styles.volunteeringCount}
                        onClick={handleGetVolunteerSummary}
                    >
                        {volunteer.volunteering_count || ""}
                    </span>
                ) : null}
                {/*  <div className={styles.nameWithCount}>
                </div> */}
                <DeleteVolunteer
                    deleteWithRole={isEmptyVolunteer}
                    onDelete={handleDelete}
                />
              </div>
            </td>
          </tr>
          {isModalVolunteerNotAdult &&
          <VolunteerNotAdultModal
              isModalOpen={isModalVolunteerNotAdult}
              handleVolunteerNotAdultModal={setIsModalVolunteerNotAdult}
          />}
      </>
    );
};

export default Row;
