import React from "react";

interface IProps {
  file: File;
  index: number;
  onDelete: (index: number) => void;
}

const FileDisplay: React.FC<IProps> = ({ file, index, onDelete }) => {
  return (
    <div className="file-display">
      {file.name}{" "}
      <span className="delete-span" onClick={() => onDelete(index)}>
        &times;
      </span>
    </div>
  );
};

export default FileDisplay;
