import { combineReducers } from "redux";
import cancelEventReducer from "./cancelEvent/slice";
import extraEventsReducer from "./extraEvents/slice";
import incidentReducer from "./incident/slice";
import resultsReducer from "./results/slice";
import userReducer, { userLogout } from "./user/slice";
import parksReducer from "./parks/slice";
import volunteersReducer from "./volunteers/slice";
import searchReducer from "./search/slice";
import passwordReducer from "./password/slice";
import { createAPI } from "../services/api";
import { configureStore } from "@reduxjs/toolkit";
import { dropToken } from "../services/token";

// потом вынести в const
export enum NameSpace {
  cancelEvent = "CANCEL_EVENT",
  extraEvents = "EXTRA_EVENTS",
  incident = "INCIDENT",
  parks = "PARKS",
  results = "RESULTS",
  search = "SEARCH",
  user = "USER",
  volunteers = "VOLUNTEERS",
  users = "USERS",
  password = "PASSWORD",
}

// export const api = createAPI(() =>
//   store.dispatch(requireAuthorization(AuthorizationStatus.NoAuth))
// );

// createAPI получает функцию которая выполняется при респонсе 401
export const api = createAPI(() => {
  dropToken();
  store.dispatch(userLogout());
  console.log("Токен просрочен");
});

export const rootReducer = combineReducers({
  [NameSpace.cancelEvent]: cancelEventReducer,
  [NameSpace.extraEvents]: extraEventsReducer,
  [NameSpace.incident]: incidentReducer,
  [NameSpace.parks]: parksReducer,
  [NameSpace.results]: resultsReducer,
  [NameSpace.search]: searchReducer,
  [NameSpace.user]: userReducer,
  [NameSpace.volunteers]: volunteersReducer,
  [NameSpace.password]: passwordReducer,
});

export const store = configureStore({ reducer: rootReducer });

export type AppDispatch = typeof store.dispatch;

// Может поломаться, говорить что типизация по кругу, тогда надо будет типизировать
// взяв типы редюсеров
export type RootState = ReturnType<typeof rootReducer>;
