import { notification } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { DATE_FORMAT, VolunteersStatusId, EventStatus } from "../const";
import {
    getIsResultsOnSite,
    getVolunteers,
    getVolunteersEventDate,
    getVolunteersEventId,
    getEventStatusId
} from "../store/volunteers/selectors";
import {
    clearVolunteersAction,
    saveVolunteersAction,
} from "../store/volunteers/thunk";
import { TSaveData } from "../store/volunteers/types";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";
import {
    getNotUniqueVolunteerIndex,
    isUnregisteredVolunteer,
} from "../utils/volunteers";
import { setScrollToIndex } from "../store/volunteers/slice";
import { setCurrentRow } from "../store/volunteers/slice";

export const useSaveVolunteers = () => {
    const dispatch = useAppDispatch();
    const eventDate = useAppSelector(getVolunteersEventDate);
    const eventId = useAppSelector(getVolunteersEventId);
    const eventStatus = useAppSelector(getEventStatusId);
    const volunteers = useAppSelector(getVolunteers);
    const resultsOnSite = useAppSelector(getIsResultsOnSite);
    const [canLoadOnSite, setCanLoadOnSite] = useState(false);

    useEffect(() => {
        if (eventDate && moment(eventDate, DATE_FORMAT).isBefore(new Date())) {
            setCanLoadOnSite(true);
        } else {
            setCanLoadOnSite(false);
        }
    }, [eventDate]);

    const saveVolunteers = (statusId: VolunteersStatusId): void => {
        let status = statusId;
        let warning = false;

        if (eventStatus === EventStatus.Pause) {
            notification.error({ message: `Мероприятие на паузе, загрузка волонтеров невозможна. 
            В случае вопросов обратитесь к вашему амбассадору поддержки или на eventsupport@5verst.ru` });
            return;
        }

        if (!eventId || eventId < 0) {
            notification.warn({ message: "Выберите старт" });
            return;
        }
        if (!eventDate) {
            notification.warn({ message: "Выберите дату" });
            return;
        }

        if (isUnregisteredVolunteer(volunteers)) {
            notification.error({
                message:
                    "Ошибка: незарегистрированный волонтёр. Попросите волонтёра зарегистрироваться на 5verst.ru или удалите его из списка",
            });
            return;
        }

        const notUniqueIndex = getNotUniqueVolunteerIndex(volunteers);
        if (notUniqueIndex !== -1) {
            notification.error({ message: "Ошибка: дублирующийся волонтер!" });
            dispatch(setScrollToIndex(notUniqueIndex));
            dispatch(setCurrentRow(notUniqueIndex));
            return;
        }
        if (status === VolunteersStatusId.Draft && canLoadOnSite) {
            // status = 1;
            warning = true; // Выводим предупреждение что сохранены а не загружены на сайт
        }
        // @ts-ignore
        let clearedVolunteers: { verst_id: number; role_id: number }[] =
            volunteers
                .filter(
                    (volunteer) =>
                        volunteer.role_id !== null &&
                        volunteer.verst_id !== null
                )
                .map((volunteer) => {
                    return {
                        verst_id: volunteer.verst_id,
                        role_id: volunteer.role_id,
                    };
                });
        if (clearedVolunteers.length === 0) {
            // notification.warn({ message: "Не выбраны волонтёры" });
            dispatch(
                clearVolunteersAction({
                    event_id: eventId,
                    event_date: eventDate,
                })
            );
            return;
        }
        // На всякий случай, если прорвется через задизэйбленую кнопку.
        if (statusId === VolunteersStatusId.Final && !resultsOnSite) {
            notification.error({
                message:
                    "Перед загрузкой на сайт финального списка волонтёров необходимо сначала загрузить результаты!",
            });
            return;
        }

        let data: TSaveData = {
            event_id: eventId,
            date: eventDate,
            upload_status_id: status,
            volunteers: clearedVolunteers,
        };
        dispatch(saveVolunteersAction({ data, warning }));
    };

    return { saveVolunteers, canLoadOnSite };
};
