import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppRoute, AuthorizationStatus } from "../../const";
import {
  getAuthLoading,
  getAuthorizationStatus,
} from "../../store/user/selectors";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { loginAction } from "../../store/user/thunk";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { Spin } from "antd";
import styles from "./index.module.scss";

const SignInScreen: React.FC = () => {
  const authorizationStatus = useAppSelector(getAuthorizationStatus);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState({ login: "", password: "" });
  const [showError, setShowError] = useState({ login: false, password: false });
  const [passwordType, setPasswordType] = useState("password");
  let myLoginRef = useRef<HTMLInputElement>(null); // Для фокуса
  let passwordRef = useRef<HTMLInputElement>(null);
  let linkForgotPasswordRef = useRef<HTMLAnchorElement>(null);

  const isLoading = useAppSelector(getAuthLoading);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (authorizationStatus === AuthorizationStatus.Auth)
      navigate(AppRoute.Home);
  }, [authorizationStatus, navigate]);

  useEffect(() => myLoginRef.current?.focus(), []);

  let validate = () => {
    let loginError = "";
    let passwordError = "";

    if (!login) {
      loginError = "Введите логин";
    }
    if (!password) {
      passwordError = "Введите пароль";
    } else passwordError = "";
    setMessage({ login: loginError, password: passwordError });
  };

  // Если убрать этот UseEffect то при первом вводе не будет проверки и отправится пустая форма
  useEffect(validate, [login, password]);

  const isValid = () => {
    if (message.password.length > 0 || message.login.length > 0) return false;
    return true;
  };

  const handleSubmit = () => {
    // пачкаем поля чтобы выдало предупреждение.
    setShowError({ login: true, password: true });
    /* validate(); // Если логин и пароль не менялся то валидации не было 
    текущий валидэйт не успеет дойти через стэйт до isValid потому что там message*/

    if (isValid()) {
      dispatch(
        loginAction({
          username: `A${login}`,
          password,
        })
      );
    }
  };

  const handleLoginChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.match(/^\d{0,9}$/)) {
      setLogin(e.target.value);
      // После первого ввода делаем поле чистым чтобы предупреждение ушло
      if (e.target.value) {
        setShowError({ ...showError, login: false });
      }
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
    // После первого ввода делаем поле чистым чтоб предупреждение ушло
    if (e.target.value) {
      setShowError({ ...showError, password: false });
    }
  };

  const togglePasswordType = () => {
    if (passwordType === "text") {
      setPasswordType("password");
    } else {
      setPasswordType("text");
    }
  };

  const handlePasswordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleLoginKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Tab") {
      e.preventDefault();
      linkForgotPasswordRef.current?.focus();
    }
  };
  const handleForgotPasswordKeyDown = (
    e: React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    if (e.key === "Tab") {
      e.preventDefault();
      passwordRef.current?.focus();
    }
  };

  return (
    <div className={styles.wrapper}>
      <main>
        <div className={styles.logo}>
          <img src="./img/Logo_B.png" alt="logo" />
        </div>
        <section>
          {/* <h1>Авторизация</h1> */}
          <form>
            <div className={styles.input}>
              <label className={styles.login} htmlFor="login">
                Логин:
              </label>
              <input
                className={styles.login}
                ref={myLoginRef}
                value={login}
                placeholder="123456789"
                type="text"
                name="username"
                id="login"
                onChange={handleLoginChange}
                onKeyDown={handleLoginKeyDown}
              />
              <div className={styles.danger}>
                {showError.login && message.login ? message.login : " "}
              </div>
            </div>
            <div className={styles.input}>
              <label htmlFor="password">Пароль:</label>
              <div>
                <input
                  ref={passwordRef}
                  value={password}
                  type={passwordType}
                  name="password"
                  id="password"
                  onChange={handlePasswordChange}
                  onKeyDown={handlePasswordKeyDown}
                  // required
                />
                <span className={styles.eye} onClick={togglePasswordType}>
                  {passwordType === "password" ? (
                    <span>
                      <EyeOutlined />
                    </span>
                  ) : (
                    <EyeInvisibleOutlined />
                  )}
                </span>
                <div className={styles.danger}>
                  {showError.password && message.password
                    ? message.password
                    : " "}
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className={styles.spinner}>
                <Spin size="large" />
              </div>
            ) : null}
          </form>{" "}
          <button
            className={styles.submit}
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Вход
          </button>{" "}
          <div className={styles.remind}>
            <Link
              ref={linkForgotPasswordRef}
              to={AppRoute.RemindPassword}
              onKeyDown={handleForgotPasswordKeyDown}
            >
              Забыли пароль?
            </Link>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SignInScreen;
