import React from "react";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { getVolunteers } from "../../store/volunteers/selectors";
import Row from "./Row/Row";
import styles from "./index.module.scss";

const VolunteersTable = () => {
  const volunteers = useAppSelector(getVolunteers);

  return (
    <table className={styles.volunteersTable}>
      <thead>
        <tr>
          <th>Позиция</th>
          <th>ID волонтёра</th>
          <th>Имя волонтёра</th>
        </tr>
      </thead>
      <tbody>
        {volunteers.map((volunteer, i) => (
          <Row key={i} volunteer={volunteer} index={i} />
        ))}
      </tbody>
    </table>
  );
};

export default VolunteersTable;
