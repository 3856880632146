import { CloseCircleOutlined } from "@ant-design/icons";
import React from "react";
import { MessageType } from "../../../const";
import Message from "../Message/Message";
import styles from "./index.module.scss";

const CancelEventMessage = () => {
  return (
    <Message type={MessageType.error}>
      <span className={styles.message}>
        <CloseCircleOutlined style={{ fontSize: 24, color: "red" }} />
        <span>Мероприятие отменено</span>
      </span>
    </Message>
  );
};

export default CancelEventMessage;
