import { ExtLink, REACT_APP_WEBSITE_URL } from "../const";
import { getParkUrlById } from "../store/parks/selectors";
import { isFutureDate } from "../utils";
import { useAppSelector } from "./useAppSelector";

const useLinks = (eventId: number | null, eventDate: string) => {
  const parkUrl = useAppSelector((state) => getParkUrlById(state, eventId));
  let resultsLink = "",
    roasterLink = "";
  const linkDate = isFutureDate(eventDate) ? "latest" : `${eventDate}`;
  if (parkUrl) {
    resultsLink = `${REACT_APP_WEBSITE_URL}/${parkUrl}${ExtLink.Results}/${linkDate}`;
    roasterLink = `${REACT_APP_WEBSITE_URL}/${parkUrl}${ExtLink.Volunteers}`;
  }
  return [resultsLink, roasterLink];
};
export default useLinks;
