import { useLocation } from "react-router-dom";
import { AppRoute } from "../../const";
import Logo from "./Logo/Logo";
import LogoutLink from "./LogoutLink/LogoutLink";
import Menu from "./Menu/Menu";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";

function Header(): JSX.Element {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const logoIsLink =
    location.pathname !== AppRoute.Login && location.pathname !== AppRoute.Home;
  if (
    location.pathname === AppRoute.Login ||
    location.pathname === AppRoute.RemindPassword
  )
    return <></>;

  let burgerStyle = open
    ? `${styles.header_burger} ${styles.active}`
    : styles.header_burger;
  let menuStyle = open ? `${styles.menu} ${styles.active}` : styles.menu;
  let wrapperStyle = open
    ? `${styles.wrapper} ${styles.active}`
    : styles.wrapper;

  const setActiveStyles = () => {
    setOpen(!open);
  };

  return (
    <header className={wrapperStyle}>
      <Logo isLink={logoIsLink} />
      <div className={menuStyle}>
        {location.pathname !== AppRoute.Login && <Menu />}{" "}
        {location.pathname === AppRoute.Login ? "" : <LogoutLink />}
      </div>

      <div className={burgerStyle} onClick={setActiveStyles}>
        <span />
      </div>
    </header>
  );
}

export default Header;
