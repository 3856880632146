import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventStatus } from "../../const";
import {
  cancelEventAction,
  getEventStatusAction,
  restoreEventAction,
} from "./thunk";
import { TCancelEventReducer } from "./types";

const initialState: TCancelEventReducer = {
  loading: false,
  error: null,
  errorCode: 0,
  errorMessage: "",
  event_id: null,
  event_date: "",
  cancelReason: "",
  eventStatus: null,
};

export const cancelEventSlice = createSlice({
  name: "cancelEventSlice",
  initialState,
  reducers: {
    setCancelEventId: (
      state: TCancelEventReducer,
      action: PayloadAction<number | null>
    ) => {
      state.event_id = action.payload;
    },
    setCancelEventDate: (
      state: TCancelEventReducer,
      action: PayloadAction<string>
    ) => {
      state.event_date = action.payload;
    },
    setCancelReason: (
      state: TCancelEventReducer,
      action: PayloadAction<string>
    ) => {
      state.cancelReason = action.payload;
    },
    resetCancelData: (state: TCancelEventReducer) => {
      state.cancelReason = "";
      state.error = "";
      state.errorCode = 0;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [cancelEventAction.pending.type]: (state: TCancelEventReducer) => {
      state.loading = true;
      state.error = null;
    },
    [cancelEventAction.fulfilled.type]: (
      state: TCancelEventReducer,
      { payload }: PayloadAction<EventStatus>
    ) => {
      state.loading = false;
      state.eventStatus = payload;
    },
    [cancelEventAction.rejected.type]: (
      state: TCancelEventReducer,
      { payload }: PayloadAction<string>
    ) => {
      state.loading = false;
      state.error = payload;
    },
    [restoreEventAction.pending.type]: (state: TCancelEventReducer) => {
      state.loading = true;
      state.error = null;
    },
    [restoreEventAction.fulfilled.type]: (
      state: TCancelEventReducer,
      { payload }: PayloadAction<EventStatus>
    ) => {
      state.loading = false;
      state.eventStatus = payload;
    },
    [restoreEventAction.rejected.type]: (
      state: TCancelEventReducer,
      { payload }: PayloadAction<string>
    ) => {
      state.loading = false;
      state.error = payload;
    },
    [getEventStatusAction.pending.type]: (state: TCancelEventReducer) => {
      state.loading = true;
      state.error = null;
    },
    [getEventStatusAction.fulfilled.type]: (
      state: TCancelEventReducer,
      { payload }: PayloadAction<number>
    ) => {
      state.loading = false;
      state.eventStatus = payload;
    },
    [getEventStatusAction.rejected.type]: (
      state: TCancelEventReducer,
      { payload }: PayloadAction<string>
    ) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  setCancelEventId,
  setCancelEventDate,
  setCancelReason,
  resetCancelData,
} = cancelEventSlice.actions;

export default cancelEventSlice.reducer;
