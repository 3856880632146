import React from "react";
import { MessageType, VolunteersStatusId } from "../../../const";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import useLinks from "../../../customHooks/useLinks";
import {
    getIsResultsOnSite,
    getShowResultsNotSavedMessage,
    getVolunteersEventDate,
    getVolunteersEventId,
    getVolunteersStatus,
} from "../../../store/volunteers/selectors";
import Message from "../../Common/Message/Message";
import styles from "./index.module.scss";

const VolunteersLink = () => {
    const showResultsNotSavedMessage = useAppSelector(
        getShowResultsNotSavedMessage
    );
    const resultsOnSite = useAppSelector(getIsResultsOnSite);
    const eventId = useAppSelector(getVolunteersEventId);
    const eventDate = useAppSelector(getVolunteersEventDate);
    const volunteersStatus = useAppSelector(getVolunteersStatus);
    // const roasterLink = useAppSelector((state) =>
    //   getVolunteersLink(state, eventId)
    // );
    // const resultsLink = useAppSelector(getResultsLink);
    const [resultsLink, roasterLink] = useLinks(eventId, eventDate);

    // if (resultsOnSite === null) return null;

    if (volunteersStatus === VolunteersStatusId.Final && resultsOnSite) {
        return resultsLink.length > 0 ? (
            <a
                className={styles.websiteLink}
                href={resultsLink}
                target="_blank"
                rel="noreferrer"
            >
                Посмотреть результаты на сайте
            </a>
        ) : null;
    }

    return (
        <>
            {roasterLink.length > 0 && (
                <>
                    {showResultsNotSavedMessage && (
                        <Message type={MessageType.warning}>
                            Перед сохранением финального списка волонтёров
                            необходимо сначала загрузить результаты!
                        </Message>
                    )}
                    <a
                        className={styles.websiteLink}
                        href={roasterLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Посмотреть график волонтеров на сайте
                    </a>
                </>
            )}
        </>
    );
};

export default VolunteersLink;
