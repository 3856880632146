import { Button, Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoute, DATE_FORMAT, EventStatus } from "../../const";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { useAppSelector } from "../../customHooks/useAppSelector";
import {
    addHistory,
    setResultsActive,
    setResultsEventDate,
    setResultsEventId,
    setShowLoadVolunteersModal,
} from "../../store/results/slice";
import { getEventData, getIsDataLoaded } from "../../store/results/selectors";
import {
    loadResultsAction,
    saveResultsAction,
} from "../../store/results/thunk";
import ResultsTable from "../../components/ResultsTable/ResultsTable/ResultsTable";
import EventName from "../../components/Common/EventName/EventName";
import EventDate from "../../components/Common/EventDate/EventDate";
import styles from "./index.module.scss";
import UndoButton from "../../components/ResultsTable/UndoButton/UndoButton";
import RedoButton from "../../components/ResultsTable/RedoButton/RedoButton";
import moment from "moment";
import {
    setVolunteersEventDate,
    setVolunteersEventId,
} from "../../store/volunteers/slice";
import useLinks from "../../customHooks/useLinks";
import CancelEventMessage from "../../components/Common/CancelEventMessage/CancelEventMessage";
import WebsiteLink from "../../components/Common/WebsiteLink/WebsiteLink";
import DoubleResultsError from "../../components/ResultsTable/DoubleResultsError/DoubleResultsError";
import { protocolTooOldToChange } from "../../utils";
import { TResultType } from "../../store/results/types";
import { isTimeGTMintime } from "../../utils/addTime";
import { getIsUserAdmin } from "../../store/user/selectors";

function ResultsScreen(): JSX.Element {
    const eventData = useAppSelector(getEventData);
    const isDataLoaded = useAppSelector(getIsDataLoaded);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isUserAdmin = useAppSelector(getIsUserAdmin);
    // const link = useAppSelector((state) =>
    //   getResultsLink(state, eventData.event_id)
    // );
    const [link] = useLinks(eventData.event_id, eventData.event_date);

    const [canLoadOnSite, setCanLoadOnSite] = useState(false);

    let sendEventData = {
        event_id: eventData.event_id,
        event_date: eventData.event_date,
        results: eventData.results,
    };

    useEffect(() => {
        document.title = "NRMS - результаты";
    }, []);

    // Проверяем что дата не позже текущей
    useEffect(() => {
        if (
            eventData.event_date &&
            !moment(eventData.event_date, DATE_FORMAT).isAfter(new Date())
        ) {
            setCanLoadOnSite(true);
        } else {
            setCanLoadOnSite(false);
        }
    }, [eventData.event_date]);

    useEffect(() => {
        if (eventData.results.length > 0 && eventData.needWriteHistory)
            dispatch(addHistory());
    }, [eventData.results, eventData.needWriteHistory, dispatch]);

    // Пока не покидаем страницу результатов, потому что у нас появилась ссылка.
    /*   useEffect(() => {
    if (resultsNavigate) {
      dispatch(resetNavigateFlag());
      navigate(AppRoute.Home);
    }
  }, [resultsNavigate, dispatch, navigate]); */

    useEffect(() => {
        // Если есть данные то не грузим. Если поменяли дату или парк то сбрасываем флаг.
        if (isDataLoaded) {
            return;
        }
        if (eventData.event_id && eventData.event_date) {
            dispatch(
                loadResultsAction({
                    event_id: eventData.event_id,
                    date: eventData.event_date,
                })
            );
        }
    }, [eventData.event_id, eventData.event_date, isDataLoaded, dispatch]);

    const canFindFasterMinimumTime = (results: TResultType[]) => {
        const problemIndex = results.findIndex(
            (result) => !isTimeGTMintime(result.finish_time)
        );
        if (problemIndex !== -1) {
            return true;
        }
        return false;
    };

    const areResultsValid = (results: TResultType[]) => {
        if (canFindFasterMinimumTime(results)) {
            notification.error({
                message: "Пожалуйста, проверьте время финиша",
            });
            return false;
        }
        return true;
    };

    const handleSaveResults = () => {
        if (areResultsValid(eventData.results)) {
            dispatch(saveResultsAction(sendEventData));
        }
    };

    const navigateVolunteers = () => {
        dispatch(setVolunteersEventId(eventData.event_id));
        dispatch(setVolunteersEventDate(eventData.event_date));
        dispatch(setShowLoadVolunteersModal(false));
        navigate(AppRoute.Volunteers);
    };

    const hideModal = () => {
        dispatch(setShowLoadVolunteersModal(false));
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.head}>
                    <div className={styles.event}>
                        <div className={styles.eventName}>
                            <EventName
                                eventId={eventData.event_id}
                                setEventId={setResultsEventId}
                            />
                        </div>

                        <EventDate
                            eventDate={eventData.event_date}
                            setEventDate={setResultsEventDate}
                        />
                    </div>
                    {eventData.status?.id !== EventStatus.Cancel && (
                        <div className={styles.undoRedo}>
                            <UndoButton />
                            <RedoButton />
                        </div>
                    )}
                </div>
                {eventData.status?.id === EventStatus.Cancel ? (
                    <>
                        <CancelEventMessage />
                        <WebsiteLink
                            link={link}
                            text={"Посмотреть результаты на сайте"}
                        />
                    </>
                ) : (
                    <>
                        <div className={styles.scrollWrapper}>
                            <ResultsTable />
                        </div>
                        <WebsiteLink
                            link={link}
                            text={"Посмотреть результаты на сайте"}
                        />
                        {eventData.doubleResults.length > 0 && (
                            <DoubleResultsError />
                        )}
                        <div className="table-buttons">
                            <Button
                                type="primary"
                                disabled={
                                    eventData?.results?.length === 0 ||
                                    !canLoadOnSite ||
                                    eventData.loading ||
                                    (protocolTooOldToChange(
                                        eventData.event_date
                                    ) &&
                                        !isUserAdmin)
                                }
                                onClick={handleSaveResults}
                            >
                                Сохранить результаты
                            </Button>
                            <Button
                                type="default"
                                onClick={() => {
                                    dispatch(setResultsActive(false));
                                    navigate(AppRoute.ResultsFilesUpload);
                                }}
                            >
                                Вернуться к загрузке файлов
                            </Button>
                        </div>
                        <Modal
                            wrapClassName="results-modal"
                            visible={eventData.showLoadVolunteersModal}
                            onOk={navigateVolunteers}
                            onCancel={hideModal}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <h2>
                                Результаты сохранены. Список волонтеров на{" "}
                                {eventData.event_date} не загружен. Перейти к
                                загрузке волонтеров?
                            </h2>
                        </Modal>
                    </>
                )}
            </div>
        </div>
    );
}

export default ResultsScreen;
