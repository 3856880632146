import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAthlete } from "../types";
import { loadEmergencyData, sendIncidentReport } from "./thunk";
import { TIncidentReducer, TLoadEmergencyDataResponse } from "./types";
import { getSaturdayString, isFutureDate } from "../../utils";
import { notification } from "antd";

const emptyData = {
    first_name: "",
    last_name: "",
    birth_year: "",
    ice_name: "",
    ice_contact: "",
};

const initialState: TIncidentReducer = {
    eventId: null,
    athlete: null,
    errorCode: null,
    errorMessage: "",
    emergencyData: emptyData,
    reportData: {
        reportMessage: "",
        incidentDate: getSaturdayString(),
    },
    loading: false,
    error: null,
};

export const incidentSlice = createSlice({
    name: "IncidentSlice",
    initialState,
    reducers: {
        clearIncident: () => initialState,
        setIncidentEventId: (
            state: TIncidentReducer,
            { payload }: PayloadAction<number | null>
        ) => {
            state.eventId = payload;
        },
        setIncidentAthlete: (
            state: TIncidentReducer,
            { payload }: PayloadAction<TAthlete>
        ) => {
            state.athlete = payload;
        },
        setIncidentReportMessage: (
            state: TIncidentReducer,
            { payload }: PayloadAction<string>
        ) => {
            state.reportData.reportMessage = payload;
        },
        setIncidentDate: (
            state: TIncidentReducer,
            { payload }: PayloadAction<string>
        ) => {
            if (!isFutureDate(payload)) {
                state.reportData.incidentDate = payload;
            } else {
                notification.error({
                    message: "Нельзя зарегистрировать инцидент в будущем",
                });
            }
        },
    },
    extraReducers: {
        [loadEmergencyData.pending.type]: (state: TIncidentReducer) => {
            state.loading = true;
            state.error = null;
        },
        [loadEmergencyData.fulfilled.type]: (
            state: TIncidentReducer,
            { payload }: PayloadAction<TLoadEmergencyDataResponse>
        ) => {
            state.emergencyData = payload.result;
            state.errorCode = payload.errorCode;
            state.errorMessage = payload.errorMessage;
            state.loading = false;
        },
        [loadEmergencyData.rejected.type]: (
            state: TIncidentReducer,
            { payload }: PayloadAction<any>
        ) => {
            state.emergencyData = emptyData;
            state.loading = false;
            state.errorCode = payload.result?.errorCode;
            state.errorMessage = payload.result?.errorMessage;
            state.error = payload;
        },
        [sendIncidentReport.pending.type]: (state: TIncidentReducer) => {
            state.loading = true;
            state.error = null;
        },
        [sendIncidentReport.fulfilled.type]: (state: TIncidentReducer) => {
            state.loading = false;
        },
        [sendIncidentReport.rejected.type]: (
            state: TIncidentReducer,
            { payload }: PayloadAction<any>
        ) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const {
    setIncidentEventId,
    setIncidentAthlete,
    clearIncident,
    setIncidentReportMessage,
    setIncidentDate,
} = incidentSlice.actions;
export default incidentSlice.reducer;
