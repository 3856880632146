import { Link } from "react-router-dom";
import { AppRoute } from "../../../const";
import styles from "./index.module.scss";

interface IProps {
  isLink: boolean;
}

const Logo: React.FC<IProps> = ({ isLink }) => {
  const classNames = isLink ? styles.logo : `${styles.logo} ${styles.disabled}`;
  return (
    <Link className={classNames} to={AppRoute.Home}>
      <img
        className="logo__img"
        src="./img/5_verst_logo.png"
        alt="5 verst logo"
        width="100"
        height="29"
      />
    </Link>
  );
};

export default Logo;
