import { createSelector } from "@reduxjs/toolkit";
import { NameSpace, RootState } from "../root-reducer";
import { ParkType } from "./types";

export const getUserParks = (state: RootState): ParkType[] =>
  state[NameSpace.parks].userParks;

export const getParkUrlById = createSelector(
  [getUserParks, (_, id: number | null) => id],
  (parks, id): string => {
    if (id) {
      let parkById = parks.find((park) => park.id === id);
      if (parkById) return parkById.url || "";
    }
    return "";
  }
);

export const getParkNameById = createSelector(
  [getUserParks, (_, id: number | null) => id],
  (parks, id): string => {
    if (id) {
      let parkById = parks.find((park) => park.id === id);
      if (parkById) return parkById.name;
    }
    return "";
  }
);
