import React from "react";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getResults } from "../../../store/results/selectors";
import ResultsRow from "../Row/Row";
import styles from "./index.module.scss";

const ResultsTable = () => {
  const results = useAppSelector(getResults);

  return (
    <table className={styles.resultsTable}>
      <thead>
        <tr className={styles.header}>
          <th className={styles.position}>Позиция</th>
          <th className={styles.time}>Результат</th>
          <th className={styles.verstId}>ID участника</th>
          <th className={styles.name}>Имя</th>
        </tr>
      </thead>
      <tbody>
        {results.map((result, i) => (
          <ResultsRow key={i} result={result} />
        ))}
      </tbody>
    </table>
  );
};

export default ResultsTable;
