import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { APIRoute, ErrorCodes, WarningMessage } from "../../const";
import { api } from "../root-reducer";
import {
  SetPasswordData,
  UpdatePasswordData,
  ChangePasswordResponse,
} from "./types";

export const requestPasswordAction = createAsyncThunk(
  "data/password-request",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await api.post<ChangePasswordResponse>(
        APIRoute.RequestPasswordChange,
        {
          email,
        }
      );
      if (response.status === 200) {
        notification.success({
          message: WarningMessage.PasswordRequestSuccess,
        });
      }
    } catch (error: any) {
      switch (error.response.data.errorCode) {
        case ErrorCodes.BadIDRequest:
          notification.warn({ message: WarningMessage.BadIDRequest });
          break;
        case ErrorCodes.AthleteNotFound:
          notification.warn({ message: WarningMessage.AthleteNotFound });
          break;
        case ErrorCodes.InternalSystemError:
          notification.warn({ message: WarningMessage.InternalSystemError });
          break;
        default:
          notification.warn({ message: WarningMessage.NetworkError });
      }

      return rejectWithValue(error.message);
    }
  }
);

export const setPasswordAction = createAsyncThunk(
  "data/password-set",
  async ({ password, hash }: SetPasswordData, { rejectWithValue }) => {
    try {
      const response = await api.post<ChangePasswordResponse>(
        APIRoute.SetNewPassword,
        {
          password,
          hash,
        }
      );
      if (response.status === 200) {
        notification.success({ message: WarningMessage.PasswordSetSuccess });
      }
    } catch (error: any) {
      switch (error.response.data.errorCode) {
        case ErrorCodes.InternalSystemError:
          notification.warn({ message: WarningMessage.InternalSystemError });
          break;
        default:
          notification.warn({ message: WarningMessage.NetworkError });
      }

      return rejectWithValue(error.message);
    }
  }
);

export const updatePasswordAction = createAsyncThunk(
  "data/password-set",
  async (
    { old_password, new_password }: UpdatePasswordData,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post<ChangePasswordResponse>(
        APIRoute.UpdateCurrentPassword,
        {
          old_password,
          new_password,
        }
      );
      if (response.status === 200) {
        notification.success({ message: WarningMessage.PasswordSetSuccess });
      }
    } catch (error: any) {
      switch (error.response.data.errorCode) {
        case ErrorCodes.WrongOldPassword:
          notification.warn({ message: WarningMessage.WrongOldPassword });
          break;
        case ErrorCodes.InternalSystemError:
          notification.warn({ message: WarningMessage.InternalSystemError });
          break;
        default:
          notification.warn({ message: WarningMessage.NetworkError });
      }

      return rejectWithValue(error.message);
    }
  }
);
