import React from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { stepHistoryBack } from "../../../store/results/slice";
import { isUndoHistory } from "../../../store/results/selectors";
import { Button } from "antd";
import { UndoOutlined } from "@ant-design/icons";

const UndoButton = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isHistoryFlag = useAppSelector(isUndoHistory);

  const handleUndo = () => {
    dispatch(stepHistoryBack());
  };

  return (
    <Button
      onClick={handleUndo}
      icon={<UndoOutlined />}
      disabled={!isHistoryFlag}
    />
  );
};

export default UndoButton;
