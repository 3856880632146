import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Select } from "antd";
import React from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getUserParks } from "../../../store/parks/selectors";
import styles from "./index.module.scss";
import { isParkExtraEventAgree } from "../../../store/results/selectors";
import { setResultsEventDate } from "../../../store/results/slice";
import { getExtraEventDate } from "../../../utils";

interface IProps {
  eventId: number | null;
  setEventId: ActionCreatorWithPayload<number | null>;
}

const EventName: React.FC<IProps> = ({ eventId, setEventId }) => {
  const { Option } = Select;
  const parks = useAppSelector(getUserParks);
  const isParkAgree = useAppSelector(isParkExtraEventAgree);
  const extraEventDay = getExtraEventDate();
  // const eventId = useAppSelector(getEventId);
  const dispatch = useAppDispatch();

  const onParkChange = (id: number) => {
    dispatch(setEventId(id));
    // комментим 06.11.24
    if (isParkAgree) {
      dispatch(setResultsEventDate(extraEventDay));
    }
  };

  return (
    <div className={styles.wrapper}>
      <Select
        placeholder="Выберите старт"
        value={eventId}
        onChange={onParkChange}
        // style={{ width: 255 }}
        defaultValue={eventId}
      >
        {parks?.map((park) => (
          <Option key={park.id} value={park.id}>
            {park.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default EventName;
