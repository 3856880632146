import { NameSpace, RootState } from "../root-reducer";

export const getIsEmergencyLoading = (state: RootState) =>
    state[NameSpace.incident].loading;
export const getIncidentEventId = (state: RootState) =>
    state[NameSpace.incident].eventId;
export const getIncidentAthlete = (state: RootState) =>
    state[NameSpace.incident].athlete;
export const getEmergecyData = (state: RootState) =>
    state[NameSpace.incident].emergencyData;
export const getIncidentReportData = (state: RootState) =>
    state[NameSpace.incident].reportData;
