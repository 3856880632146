import React, { ReactElement } from "react";
import { MessageFontSize, MessageType } from "../../../const";
import styles from "./index.module.scss";

interface IProps {
  type?: MessageType;
  size?: MessageFontSize;
  children: ReactElement | Array<ReactElement> | string;
}

const Message: React.FC<IProps> = ({
  type = MessageType.default,
  size = MessageFontSize.default,
  children,
}) => {
  return (
    <div className={`${styles.wrapper} ${styles[type]} ${styles[size]}`}>
      {children}
    </div>
  );
};

export default Message;
