import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { APIRoute } from "../../const";
import { api } from "../root-reducer";
import {
    SendIncidentReportArg,
    TLoadEmergencyDataArg,
    TLoadEmergencyDataResponse,
} from "./types";

export const loadEmergencyData = createAsyncThunk(
    "incident/loadEmergencyData",
    async (arg: TLoadEmergencyDataArg, { rejectWithValue }) => {
        try {
            const response = await api.post<TLoadEmergencyDataResponse>(
                APIRoute.EmergencyDataLoad,
                arg
            );
            return response.data;
        } catch (error: any) {
            if (error.response?.data.errorMessage) {
                notification.error({
                    message: error.response.data.errorMessage,
                });
            } else {
                notification.error({ message: error.message });
            }
            return rejectWithValue(error.message);
        }
    }
);

export const sendIncidentReport = createAsyncThunk(
    "incident/sendIncidentReport",
    async (arg: SendIncidentReportArg, { rejectWithValue }) => {
        try {
            const responseJSON = await fetch(
                "https://vercel-functions-teal.vercel.app/api/incident",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                    },
                    body: JSON.stringify(arg),
                }
            );
            const response = await responseJSON.json();
            notification.success({
                message: "Сообщение об инциденте отправлено",
            });
            return response;
        } catch (error: any) {
            if (error.response?.data.errorMessage) {
                notification.error({
                    message: error.response.data.errorMessage,
                });
            } else {
                notification.error({ message: error.message });
            }
            return rejectWithValue(error.message);
        }
    }
);
