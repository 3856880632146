import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getResults } from "../../../store/results/selectors";
import {
  setEditRow,
  setResults,
  setResultTime,
} from "../../../store/results/slice";
import { TResultType } from "../../../store/results/types";
import DeleteButton from "../../Common/DeleteButton/DeleteButton";
import AthleteId from "../AthleteId/AthleteId";
import AthleteName from "../AthleteName/AthleteName";
import AthleteTime from "../AthleteTime/AthleteTime";
import { ColorStatus } from "../Row/Row";
import { deleteCurrentLine, InsertLine } from "../service";
import styles from "./index.module.scss";

interface IProps {
  result: TResultType;
  colorStatus: ColorStatus;
}

const CurrentRow: React.FC<IProps> = ({ result, colorStatus }) => {
  const dispatch = useAppDispatch();
  const results = useAppSelector(getResults);

  const classNames = `${styles.currentRow} ${colorStatus}`;

  const handleResultChange = (newTime: string) => {
    dispatch(setResultTime({ index: result.position - 1, newTime: newTime }));
  };

  const handleClickEdit = () => dispatch(setEditRow(result.position - 1));

  const handleAdd = () =>
    dispatch(setResults(InsertLine(results, result.position + 1)));

  const handleDelete = () => {
    const conf = window.confirm(`Удалить строку?`);
    if (conf) {
      dispatch(setResults(deleteCurrentLine(results, result.position)));
    }
  };

  return (
    <tr className={classNames}>
      <td className={styles.position}>{result.position}</td>
      <td className={styles.result}>
        <AthleteTime time={result.finish_time} setTime={handleResultChange} />
        {/* {result.finish_time} */}
      </td>
      <td className={styles.id}>
        <AthleteId id={result.athlete_id} index={result.position - 1} />
        {/* {result.athlete_id ? `A${result.athlete_id}` : null} */}
      </td>
      <td className={styles.name}>
        {/* {result.full_name} */}
        <div className={styles.nameContent}>
          <AthleteName name={result.full_name} index={result.position - 1} />
          {/* <DeleteButton onClick={handleDelete} /> */}
          <div className={styles.buttons}>
            <EditOutlined onClick={handleClickEdit} />
            <PlusOutlined onClick={handleAdd} style={{ color: "green" }} />
            <DeleteButton
              onClick={handleDelete}
              // onRed={colorStatus === ColorStatus.red}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default CurrentRow;
