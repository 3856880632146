import React from "react";
import { MessageType } from "../../../const";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getError, getErrorMessage } from "../../../store/volunteers/selectors";
import Message from "../../Common/Message/Message";

const Errors = () => {
  const errorMessage = useAppSelector(getErrorMessage);
  const error = useAppSelector(getError);
  return (
    <>
      {errorMessage ? (
        <Message type={MessageType.error}>
          <span>Ошибка: {errorMessage}</span>
        </Message>
      ) : null}
      {error ? (
        <Message type={MessageType.error}>
          <span>Ошибка: {error}</span>
        </Message>
      ) : null}
    </>
  );
};

export default Errors;
