import {
  DOUBLE_RESULTS_ALLOWED_DATES,
  RESULTS_NUMBER_ALLOWED,
  RESULTS_NUMBER_ALLOWED_EXTRA,
} from "../const";

export const isDoubleResultAllowedDate = (date: string) =>
  DOUBLE_RESULTS_ALLOWED_DATES.includes(date);

export const allowedResultsCount = (date: string): number =>
  isDoubleResultAllowedDate(date)
    ? RESULTS_NUMBER_ALLOWED_EXTRA
    : RESULTS_NUMBER_ALLOWED;
