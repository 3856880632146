import React from "react";
import styles from "./index.module.scss";

interface IProps {
  position: number;
}
const Position: React.FC<IProps> = ({ position }) => {
  return <div className={styles.wrapper}>{position}</div>;
};

export default Position;
