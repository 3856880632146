import { notification } from "antd";
import moment from "moment";
import {
    ALLOWED_DAYS_FOR_CHANGE_PROTOCOL,
    DATE_FORMAT,
    EXTRA_EVENTS_DATES,
    MINIMUM_EVENT_DATE,
} from "./const";
import { TAthlete } from "./store/types";
import { TVolunteer } from "./store/volunteers/types";
import { TSearchAthleteOption } from "./types/commonTypes";

/** возвращает ближайшую прошедшую субботу */
export function getSaturday(): Date {
    let today = new Date();
    var dayToday = today.getDay();
    if (dayToday === 6) return today;
    var diff = dayToday - 6;
    if (diff < 0) {
        today.setDate(today.getDate() - diff - 7);
    } else {
        today.setDate(today.getDate() - diff);
    }

    return today;
}

/** возвращает ближайшую дату доп мероприятия */
export function getExtraEventDate(): string {
    const today = new Date();
    const todayFormat = moment(today).format(DATE_FORMAT);
    let extraEventDate = "";

    if (EXTRA_EVENTS_DATES.includes(todayFormat)) {
        extraEventDate = todayFormat;
    } else if (todayFormat > "03.11.24") {
        const dayToday = today.getDay();
        today.setDate(today.getDate() - dayToday);
        extraEventDate = moment(today).format(DATE_FORMAT);
    } else {
      extraEventDate = todayFormat; 
    } 

    return extraEventDate;
}

/** возвращает ближайшую прошедшую субботу строкой */
export function getSaturdayString() {
    return moment(getSaturday()).format(DATE_FORMAT);
}

/** возвращает ближайшую следующую субботу */
export function getNextSaturday(): Date {
    let today = getSaturday();
    today.setDate(today.getDate() + 7);
    return today;
}

export const isExtraDate = (date: moment.Moment | null): boolean => {
    if (!date) return false;
    if (EXTRA_EVENTS_DATES.includes(moment(date).format(DATE_FORMAT)))
        return true;
    return false;
};

export const isSaturday = (date: moment.Moment): boolean =>
    moment(date).format("d") === "6";

const isBeforeMinimumDate = (date: moment.Moment): boolean => {
    return moment(date, DATE_FORMAT).isBefore(MINIMUM_EVENT_DATE, "day");
};

export const isValidDate = (date: moment.Moment | null, isParkAgree: boolean): boolean => {

    if (!date) return false;
    if (isBeforeMinimumDate(date)) {
        notification.warn({
            message: "Вы не можете вводить данные раньше 21 мая 2022 года",
        });
        return false;
    }
    // Если дата в календаре
    
    if (isExtraDate(date) && isParkAgree) return true;
    // Если не суббота
    if (!isSaturday(date)) {
        notification.warn({ message: "Выберите субботу" });
        return false;
    }

    return true;
};

export const isChoosableDate = (date: moment.Moment | null, isParkAgree: boolean): boolean => {
    // То же самое, что isValidDate но без вывода предупреждений. Используется для окраски дат в календаре.
    if (!date || isBeforeMinimumDate(date)) return false;
    if ((isExtraDate(date) && isParkAgree) || isSaturday(date)) return true;
    return false;
};

/** Возвращает true если день позже чем сейчас */
export const isFutureDate = (date: string) => {
    return moment(date, DATE_FORMAT).isAfter(new Date());
};

export const isSameOrFutureDate = (date: string) =>
    moment(date, DATE_FORMAT).isSameOrAfter(moment(new Date()), "day");

export const isToday = (date: string) => {
    return moment(date, DATE_FORMAT).isSame(moment(new Date()), "day");
};

export const isValidCancelDate = (date: string) => {
    let today = new Date();
    today.setDate(today.getDate() - 6); // Можем отменить не раньше 6-ти дней назад. То есть субботу - в следующую пятницу
    return moment(date, DATE_FORMAT).isBefore(today, "day");
};

// добавляет альфацифрам 200 к коду, так что их вес будет больше чем всех спецсимволов
const charWeight = (char: string): number => {
    const wordRegex = /[\wа-яА-Я]/;
    if (wordRegex.test(char[0])) return char.charCodeAt(0) + 200;
    return char.charCodeAt(0);
};

// cравнивает строки по весу символов
export const compareStrings = (
    firstString: string,
    secondString: string
): number => {
    for (
        let i = 0;
        i < Math.min(firstString.length, secondString.length);
        i += 1
    ) {
        const diff = charWeight(firstString[i]) - charWeight(secondString[i]);
        if (diff !== 0) return diff;
    }
    return 0;
};

// Делает строку-список волонтеров из объекта волонтеров для соцсетей
type TNameList = { full_name: string; roles: string };

export function getVolunteersList(volunteers: TVolunteer[]) {
    let nameList: TNameList[] = [];
    volunteers.forEach((volunteer) => {
        // Если на позиции есть волонтер
        if (volunteer.full_name) {
            let index = nameList.findIndex(
                (name) => name.full_name === volunteer.full_name
            );
            // Если мы его уже внесли в список, то добавляем роль.
            if (index >= 0) {
                nameList[index] = {
                    ...nameList[index],
                    roles: `${
                        nameList[index].roles
                    }, ${volunteer.role_name?.toLowerCase()}`,
                };
            } else {
                // Если в списке нет человека, то добавляем человека с ролью
                nameList.push({
                    full_name: volunteer.full_name,
                    roles: volunteer.role_name?.toLowerCase() || "",
                });
            }
        }
    });
    let list = nameList.reduce((total, volunteer) => {
        return `${total}${total.length > 0 ? "\n" : ""}${
            volunteer.full_name
        } - ${volunteer.roles}`;
    }, "");
    return list;
}

// Используем для проверки поля поиска по ID в SearchAthleteByNameOrId
export const isValidId = (data: string) => {
    return /^\d{1,9}$/.test(data);
};

// Используем для проверки поля поиска по имени в SearchAthleteByNameOrId
export const isValidName = (data: string) => {
    return /^[a-zа-я]+\s?[а-яa-z]*$/.test(data.trim().toLowerCase());
};

// Делает список строк вида ID FullName HomePark из списка объектов атлетов
export const getAthleteSearchOptions = (
    search: TAthlete[]
): TSearchAthleteOption[] => {
    return search.map((el) => {
        const label =
            `A${el.id} ${el.full_name}` +
            (el.home_event ? ` (${el.home_event})` : "");
        return {
            value: el.id ? el.id.toString() : "",
            label: label,
        };
    });
};

export const timeStringToSeconds = (timeString: string): number => {
    let seconds = moment.duration(timeString).asSeconds();
    // console.log(seconds);
    return seconds;
};

export const protocolTooOldToChange = (date: string) => {
    return moment(date, DATE_FORMAT)
        .add(ALLOWED_DAYS_FOR_CHANGE_PROTOCOL, "days")
        .isBefore(moment(new Date()), "day");
};

export const today = () => moment(new Date()).format(DATE_FORMAT);
