import { UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useAppDispatch } from "../../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../../customHooks/useAppSelector";
import { getEditRow } from "../../../../store/results/selectors";
import {
  setCurrentRow,
  setEditRow,
  setResultTime,
} from "../../../../store/results/slice";
import { TResultType } from "../../../../store/results/types";
import AthleteId from "../../AthleteId/AthleteId";
import AthleteName from "../../AthleteName/AthleteName";
import AthleteTime from "../../AthleteTime/AthleteTime";
import Position from "../../Position/Position";
import styles from "./index.module.scss";

interface IProps {
  result: TResultType;
}

const ResultRow: React.FC<IProps> = ({ result }) => {
  const dispatch = useAppDispatch();
  const editRow = useAppSelector(getEditRow);

  const handleClose = () => {
    dispatch(setCurrentRow(editRow));
    dispatch(setEditRow(null));
  };

  const handleResultChange = (newTime: string) => {
    dispatch(setResultTime({ index: result.position - 1, newTime: newTime }));
  };

  return (
    <div className={styles.data}>
      <div className={styles.between}>
        <div className={styles.item}>
          Поз: <Position position={result.position} />
        </div>
        <div className={styles.item}>
          Результат:
          <span className={styles.dotted}>
            {" "}
            <AthleteTime
              time={result.finish_time}
              setTime={handleResultChange}
            />
          </span>
        </div>
        <div className={styles.item}>
          ID:{" "}
          <span className={styles.dotted}>
            <AthleteId id={result.athlete_id} index={result.position - 1} />
          </span>
        </div>
        <div className={styles.item}>
          Имя:{" "}
          <span className={styles.dotted}>
            {" "}
            <AthleteName name={result.full_name} index={result.position - 1} />
          </span>
        </div>
      </div>
      <div>
        <Button onClick={handleClose} icon={<UpOutlined />} />
        {/* <UndoButton /> */}
      </div>
    </div>
  );
};

export default ResultRow;
