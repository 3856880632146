import { WarningOutlined } from "@ant-design/icons";
import React from "react";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getDoubleResults } from "../../../store/results/selectors";
import DoubleResultAthlete from "./DoubleResultAthlete/DoubleResultAthlete";
import styles from "./index.module.scss";

const DoubleResultsError = () => {
  const doubleResults = useAppSelector(getDoubleResults);
  return (
    <div className={styles.wrapper}>
      <p>
        <WarningOutlined style={{ fontSize: "24px" }} /> Невозможно сохранить
        протокол по причине дублирования результатов одного или нескольких
        участников в других парках:
      </p>
      <ul>
        {doubleResults.map((athlete) => (
          <DoubleResultAthlete key={athlete.athlete_id} athlete={athlete} />
        ))}
      </ul>
      <p>Для сохранения протокола замените данных участников на неизвестных.</p>
    </div>
  );
};

export default DoubleResultsError;
