import moment from "moment";
import { MIN_VALID_TIME } from "../const";
import { timeStringToSeconds } from "../utils";

/** Добавляет к времени time время addedTime addTime("00:01:50", "00:00:20"); */
export const addTime = (time: string, addedTime: string): string => {
  let newTime = moment.duration(time).add(addedTime).asSeconds();
  return durationSecondsToHMS(newTime);
};

/** Вычитает из времени time время subtractedTime addTime("00:01:50", "00:00:20"); */
export const subtractTime = (time: string, subtractedTime: string): string => {
  let newTime = moment.duration(time).subtract(subtractedTime).asSeconds();
  return durationSecondsToHMS(newTime);
};

const durationSecondsToHMS = (duration: number): string => {
  // возвращает из количества секунд числом время в виде строки "00:01:50"
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration % 3600) / 60);
  let seconds = (duration % 3600) % 60;
  let timeString =
    twoDigitString(hours) +
    ":" +
    twoDigitString(minutes) +
    ":" +
    twoDigitString(seconds);
  return timeString;
};

export const twoDigitString = (value: number): string => {
  // делает из числа 3 или 23 двузначную строку "03" или "23"
  return `${value > 9 ? value : "0" + value.toString()}`;
};

export const mmssPartOfHMS = (time: string) => {
  return time.slice(-5);
};

export const isTimeGTMintime = (time: string) =>
  timeStringToSeconds(time) > timeStringToSeconds(MIN_VALID_TIME);

export const isFirstTimeGTSecondTime = (
  firstTime: string,
  secondTime: string
) => {
  return moment.duration(firstTime).subtract(secondTime).asSeconds() > 0;
};
