import { Link, useLocation } from "react-router-dom";
import { AppRoute } from "../../../const";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getIsResultsActive } from "../../../store/results/selectors";
import styles from "./index.module.scss";

function Menu(): JSX.Element {
  const location = useLocation();
  const isResultsActive = useAppSelector(getIsResultsActive);

  return (
    <nav className={styles.wrapper}>
      <Link
        to={AppRoute.Home}
        className={location.pathname === AppRoute.Home ? styles.active : ""}
      >
        Главная
      </Link>
      <Link
        to={isResultsActive ? AppRoute.Results : AppRoute.ResultsFilesUpload}
        className={
          location.pathname === AppRoute.ResultsFilesUpload ||
          location.pathname === AppRoute.Results
            ? styles.active
            : ""
        }
      >
        Результаты
      </Link>
      <Link
        to={AppRoute.Volunteers}
        className={
          location.pathname === AppRoute.Volunteers ? styles.active : ""
        }
      >
        Волонтёры
      </Link>

      <Link
        to={AppRoute.CancelEvent}
        className={
          location.pathname === AppRoute.CancelEvent ? styles.active : ""
        }
      >
        Отмена
      </Link>
      <Link
        to={AppRoute.Incident}
        className={
          location.pathname === AppRoute.Incident
            ? `${styles.active} ${styles.danger}`
            : location.pathname === AppRoute.Home
            ? ""
            : styles.danger
        }
      >
        Инцидент
      </Link>
    </nav>
  );
}

export default Menu;
