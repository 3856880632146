import React from "react";
import { CANCEL_EVENT_REASON_MAX_LENGTH } from "../../../const";
import styles from "./index.module.scss";

interface IProps {
  reasonLength: number;
}

const CancelReasonCounter: React.FC<IProps> = ({ reasonLength }) => {
  let reasonTooLong = CANCEL_EVENT_REASON_MAX_LENGTH < reasonLength;
  let addStyle = reasonTooLong ? styles.danger : "";
  return (
    <div className={`${styles.counter} ${addStyle}`}>
      {CANCEL_EVENT_REASON_MAX_LENGTH - reasonLength}
    </div>
  );
};

export default CancelReasonCounter;
