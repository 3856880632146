import { Button, notification } from "antd";
import React, { useState } from "react";
import { useAppDispatch } from "../../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../../customHooks/useAppSelector";
import { getEditRow, getResults } from "../../../../store/results/selectors";
import { setResults } from "../../../../store/results/slice";
import { deleteAthletes } from "../../service";

const DeleteAthletes = (e: any) => {
  const dispatch = useAppDispatch();
  const results = useAppSelector(getResults);
  const editRow = useAppSelector(getEditRow);
  const [startLine, setStartLine] = useState(
    editRow === null ? "" : (editRow + 1).toString()
  );
  const [endLine, setEndLine] = useState(
    editRow === null ? "" : (editRow + 1).toString()
  );

  const handleStartLineChange = (e: any) => {
    let input = e.target.value;
    if (input.length === 0) setStartLine("");
    if (/^\d{1,3}$/.test(input)) setStartLine(e.target.value);
  };

  const handleEndLineChange = (e: any) => {
    let input = e.target.value;
    if (input.length === 0) setEndLine("");
    if (/^\d{1,3}$/.test(input)) setEndLine(e.target.value);
  };

  const handleDelete = () => {
    if (startLine.length === 0 || endLine.length === 0) {
      notification.warn({ message: "Введите диапазон" });
      return;
    }
    let start = Number(startLine),
      end = Number(endLine);
    console.log(start, end);
    if (start === 0 || end === 0) {
      notification.warn({ message: "Строка должна быть положительным числом" });
      return;
    }
    if (start > end) {
      notification.warn({ message: "Второе число меньше первого" });
      return;
    }
    const conf = window.confirm(
      `Удалить участников с ${startLine} по ${endLine}?`
    );
    if (conf) dispatch(setResults(deleteAthletes(results, start, end)));
  };

  return (
    <div className="item">
      <span>
        Удалить участников с{" "}
        <input
          className="number-input"
          type="text"
          value={startLine}
          onChange={handleStartLineChange}
        />{" "}
        по{" "}
        <input
          className="number-input"
          type="text"
          value={endLine}
          onChange={handleEndLineChange}
        />
      </span>
      <Button size="small" onClick={handleDelete}>
        Выполнить
      </Button>
    </div>
  );
};

export default DeleteAthletes;
