import React from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { stepHistoryForward } from "../../../store/results/slice";
import { isRedoHistory } from "../../../store/results/selectors";
import { Button } from "antd";
import { RedoOutlined } from "@ant-design/icons";

const RedoButton = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isRedoHistoryFlag = useAppSelector(isRedoHistory);

  const handleRedo = () => {
    dispatch(stepHistoryForward());
  };

  return (
    <Button
      onClick={handleRedo}
      icon={<RedoOutlined />}
      disabled={!isRedoHistoryFlag}
    />
  );
};

export default RedoButton;
