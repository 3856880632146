import React, { useState } from "react";
import { TAthlete } from "../../../../store/types";
import styles from "./index.module.scss";
import SearchAthleteByName from "../../../Common/SearchAthleteByName/SearchAthleteByName";
import { useAppSelector } from "../../../../customHooks/useAppSelector";
import { getVolunteersEventId } from "../../../../store/volunteers/selectors";

interface IProps {
  name: string | undefined;
  onPickAthlete: (athlete: TAthlete) => void;
}

const SearchName: React.FC<IProps> = ({ name, onPickAthlete }) => {
  const eventId = useAppSelector(getVolunteersEventId);
  const [showInput, setShowInput] = useState(false);

  return showInput ? (
    <SearchAthleteByName
      name={name}
      event_id={eventId}
      registered_only={true}
      onClose={() => setShowInput(false)}
      onPickAthlete={onPickAthlete}
    />
  ) : (
    <div
      className={`${styles.name} ${name ? styles.value : ""}`}
      onClick={() => setShowInput(true)}
    >
      {name ? name : "Имя"}
    </div>
  );
};

export default SearchName;
