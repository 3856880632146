import { TVolunteer } from "../store/volunteers/types";

/** Возвращает индекс дублирующейся позиции волонтеров или -1 если нет дубликатов */
export const getNotUniqueVolunteerIndex = (volunteers: TVolunteer[]) => {
    for (let i = 0; i < volunteers.length; i++) {
        if (!volunteers[i].verst_id) continue;
        const indexOfDuplicate = volunteers.findIndex(
            (el, index) =>
                index !== i &&
                el.verst_id === volunteers[i].verst_id &&
                el.role_id === volunteers[i].role_id
        );
        if (indexOfDuplicate !== -1) return indexOfDuplicate;
    }
    return -1;
};

/** Возвращает true если в списке волонтеров есть незарегистрированные */
export const isUnregisteredVolunteer = (volunteers: TVolunteer[]) => {
    let unregisteredIndex = volunteers.findIndex(
        (volunteer) => volunteer.is_record_confirmed === false
    );
    return unregisteredIndex !== -1;
};
