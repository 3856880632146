import { Button } from "antd";
import React from "react";
import { VolunteersStatusId } from "../../../const";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { useSaveVolunteers } from "../../../customHooks/useSaveVolunteers";
import {
    getIsResultsOnSite,
    getVolunteersEventDate,
    getVolunteersLoading,
    getVolunteersStatus,
} from "../../../store/volunteers/selectors";
import { protocolTooOldToChange } from "../../../utils";
import { getIsUserAdmin } from "../../../store/user/selectors";

const VolunteersSaveButtons = () => {
    const volunteersStatus = useAppSelector(getVolunteersStatus);
    const resultsOnSite = useAppSelector(getIsResultsOnSite);
    const loading = useAppSelector(getVolunteersLoading);
    const eventDate = useAppSelector(getVolunteersEventDate);
    const isUserAdmin = useAppSelector(getIsUserAdmin);
    const { saveVolunteers, canLoadOnSite } = useSaveVolunteers();

    const handleSaveVolunteersOnSite = () => {
        saveVolunteers(VolunteersStatusId.Final);
    };

    return (
        <div className="table-buttons">
            {volunteersStatus !== VolunteersStatusId.Final || !canLoadOnSite ? (
                <Button
                    className="submit-button"
                    type="primary"
                    size="large"
                    disabled={
                        loading ||
                        (protocolTooOldToChange(eventDate) && !isUserAdmin)
                    }
                    onClick={() => saveVolunteers(VolunteersStatusId.Draft)}
                >
                    Сохранить
                </Button>
            ) : null}
            {canLoadOnSite && (
                <Button
                    className="submit-button"
                    type="primary"
                    size="large"
                    disabled={
                        loading ||
                        (protocolTooOldToChange(eventDate) && !isUserAdmin) ||
                        !resultsOnSite
                    }
                    onClick={handleSaveVolunteersOnSite}
                >
                    Загрузить на сайт
                </Button>
            )}
        </div>
    );
};

export default VolunteersSaveButtons;
