import { notification } from "antd";
import React, { useState } from "react";
import { MAX_FILE_SIZE, MessageType } from "../../const";
import Message from "../Common/Message/Message";
import FileDisplay from "./FileDisplay/FileDisplay";
import "./filesUpload.scss"; // Пока не стал менять потому что стили глобальные и наследуются внутри в других компонентах

interface IProps {
    files: File[];
    handleFiles: (files: File[]) => void;
}

const FileUpload: React.FC<IProps> = ({ files, handleFiles }) => {
    // const [drag, setDrag] = useState(false);
    //   const [files, setFiles] = useState<File[]>([]);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    const dragStartHandler = (e: React.DragEvent) => {
        e.preventDefault();
        // setDrag(true);
    };

    const dragLeaveHandler = (e: React.DragEvent) => {
        e.preventDefault();
        // setDrag(false);
    };

    const validateFilesMessages = (files: File[]) => {
        let errors: string[] = [];

        files.forEach((file) => {
            const name = file.name;
            let ext = name.split(".").pop()?.toLowerCase();
            console.log(ext);

            if (ext === "xls" || ext === "xlsx") {
                errors.push(
                    `ФАЙЛ: ${file.name} ТИП: .${ext} => Файлы Excel недопустимы`
                );
            } else if (file.size > MAX_FILE_SIZE) {
                errors.push(
                    `ФАЙЛ: ${file.name} РАЗМЕР: ${file.size} => Превышен размер. Возможно, неверный формат файла`
                );
            } else if (ext !== "csv" && file.type !== "txt") {
                errors.push(
                    `ФАЙЛ: ${file.name} ТИП: ${file.type} => Файл не .csv или .txt`
                );
            }
        });
        setErrorMessages(errors);
        return errors;
    };

    const onDropHandler = (e: React.DragEvent) => {
        e.preventDefault();
        // @ts-ignore
        let dropFiles = [...e.dataTransfer.files];
        let newFiles: File[] = [];
        const errors = validateFilesMessages(dropFiles);
        if (errors.length === 0) {
            dropFiles.forEach((file) => {
                if (files.find((f) => f.name === file.name) === undefined) {
                    newFiles.push(file);
                }
            });
            handleFiles([...files, ...newFiles]);
        } else {
            notification.warn({
                message:
                    "Формат файла Excel не поддерживается. Загрузите файл в формате CSV или TXT",
                placement: "topLeft",
            });
        }
    };

    const onChangeFiles = (e: any) => {
        const newFiles = [...e.target.files];
        const originalFiles: File[] = [];
        const errors = validateFilesMessages(newFiles);
        if (errors.length === 0) {
            newFiles.forEach((file) => {
                // проверяем что файл уникальный

                if (files.find((f) => f.name === file.name) === undefined)
                    originalFiles.push(file);
            });
            handleFiles([...files, ...originalFiles]);
        } else {
            notification.warn({
                message:
                    "Формат файла Excel не поддерживается. Загрузите файл в формате CSV или TXT",
                placement: "topLeft",
            });
        }

        e.target.value = null;
    };

    const onRemoveFile = (index: number) => {
        let newFiles = [...files];
        newFiles.splice(index, 1);
        handleFiles(newFiles);
    };

    return (
        <div className="files-upload">
            <h1>Секундомер и сканер</h1>
            {/* Ошибки файлов */}
            {errorMessages.length > 0
                ? errorMessages.map((message) => (
                      <h2 key={message} className="drop-error-message">
                          {message}
                      </h2>
                  ))
                : null}

            <div
                className="drop-area"
                onDragStart={(e) => dragStartHandler(e)}
                onDragLeave={(e) => dragLeaveHandler(e)}
                onDragOver={(e) => dragStartHandler(e)}
                onDrop={(e) => onDropHandler(e)}
            >
                <h2>
                    Выберите один файл секундомера и все файлы сканера или
                    перетащите сюда
                </h2>
                <Message type={MessageType.error}>
                    Не вносите изменения в файлы вручную и не меняйте формат.
                    Любые правки протокола делаются через систему NRMS.
                </Message>

                <input
                    type="file"
                    id="files"
                    onChange={onChangeFiles}
                    multiple
                    // Если написать .csv .txt то на мобилке будет запускать фотокамеру без выбора файлов
                    accept="text/csv, text/plain"
                />
                <label htmlFor="files">Добавить файлы</label>
                <div className="files">
                    {files.map((file, i) => (
                        <div key={i}>
                            <FileDisplay
                                file={file}
                                index={i}
                                onDelete={onRemoveFile}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FileUpload;
