import { notification, Select } from "antd";
import React, { useState } from "react";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getRoles, getVolunteers } from "../../../store/volunteers/selectors";
import {
  setCurrentRow,
  setScrollToIndex,
  setVolunteers,
} from "../../../store/volunteers/slice";
import { TVolunteerPosition } from "../../../store/volunteers/types";
import { roleByRoleId } from "../service";

import styles from "./index.module.scss";

const { Option } = Select;

interface IProps {
  roleId: number | null;
  roleName: string | undefined;
  index: number;
}

const Role: React.FC<IProps> = ({ roleId, index, roleName }) => {
  const [editMode, setEditMode] = useState(false);
  const roles = useAppSelector(getRoles);
  const dispatch = useAppDispatch();
  let role: TVolunteerPosition | undefined;
  const volunteers = useAppSelector(getVolunteers);

  // Для пустых позиций (стартовых) где есть id но нет названия позиции берем роль по айди.
  if (roleId) {
    role = roleByRoleId(roles, roleId);
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Escape") {
      setEditMode(false);
    }
  };

  const handleChange = (id: any) => {
    if (id) {
      let newVolunteers = [...volunteers];
      let role_name = roleByRoleId(roles, id)?.name;

      newVolunteers[index] = {
        ...newVolunteers[index],
        role_id: id,
        role_name,
      };
      let currentVolunteer = newVolunteers[index];

      newVolunteers.sort((a, b) => {
        if (a.role_id && b.role_id) {
          return a.role_id - b.role_id;
        } else return 0;
      });
      dispatch(setVolunteers(newVolunteers));

      // После сортировки надо найти строку с индексом роли и тем же волонтером и установить фокус и проскроллить.
      const newCurrentIndex = newVolunteers.findIndex((volunteer) => {
        return volunteer === currentVolunteer;
      });

      dispatch(setCurrentRow(newCurrentIndex));
      if (newCurrentIndex !== index) {
        dispatch(setScrollToIndex(newCurrentIndex));
      }

      // setId(null);
      setEditMode(false);
    } else {
      notification.warn({ message: "выберите позицию" });
    }
  };

  if (editMode) {
    return (
      <div className={styles.role}>
        <Select
          placeholder="Выберите позицию"
          autoFocus
          style={{ width: "100%" }}
          onChange={handleChange}
          defaultValue={roleId}
          onBlur={() => {
            setEditMode(false);
          }}
          onKeyDown={handleKeyDown}
        >
          {roles.map((role) => (
            <Option key={role.id} value={role.id}>
              {role.name}
            </Option>
          ))}
        </Select>
      </div>
    );
  } else {
    return roleName ? (
      <div className={styles.role} onClick={() => setEditMode(true)}>
        {roleName}
      </div>
    ) : role?.name ? (
      <div className={styles.role} onClick={() => setEditMode(true)}>
        {role.name}
      </div>
    ) : (
      <div>""</div>
    );
  }
};

export default Role;
