import React, { useEffect, useRef, useState } from "react";
import { Button, Select, notification } from "antd";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getRoles, getVolunteers } from "../../../store/volunteers/selectors";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import {
  setScrollToIndex,
  setVolunteers,
} from "../../../store/volunteers/slice";
import { EMPTY_ATHLETE } from "../../../const";
import { TAthlete } from "../../../store/types";
import { BaseSelectRef } from "rc-select";
import SearchId from "./SearchId/SearchId";
import SearchName from "./SearchName/SearchName";

const { Option } = Select;

interface IProps {
  onClose(): void;
}

const AddVolunteerForm: React.FC<IProps> = ({ onClose }) => {
  const [id, setId] = useState<number | null>(null);
  const [volunteer, setVolunteer] = useState<TAthlete>(EMPTY_ATHLETE);
  const roles = useAppSelector(getRoles);
  const dispatch = useAppDispatch();
  const volunteers = useAppSelector(getVolunteers);
  const positionRef = useRef<BaseSelectRef>(null);

  const getRoleNameById = (roleId: number) => {
    const roleById = roles.find((role) => role.id === id);
    if (roleById) {
      return roleById.name;
    } else return "";
  };

  const setFocus = () => {
    // без blur() фокус не подхватывается почему то.
    positionRef.current?.blur();
    positionRef.current?.focus();
  };
  useEffect(setFocus, []);

  const handleSave = (): void => {
    if (id) {
      let newVolunteers = [...volunteers];
      newVolunteers.push({
        verst_id: volunteer.id,
        role_id: id,
        full_name: volunteer.full_name,
        role_name: getRoleNameById(id),
        volunteering_count: volunteer.volunteering_count,
        is_record_confirmed: volunteer.is_record_confirmed,
      });
      newVolunteers.sort((a, b) => {
        if (a.role_id && b.role_id) {
          return a.role_id - b.role_id;
        } else return 0;
      });
      dispatch(setVolunteers(newVolunteers));
      let addedIndex = newVolunteers.findIndex(
        (volunteer) => volunteer.role_id === id
      );
      dispatch(setScrollToIndex(addedIndex)); // Находим индекс волонтера для фокуса на нем
      setId(null);
      onClose();
    } else {
      notification.warn({ message: "выберите позицию" });
    }
  };

  const handleCancel = (): void => {
    setId(null);
    setVolunteer(EMPTY_ATHLETE);
    onClose();
  };

  const handlePositionChange = (value: any) => {
    setId(value);
  };

  const onPickAthlete = (athlete: TAthlete) => {
    if (athlete && athlete.is_record_confirmed === false) {
      notification.warn({
        message: "Попросите волонтера зарегистрироваться!!!",
      });
    }
    setVolunteer(athlete);
  };

  return (
    <div className="table-buttons">
      <Select
        ref={positionRef}
        dropdownMatchSelectWidth={false}
        autoFocus
        placeholder="Выберите позицию"
        style={{ width: 400 }}
        onChange={handlePositionChange}
      >
        {roles.map((role) => (
          <Option key={role.id} value={role.id}>
            {role.name}
          </Option>
        ))}
      </Select>
      <SearchId id={volunteer.id} onPickAthlete={onPickAthlete} />
      {/* <SearchById id={volunteer.id} onPickAthlete={onPickAthlete} /> */}
      <SearchName name={volunteer.full_name} onPickAthlete={onPickAthlete} />
      <Button type="primary" onClick={handleSave}>
        Добавить
      </Button>
      <Button onClick={handleCancel}>Отменить</Button>
    </div>
  );
};

export default AddVolunteerForm;
