import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { TAthlete } from "../types";
import { searchByIdPart, searchByNamePart } from "./thunk";
import { SearchReducer } from "./types";

const initialState: SearchReducer = {
  loading: false,
  error: null,
  athleteSearch: [],
};
export const searchSlice = createSlice({
  name: "SearchSlice",
  initialState,
  reducers: {
    clearSearch: () => initialState,
  },
  extraReducers: {
    [searchByIdPart.pending.type]: (state: SearchReducer) => {
      state.loading = true;
      state.error = null;
    },
    [searchByIdPart.fulfilled.type]: (
      state: SearchReducer,
      { payload }: PayloadAction<TAthlete[]>
    ) => {
      state.athleteSearch = payload;
      state.loading = false;
    },
    [searchByIdPart.rejected.type]: (
      state: SearchReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.athleteSearch = [];
      state.loading = false;
      state.error = payload;
    },
    [searchByNamePart.pending.type]: (state: SearchReducer) => {
      state.loading = true;
      state.error = null;
    },
    [searchByNamePart.fulfilled.type]: (
      state: SearchReducer,
      { payload }: PayloadAction<TAthlete[]>
    ) => {
      state.athleteSearch = payload;
      state.loading = false;
    },
    [searchByNamePart.rejected.type]: (
      state: SearchReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { clearSearch } = searchSlice.actions;
export default searchSlice.reducer;
