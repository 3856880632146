import { EventStatus, VolunteersStatusId } from "../../const";

export type VolunteersReducer = {
    loading: boolean;
    error: string | null;
    errorCode: number;
    errorMessage: string;
    event_id: number | null;
    event_date: string;
    roles: TVolunteerPosition[];
    volunteers: TVolunteer[];
    currentRow: number | null;
    saveMessage: string;
    scrollToIndex: number | null;
    upload_status_id: VolunteersStatusId | null;
    eventStatusId: EventStatus | null;
    // initialRender: boolean;
    needLoadVolunteers: boolean;
    isResultsOnSite: boolean; // Показывает есть ли результаты на сайте за данное число
    totalEventCount: number | null;
    isInitialVolunteersList: boolean;
    showSaveResultsModal: boolean;
    initialPositions: number[];
    volunteerSummary: TRoleCounter[];
    showSummary: boolean;
};

export type TVolunteerPosition = {
    id: number;
    name: string;
};

export type TVolunteer = {
    verst_id: number | null;
    role_id: number;
    full_name: string;
    role_name?: string;
    volunteering_count?: number;
    home_event?: string;
    // unregisteredWarn?: boolean;
    is_record_confirmed?: boolean;
    is_adult?: boolean;
};

export type TRolesResponse = {
    errorCode: number;
    errorMessage: string;
    result: {
        roles: {
            id: number;
            name: string;
            is_default: boolean;
        }[];
    };
};

export type TLoadVolunteersResponse = {
    errorCode: number;
    errorMessage: string;
    result: LoadVolunteersPayload;
};

export type TSaveVolunteersResponse = {
    errorCode: number;
    errorMessage: string;
    result: any;
};

export type TResponse = {
    errorCode: number;
    errorMessage: string;
    result: any;
};

export type TSaveDataArg = {
    data: TSaveData;
    warning: boolean;
};
export type TSaveData = {
    event_id: number;
    date: string;
    upload_status_id: number;
    volunteers: { verst_id: number; role_id: number }[];
};

export type TLoadData = {
    event_id: number;
    event_date: string;
};

export type TSetVolunteerPayload = {
    index: number;
    id: number | null;
    full_name: string;
    volunteering_count?: number;
    is_record_confirmed?: boolean;
    is_adult?: boolean;
};

export type LoadVolunteersPayload = {
    status_id?: EventStatus | null;
    upload_status_id: VolunteersStatusId | null;
    volunteer_list: TVolunteer[];
};

export type TVolunteerSummaryResponse = {
    errorCode: number;
    errorMessage: string;
    result: { volunteer_roles: TRoleCounter[] };
};

export type TRoleCounter = { role_id: number; role: string; count: number };

// status_id 1 - предварительный ростер, 2 - окончательный

export const INITIAL_POSITIONS = [1, 2, 2, 11, 11, 13, 19, 8, 7, 4, 5];
export const RoasterLocalstorageKey = "Volunteers_initial_positions";
