import { CopyOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import React from "react";
import { TEmergencyData } from "../../../store/incident/types";
import styles from "./index.module.scss";

interface IProps {
  data: TEmergencyData;
}

const IceData: React.FC<IProps> = ({ data }) => {
  // const handleCopyName = () => {
  //   // работает только на https:
  //   navigator.clipboard.writeText(contact.name);
  //   notification.info({
  //     message: "Список волонтеров скопирован в буфер обмена",
  //   });
  // };
  const handleCopyPhone = () => {
    // работает только на https:
    navigator.clipboard.writeText(data.ice_contact);
    notification.info({
      message: "Телефон скопирован в буфер обмена",
    });
  };

  return (
    <div className={styles.wrapper}>
      <section>
        {" "}
        <h2>Участник:</h2>
        <div className={styles.name}>
          {data.last_name} {data.first_name}
        </div>
        <div className={styles.name}>Год рождения: {data.birth_year}</div>
      </section>
      <section>
        <h2>Контактное лицо:</h2>
        <div className={styles.name}>
          {/* <span>Имя:</span> */}
          {data.ice_name.length > 0 ? (
            <span>
              {data.ice_name}
              {/* <Button
            type="text"
            icon={<CopyOutlined style={{ fontSize: "24px", color: "#444" }} />}
            onClick={handleCopyName}
          /> */}
            </span>
          ) : null}
        </div>
        <div className={styles.phone}>
          {/* <span>Телефон:</span> */}
          {data.ice_contact.length > 0 ? (
            <span>
              {data.ice_contact}
              <Button
                type="text"
                icon={
                  <CopyOutlined style={{ fontSize: "24px", color: "#444" }} />
                }
                onClick={handleCopyPhone}
              />
            </span>
          ) : (
            <span>Контакт не указан</span>
          )}
        </div>
      </section>

      {data.ice_contact.length > 0 ? (
        <a className={styles.call} href={`tel:${data.ice_contact}`}>
          <PhoneOutlined /> <span>Позвонить</span>
        </a>
      ) : null}
    </div>
  );
};

export default IceData;
