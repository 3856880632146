import { NameSpace } from "../root-reducer";
import { State } from "../../types/state";
import { ADMIN_IDS, AuthorizationStatus } from "../../const";
import decode from "jwt-decode";
import { createSelector } from "@reduxjs/toolkit";

type DecodedToken = { username: string };

export const getAuthorizationStatus = (state: State): AuthorizationStatus =>
    state[NameSpace.user].authorizationStatus;

export const getUserId = (state: State) => {
    const user = state[NameSpace.user];
    if (user.authorizationStatus === AuthorizationStatus.Auth && user.user) {
        const decodeToken: DecodedToken = decode(user.user.token);
        return +decodeToken.username;
    }
};

export const getIsUserAdmin = createSelector([getUserId], (id): boolean => {
    if (id) {
        return ADMIN_IDS.includes(id);
    }
    return false;
});

export const getUserName = (state: State) =>
    state[NameSpace.user].user?.full_name;

/* Авторизация для дева */
/* export const getAuthorizationStatus = (state: State): AuthorizationStatus =>
  AuthorizationStatus.Auth; */

export const getAuthLoading = (state: State): boolean =>
    state[NameSpace.user].loading;
