import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VolunteersStatusId, VOLUNTEERS_CLEAR_MESSAGE } from "../../const";

import {
    clearVolunteersAction,
    getVolunteerSummary,
    initVolunteersDateAction,
    loadRolesAction,
    loadVolunteersAction,
    saveVolunteersAction,
} from "./thunk";
import {
    INITIAL_POSITIONS,
    LoadVolunteersPayload,
    TLoadVolunteersResponse,
    TSetVolunteerPayload,
    TVolunteer,
    TVolunteerPosition,
    TVolunteerSummaryResponse,
    VolunteersReducer,
} from "./types";

const initialState: VolunteersReducer = {
    loading: false,
    error: null,
    errorCode: 0,
    errorMessage: "",
    event_id: null,
    event_date: "",
    roles: [],
    volunteers: [],
    currentRow: null,
    saveMessage: "",
    scrollToIndex: null,
    upload_status_id: null,
    eventStatusId: null,
    needLoadVolunteers: true,
    isResultsOnSite: false,
    totalEventCount: null,
    isInitialVolunteersList: true,
    showSaveResultsModal: false,
    initialPositions: INITIAL_POSITIONS,
    volunteerSummary: [],
    showSummary: false,
};

export const volunteersSlice = createSlice({
    name: "VolunteersSlice",
    initialState,
    reducers: {
        setVolunteersEventId: (
            state: VolunteersReducer,
            action: PayloadAction<number | null>
        ) => {
            state.event_id = action.payload;
            state.needLoadVolunteers = true;
        },
        setVolunteersEventDate: (
            state: VolunteersReducer,
            action: PayloadAction<string>
        ) => {
            state.event_date = action.payload;
            state.needLoadVolunteers = true;
        },
        setCurrentRow: (
            state: VolunteersReducer,
            action: PayloadAction<number | null>
        ) => {
            state.currentRow = action.payload;
        },
        setVolunteers: (
            state: VolunteersReducer,
            action: PayloadAction<TVolunteer[]>
        ) => {
            state.volunteers = action.payload;
            state.isInitialVolunteersList = false;
        },
        setTotalEventCount: (
            state: VolunteersReducer,
            action: PayloadAction<number | null>
        ) => {
            state.totalEventCount = action.payload;
        },
        addVolunteer: (
            state: VolunteersReducer,
            action: PayloadAction<TVolunteer>
        ) => {
            state.volunteers.push({
                verst_id: action.payload.verst_id,
                role_id: action.payload.role_id,
                full_name: "",
                role_name: "",
            });
            state.isInitialVolunteersList = false;
        },
        deleteVolunteer: (
            state: VolunteersReducer,
            action: PayloadAction<number>
        ) => {
            state.volunteers.splice(action.payload, 1);
            state.isInitialVolunteersList = false;
        },
        clearVolunteer: (
            state: VolunteersReducer,
            { payload }: PayloadAction<number>
        ) => {
            state.volunteers[payload].verst_id = null;
            state.volunteers[payload].full_name = "";
            state.volunteers[payload].volunteering_count = 0;
            state.volunteers[payload].is_adult = undefined;
            state.volunteers[payload].is_record_confirmed = undefined;
        },
        setVolunteerId: (
            state: VolunteersReducer,
            action: PayloadAction<{ index: number; id: number | null }>
        ) => {
            state.volunteers[action.payload.index] = {
                ...state.volunteers[action.payload.index],
                verst_id: action.payload.id,
            };
            state.isInitialVolunteersList = false;
        },
        setVolunteerMessage: (
            state: VolunteersReducer,
            action: PayloadAction<string>
        ) => {
            state.saveMessage = action.payload;
        },
        setVolunteerName: (
            state: VolunteersReducer,
            action: PayloadAction<{ index: number; name: string }>
        ) => {
            state.volunteers[action.payload.index] = {
                ...state.volunteers[action.payload.index],
                full_name: action.payload.name,
            };
            state.isInitialVolunteersList = false;
        },
        setVolunteer: (
            state: VolunteersReducer,
            action: PayloadAction<TSetVolunteerPayload>
        ) => {
            state.volunteers[action.payload.index].verst_id = action.payload.id;
            state.volunteers[action.payload.index].full_name =
                action.payload.full_name;
            state.volunteers[action.payload.index].volunteering_count =
                action.payload.volunteering_count;
            state.volunteers[action.payload.index].is_adult =
                action.payload.is_adult;
            if (action.payload.is_record_confirmed !== undefined) {
                state.volunteers[action.payload.index].is_record_confirmed =
                    action.payload.is_record_confirmed;
            }
            state.isInitialVolunteersList = false;
        },
        setScrollToIndex: (
            state: VolunteersReducer,
            { payload }: PayloadAction<number>
        ) => {
            state.scrollToIndex = payload;
        },
        resetScrollToIndex: (state: VolunteersReducer) => {
            state.scrollToIndex = null;
        },
        setVolunteersStatus: (
            state: VolunteersReducer,
            { payload }: PayloadAction<VolunteersStatusId>
        ) => {
            state.upload_status_id = payload;
        },
        setErrorMessage: (
            state: VolunteersReducer,
            { payload }: PayloadAction<TLoadVolunteersResponse>
        ) => {
            state.errorCode = payload.errorCode;
            state.errorMessage = payload.errorMessage;
        },
        clearError: (state: VolunteersReducer) => {
            state.error = null;
            state.errorCode = 0;
            state.errorMessage = "";
        },
        resetErrorMessage: (state: VolunteersReducer) => {
            state.errorCode = 0;
            state.errorMessage = "";
            state.error = null;
        },
        setIsResultsOnSite: (
            state: VolunteersReducer,
            { payload }: PayloadAction<boolean>
        ) => {
            state.isResultsOnSite = payload;
        },
        setShowSaveResultsModal: (
            state: VolunteersReducer,
            { payload }: PayloadAction<boolean>
        ) => {
            state.showSaveResultsModal = payload;
        },
        setIsInitialVolunteersList: (
            state: VolunteersReducer,
            { payload }: PayloadAction<boolean>
        ) => {
            state.isInitialVolunteersList = payload;
        },
        setInitialPositions: (
            state: VolunteersReducer,
            { payload }: PayloadAction<number[]>
        ) => {
            state.initialPositions = payload;
        },
        clearVolunteerSummary: (state: VolunteersReducer) => {
            state.volunteerSummary = [];
            state.showSummary = false;
        },
    },
    extraReducers: {
        [loadRolesAction.pending.type]: (state: VolunteersReducer) => {
            state.loading = true;
            state.error = null;
        },
        [loadRolesAction.fulfilled.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<TVolunteerPosition[]>
        ) => {
            state.roles = payload;
            state.loading = false;
        },
        [loadRolesAction.rejected.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<string>
        ) => {
            state.roles = [];
            state.loading = false;
            state.error = payload;
        },
        [saveVolunteersAction.pending.type]: (state: VolunteersReducer) => {
            state.loading = true;
            state.error = null;
        },
        [saveVolunteersAction.fulfilled.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<any>
        ) => {
            // state.roles = payload;
            state.loading = false;
        },
        [saveVolunteersAction.rejected.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<string>
        ) => {
            // state.roles = [];
            state.loading = false;
            state.error = payload;
        },
        [loadVolunteersAction.pending.type]: (state: VolunteersReducer) => {
            state.loading = true;
            state.error = null;
        },
        [loadVolunteersAction.fulfilled.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<LoadVolunteersPayload>
        ) => {
            if (payload.volunteer_list) {
                state.volunteers = payload.volunteer_list;
            }
            if (payload.upload_status_id) {
                state.upload_status_id = payload.upload_status_id;
            }
            if (payload.status_id) {
                state.eventStatusId = payload.status_id;
            }
            state.loading = false;
            state.needLoadVolunteers = false;
            state.isInitialVolunteersList = true;
        },
        [loadVolunteersAction.rejected.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = payload;
            state.volunteers = [];
            state.upload_status_id = null;
            state.eventStatusId = null;
        },
        [initVolunteersDateAction.pending.type]: (state: VolunteersReducer) => {
            state.loading = true;
            state.error = null;
        },
        [initVolunteersDateAction.fulfilled.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<string>
        ) => {
            state.event_date = payload;
            state.loading = false;
        },
        [initVolunteersDateAction.rejected.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = payload;
        },
        [clearVolunteersAction.pending.type]: (state: VolunteersReducer) => {
            state.loading = true;
            state.error = null;
        },
        [clearVolunteersAction.fulfilled.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<any>
        ) => {
            state.loading = false;
            state.saveMessage = VOLUNTEERS_CLEAR_MESSAGE;
        },
        [clearVolunteersAction.rejected.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = payload;
        },
        [getVolunteerSummary.pending.type]: (state: VolunteersReducer) => {
            state.loading = true;
            state.error = null;
        },
        [getVolunteerSummary.fulfilled.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<TVolunteerSummaryResponse>
        ) => {
            state.loading = false;
            state.volunteerSummary = payload.result.volunteer_roles;
            state.showSummary = true;
        },
        [getVolunteerSummary.rejected.type]: (
            state: VolunteersReducer,
            { payload }: PayloadAction<string>
        ) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const {
    setVolunteersEventId,
    setVolunteersEventDate,
    setCurrentRow,
    setVolunteers,
    addVolunteer,
    deleteVolunteer,
    clearVolunteer,
    setVolunteerId,
    setVolunteerName,
    setVolunteer,
    setVolunteerMessage,
    setScrollToIndex,
    resetScrollToIndex,
    setVolunteersStatus,
    setErrorMessage,
    resetErrorMessage,
    setIsResultsOnSite,
    setTotalEventCount,
    setIsInitialVolunteersList,
    clearError,
    setShowSaveResultsModal,
    setInitialPositions,
    clearVolunteerSummary,
} = volunteersSlice.actions;
export default volunteersSlice.reducer;
