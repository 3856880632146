import { State } from "../../types/state";
import { NameSpace } from "../root-reducer";
import { TExtraEvent, TAdditionalEvents } from "./types";

export const getIsLoading = (state: State) =>
  state[NameSpace.extraEvents].loading;
export const getExtraEventsEventId = (state: State) =>
  state[NameSpace.extraEvents].event_id;
export const getExtraEvents = (state: State): TExtraEvent[] =>
  state[NameSpace.extraEvents].eventsToChoose;
export const getAdditionalEvents = (state: State): TAdditionalEvents =>
  state[NameSpace.extraEvents].additional_events;
export const getCalendar = (state: State) =>
  state[NameSpace.extraEvents].calendar;
  
