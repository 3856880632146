import { Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import SearchAthleteByNameOrId from "../../components/Common/SearchAthleteByNameOrId/SearchAthleteByNameOrId";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { useAppSelector } from "../../customHooks/useAppSelector";
import {
    getEmergecyData,
    getIncidentAthlete,
    getIncidentEventId,
} from "../../store/incident/selectors";
import { setIncidentAthlete } from "../../store/incident/slice";
import { loadEmergencyData } from "../../store/incident/thunk";
import { TAthlete } from "../../store/types";
import IceData from "./IceData/IceData";
import styles from "./index.module.scss";
import IncidentRegister from "../../components/incident/incident-register/IncidentRegister";

const Incident = () => {
    const eventId = useAppSelector(getIncidentEventId);
    const athlete = useAppSelector(getIncidentAthlete);
    const emergencyData = useAppSelector(getEmergecyData);
    const dispatch = useAppDispatch();
    const setAthlete = (athlete: TAthlete) => {
        dispatch(setIncidentAthlete(athlete));
    };

    const [confirmVisible, setConfirmVisible] = useState(false);
    const [showIceContact, setShowIceContact] = useState(false);

    useEffect(() => {
        document.title = "NRMS - регистрация инцидента";
    }, []);

    useEffect(() => {
        setShowIceContact(false);
    }, [athlete]);

    useEffect(() => {
        if (emergencyData.first_name) {
            setShowIceContact(true);
        }
    }, [emergencyData]);

    const hideConfirm = () => {
        setConfirmVisible(false);
    };
    const showConfirm = () => {
        setConfirmVisible(true);
    };

    const handleGetEmergencyData = () => {
        setConfirmVisible(false);
        if (!athlete?.id) {
            notification.error({ message: "Не выбран участник!" });
        } else {
            dispatch(loadEmergencyData({ verst_id: athlete.id }));
        }
    };

    return (
        <div className={styles.wrapper}>
            <IncidentRegister />

            <div className={styles.iceContact}>
                <h2>Получить Ice-контакт</h2>
                {/* <Message type={MessageType.error} size={MessageFontSize.large}>
        Ваш запрос контактного лица будет передан в штаб-квартиру
      </Message> */}
                <div className={styles.inputs}>
                    {/* <EventName eventId={eventId} setEventId={setIncidentEventId} /> */}

                    <div className={styles.search}>
                        <SearchAthleteByNameOrId
                            athlete={athlete}
                            event_id={eventId}
                            registeredOnly={true}
                            onPickAthlete={setAthlete}
                        />
                    </div>
                </div>
                <button className={styles.getContact} onClick={showConfirm}>
                    Получить контакт
                </button>
                <Modal
                    wrapClassName={styles.emergencyModal}
                    visible={confirmVisible}
                    onOk={handleGetEmergencyData}
                    onCancel={hideConfirm}
                    okText="Да"
                    cancelText="Нет"
                    cancelButtonProps={{ size: "large" }}
                    okButtonProps={{ size: "large" }}
                    destroyOnClose={true}
                    // centered
                >
                    <h2>
                        Показать контакт? Ваш запрос контактного лица будет
                        передан в штаб-квартиру
                    </h2>
                </Modal>

                {showIceContact && emergencyData ? (
                    <IceData data={emergencyData} />
                ) : null}
            </div>
        </div>
    );
};

export default Incident;
