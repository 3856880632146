import { Button, notification, Select } from "antd";
import React from "react";
import { EXTRA_EVENT_ALOWED_TIMES } from "../../../const";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import {
  getCalendar,
  getIsLoading,
  getExtraEvents,
} from "../../../store/extraEvents/selector";
import { setExtraEvent } from "../../../store/extraEvents/slice";
import { Checkbox } from "antd";
import {
  AddExtraEventAction,
  DeleteExtraEventAction,
  UpdateExtraEventAction,
} from "../../../store/extraEvents/thunk";
import { TExtraEvent } from "../../../store/extraEvents/types";
import { isEventActive, timeStringFromEvent } from "../../../utils/extraEvents";
import styles from "./index.module.scss";

interface IProps {
  event: TExtraEvent;
  index: number;
}

const ExtraEvent: React.FC<IProps> = ({ event, index }) => {
  const calendar = useAppSelector(getCalendar);
  const loading = useAppSelector(getIsLoading);
  const dispatch = useAppDispatch();
  const timeString = timeStringFromEvent(event);
  const extraEvents = useAppSelector(getExtraEvents);

  const toggleToAddEvent = () => {
    dispatch(
      setExtraEvent({ event: { ...event, isAdded: !event.isAdded }, index })
    );
  };

  const togglePromiseEvent = () => {
    dispatch(
      setExtraEvent({
        event: {
          ...event,
          extraEventTypeIsAdded: !event.extraEventTypeIsAdded,
          extra_event_type_id: event.extraEventTypeIsAdded ? null : 1,
        },
        index,
      })
    );
  };

  const isEventInCalendar = (
    eventItem: TExtraEvent,
    eventsCalendar: TExtraEvent[] | null
  ) => {
    return eventsCalendar?.some((e) => e.event_date === eventItem.event_date);
  };

  const isDisabled = !isEventActive(event);

  const handleSaveEvent = () => {
    if (!isEventInCalendar(event, calendar)) {
      if (event.isAdded) {
        dispatch(AddExtraEventAction(event));
      } else {
        notification.warn({ message: "Мероприятие не выбрано" });
      }
    } else if (!event.isAdded) {
      dispatch(DeleteExtraEventAction(event));
    } else {
      dispatch(UpdateExtraEventAction(event));
    }
  };

  const handleTimeChange = (time: string) => {
    let [hours, minutes] = time.split(":");
    dispatch(
      setExtraEvent({
        event: {
          ...event,
          hour: Number(hours),
          minute: Number(minutes),
        },
        index,
      })
    );
  };

  return (
    <div className={styles.inputs}>
      <Checkbox
        className={styles.checkboxExtraEvent}
        checked={extraEvents[index].isAdded}
        onChange={toggleToAddEvent}
        disabled={isDisabled}
      >
        {event.event_date}
      </Checkbox>
      {event.event_date !== "29.12.2024" && (
        <>
          в
          <Select
            className={styles.timeSelect}
            placeholder="Выберите время"
            value={timeString}
            onChange={handleTimeChange}
            options={EXTRA_EVENT_ALOWED_TIMES.map((time) => {
              return { value: time, label: time };
            })}
            disabled={isDisabled}
          />
        </>
      )}
      {event.event_date === "01.01.2025" && (
        <Checkbox
          className={styles.checkboxPromiseEvent}
          checked={extraEvents[index].extraEventTypeIsAdded}
          onChange={togglePromiseEvent}
          disabled={isDisabled}
        >
          <span className={styles.checkboxLabel}>Забег обещаний</span>
        </Checkbox>
      )}
      {!isDisabled && (
        <Button
          className="submit-button"
          type="primary"
          disabled={isDisabled || loading || !event.event_id}
          onClick={handleSaveEvent}
        >
          Отправить
        </Button>
      )}
    </div>
  );
};

export default ExtraEvent;
