import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIRoute, WarningMessage } from "../../const";
import { api } from "../root-reducer";
import { notification } from "antd";
import {
  TSearchByIdPartResponse,
  TSearchByNamePartArg,
  TSearchByNamePartResponse,
} from "./types";

export const searchByIdPart = createAsyncThunk(
  "search/searchById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.post<TSearchByIdPartResponse>(
        APIRoute.AthleteByIdPart,
        { id }
      );
      return response.data.result.data;
    } catch (error: any) {
      notification.error({ message: WarningMessage.VolunteerLoadFail });
      return rejectWithValue(error.message);
    }
  }
);

export const searchByNamePart = createAsyncThunk(
  "serch/serchByName",
  async (arg: TSearchByNamePartArg, { rejectWithValue }) => {
    try {
      const response = await api.post<TSearchByNamePartResponse>(
        APIRoute.AthleteByNamePart,
        arg
      );
      return response.data.result.data;
    } catch (error: any) {
      console.log(error.response);
      console.log("STATUS: ", error.response.status);
      if (error.message) console.log("ERROR: ", error.message);
      if (error.response.data.errorMessage)
        console.log("ERRORMESSAGE: ", error.response.data.errorMessage);
      if (error.response.status === 0) {
        notification.error({ message: "Ошибка сети.Проверьте интернет" });
      } else if (error.response.status === 404) {
        notification.error({ message: "Адрес не найден, 404" });
      } else {
        notification.error({ message: WarningMessage.VolunteerLoadFail });
      }
      return rejectWithValue(error.message);
    }
  }
);
