import React from "react";
import styles from "./index.module.scss";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface IProps {
    isOpen: boolean;
    setIsOpen: (x: boolean) => void;
}

const IncidentRegisterHeader: React.FC<IProps> = ({ isOpen, setIsOpen }) => {
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.wrapper}>
          <button className={styles.buttonReg} onClick={toggleIsOpen}>
              Зарегистрировать инцидент
          </button>
          <Button
          className={styles.buttonarrow}
            type="text"
            icon={
              isOpen ? (
                  <UpCircleOutlined
                      style={{ fontSize: "24px", color: "#ffffff", marginTop: "5px" }}
                  />
              ) : (
                  <DownCircleOutlined
                      style={{ fontSize: "24px", color: "#ffffff", marginTop: "5px" }}
                  />
              )
            }
            onClick={toggleIsOpen}
          />
        </div>
    );
};

export default IncidentRegisterHeader;
