import { Popconfirm } from "antd";
import React from "react";
import DeleteButton from "../../Common/DeleteButton/DeleteButton";

interface IProps {
  deleteWithRole?: boolean;
  onDelete: () => void;
}

const DeleteVolunteer: React.FC<IProps> = ({
  deleteWithRole = false,
  onDelete,
}) => {
  return (
    <Popconfirm
      title={deleteWithRole ? "Удалить позицию?" : "Удалить волонтера?"}
      okText="Да"
      cancelText="Нет"
      placement="leftTop"
      onConfirm={onDelete}
      okButtonProps={{ size: "large" }}
      cancelButtonProps={{ size: "large" }}
      // onCancel={cancel}
    >
      <DeleteButton /* onClick={handleDelete} */ />
    </Popconfirm>
  );
};

export default DeleteVolunteer;
