import { TResultsType, TResultType } from "../../store/results/types";
import {
  addTime,
  isFirstTimeGTSecondTime,
  subtractTime,
} from "../../utils/addTime";

export const deleteCurrentAthletID = (
  results: TResultsType,
  position: number
) => {
  // Сдвигаем таблицу вверх удалив id атлета с позицией position

  let index = position - 1;
  let newResults = [...results];
  for (let i = index; i < results.length; i++) {
    if (i < results.length - 1) {
      newResults[i] = {
        ...results[i],
        athlete_id: results[i + 1].athlete_id,
        full_name: results[i + 1].full_name,
      };
    } else if (i === results.length - 1) {
      // Если в последней строке нет результата убираем. Иначе ставим в ID нули.
      if (results[i].finish_time === "") {
        newResults.pop();
      } else {
        newResults[i] = {
          ...results[i],
          athlete_id: null,
          full_name: "",
        };
      }
    }
  }
  return newResults;
};

export const InsertAthletID = (results: TResultsType, position: number) => {
  // Вставляем id со сдвигом таблицы вниз

  let index = position - 1;
  let newResults = [...results];
  newResults[index] = { ...results[index], athlete_id: null, full_name: "" };
  for (let i = index + 1; i <= results.length; i++) {
    if (i < results.length) {
      newResults[i] = {
        ...results[i],
        athlete_id: results[i - 1].athlete_id,
        full_name: results[i - 1].full_name,
      };
    } else if (results[i - 1].athlete_id === null) {
      continue;
    } else {
      newResults[i] = {
        position: i + 1,
        finish_time: "",
        athlete_id: results[i - 1].athlete_id,
        full_name: results[i - 1].full_name,
      };
    }
  }
  return newResults;
};

export const InsertAthletTime = (results: TResultsType, position: number) => {
  // Вставляем id со сдвигом таблицы вниз

  let index = position - 1;
  let newResults = [...results];
  newResults[index] = { ...results[index], finish_time: "" };
  for (let i = index + 1; i <= results.length; i++) {
    if (i < results.length) {
      newResults[i] = {
        ...results[i],
        finish_time: results[i - 1].finish_time,
      };
    } else if (results[i - 1].finish_time === "") {
      continue;
    } else {
      newResults[i] = {
        position: i + 1,
        finish_time: results[i - 1].finish_time,
        athlete_id: null,
        full_name: "",
      };
    }
  }
  return newResults;
};

export const InsertLine = (results: TResultsType, position: number) => {
  // Добавляем строку

  let index = position - 1;
  let newResults = [...results];
  newResults[index] = {
    ...results[index],
    finish_time: "",
    athlete_id: null,
    full_name: "",
  };
  if (index === results.length) {
    newResults[index] = {
      position: index + 1,
      finish_time: "",
      athlete_id: null,
      full_name: "",
    };
    return newResults;
  }
  for (let i = index + 1; i <= results.length; i++) {
    if (i < results.length) {
      newResults[i] = {
        ...results[i],
        finish_time: results[i - 1].finish_time,
        athlete_id: results[i - 1].athlete_id,
        full_name: results[i - 1].full_name,
      };
    }
    newResults[i] = {
      position: i + 1,
      finish_time: results[i - 1].finish_time,
      athlete_id: results[i - 1].athlete_id,
      full_name: results[i - 1].full_name,
    };
  }
  return newResults;
};

export const deleteCurrentAthleteTime = (
  results: TResultsType,
  position: number
) => {
  // Сдвигаем таблицу вверх удалив время атлета с позицией position
  let index = position - 1;
  let newResults = [...results];
  for (let i = index; i < results.length; i++) {
    if (i < results.length - 1) {
      newResults[i] = {
        ...results[i],
        finish_time: results[i + 1].finish_time,
      };
    } else if (i === results.length - 1) {
      // Если в последней строке нет результата убираем. Иначе ставим в ID нули.
      if (results[i].athlete_id === null) {
        newResults.pop();
      } else {
        newResults[i] = {
          ...results[i],
          finish_time: "",
        };
      }
    }
  }
  return newResults;
};

export const deleteCurrentLine = (results: TResultsType, position: number) => {
  // Сдвигаем таблицу вверх удалив id атлета с позицией position

  let index = position - 1;
  let newResults = [...results];
  for (let i = index; i < results.length; i++) {
    if (i < results.length - 1) {
      newResults[i] = {
        ...results[i],
        finish_time: results[i + 1].finish_time,
        athlete_id: results[i + 1].athlete_id,
        full_name: results[i + 1].full_name,
      };
    } else if (i === results.length - 1) {
      // Если в последней строке нет результата убираем. Иначе ставим в ID нули.
      newResults.pop();
    }
  }

  return newResults;
};

export const deleteLines = (
  results: TResultsType,
  startposition: number,
  endposition: number
) => {
  // Сдвигаем таблицу вверх удалив строки с позицией position от startposition до endposition

  let index = startposition - 1;
  if (startposition > results.length) return results;
  let quontity = endposition - startposition + 1;
  let newResults = [...results];
  for (let i = index; i < results.length; i++) {
    if (i < results.length - quontity) {
      newResults[i] = {
        ...results[i],
        finish_time: results[i + quontity].finish_time,
        athlete_id: results[i + quontity].athlete_id,
        full_name: results[i + quontity].full_name,
      };
    } else if (i <= results.length - 1) {
      // Если в последней строке нет результата убираем. Иначе ставим в ID нули.
      newResults.pop();
    }
  }

  return newResults;
};

export const deleteAthletes = (
  results: TResultsType,
  startposition: number,
  endposition: number
) => {
  // Сдвигаем людей вверх удалив строки с позицией position от startposition до endposition

  let index = startposition - 1;
  if (startposition > results.length) return results;
  let quontity = endposition - startposition + 1;
  let newResults = [...results];
  for (let i = index; i < results.length; i++) {
    if (i < results.length - quontity) {
      newResults[i] = {
        ...results[i],
        athlete_id: results[i + quontity].athlete_id,
        full_name: results[i + quontity].full_name,
      };
    } else if (i <= results.length - 1) {
      // Если в строке нет результата убираем. Иначе ставим в ID нули.
      if (results[i].finish_time === "") {
        newResults.pop();
      } else {
        newResults[i] = {
          ...results[i],
          athlete_id: null,
          full_name: "",
        };
      }
      // newResults.pop();
    }
  }

  return newResults;
};

export const deleteTimes = (
  results: TResultsType,
  startposition: number,
  endposition: number
) => {
  // Сдвигаем время вверх удалив строки с позицией position от startposition до endposition

  let index = startposition - 1;
  if (startposition > results.length) return results;
  let quontity = endposition - startposition + 1;
  let newResults = [...results];
  for (let i = index; i < results.length; i++) {
    if (i < results.length - quontity) {
      newResults[i] = {
        ...results[i],
        finish_time: results[i + quontity].finish_time,
      };
    } else if (i <= results.length - 1) {
      // Если в строке нет человека убираем. Иначе ставим в ID нули. ???
      if (results[i].athlete_id === null) {
        newResults.pop();
      } else {
        newResults[i] = {
          ...results[i],
          finish_time: "",
        };
      }
    }
    // newResults.pop();
  }

  return newResults;
};

export const addTimes = (
  results: TResultType[],
  addedTime: string
): TResultType[] => {
  // Для массива результатов каждому результату добавляет время
  return results.map((result: TResultType) => {
    return { ...result, finish_time: addTime(result.finish_time, addedTime) };
  });
};

export const subtractTimes = (
  results: TResultType[],
  subtractedTime: string
): TResultType[] => {
  // Для массива результатов из каждого результатфа вычитает время
  return results.map((result: TResultType) => {
    return {
      ...result,
      finish_time: subtractTime(result.finish_time, subtractedTime),
    };
  });
};

export const isValidSubstraction = (
  resultsArray: TResultsType,
  subtractedTime: string
) => {
  // console.log(moment.duration(addedTime))
  return (
    resultsArray.findIndex((result: TResultType) =>
      isFirstTimeGTSecondTime(subtractedTime, result.finish_time)
    ) === -1
  );
};
