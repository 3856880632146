import { Spin } from "antd";
import React, { useEffect, useRef } from "react";
import EventName from "../../components/Common/EventName/EventName";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { EXTRA_EVENTS_CALENDAR_YEARS } from "../../const";
import {
  getExtraEventsEventId,
  getIsLoading,
  getExtraEvents,
} from "../../store/extraEvents/selector";
import { setExtraEventsEventId } from "../../store/extraEvents/slice";
import {
  getCalendarAction,
  getAdditionaEventsAction,
} from "../../store/extraEvents/thunk";
import { TAdditionalEvent } from "../../store/extraEvents/types";
import styles from "./index.module.scss";
import ExtraEvent from "../../components/ExtraEvents/ExtraEvent/ExtraEvent";

const AdditionalEvents = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getIsLoading);
  const eventId = useAppSelector(getExtraEventsEventId);
  const extraEvents = useAppSelector(getExtraEvents);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (eventId) {
      dispatch(
        getCalendarAction({
          event_id: eventId,
          years: EXTRA_EVENTS_CALENDAR_YEARS,
        })
      );
    }
  }, [eventId, dispatch]);

  useEffect(() => {
    if (isFirstRender.current) {
      dispatch(getAdditionaEventsAction());
      isFirstRender.current = false;
    }
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <h1>
        Дополнительные мероприятия <span>{loading ? <Spin /> : null}</span>
      </h1>
      <div className={styles.eventName}>
        <EventName eventId={eventId} setEventId={setExtraEventsEventId} />
      </div>
      <p>
        Ближайшие даты дополнительных мероприятий - 29 декабря в этом году и 1
        января 2025 года.
        <br />
        1 января можно выбрать время старта, а также при желании провести в
        рамках 5 вёрст Забег Обещаний (фановый старт на 2 км (2025 метров)).
        <br />
        Если вы не знаете, что такое Забег Обещаний, обратитесь к вашему
        амбассадору поддержки.
        <br />
        <br />
        Если вы будете проводить мероприятие, отметьте его галочкой и нажмите
        кнопку «Отправить».
        <br />
        Если ваши планы поменялись, уберите галочку и снова нажмите кнопку.
      </p>
      {extraEvents &&
        extraEvents.length > 0 &&
        extraEvents.map((addEvent: TAdditionalEvent, index: number) => (
          <ExtraEvent event={addEvent} index={index} key={index} />
        ))}

      {/* {eventId ? <Calendar /> : null} */}
    </div>
  );
};

export default AdditionalEvents;
