import React from "react";
import styles from "./index.module.scss";

interface IProps {
  link: string;
  text: string;
}

const WebsiteLink: React.FC<IProps> = ({ link, text }) => {
  return link.length > 0 ? (
    <a
      className={styles.websiteLink}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  ) : null;
};

export default WebsiteLink;
