import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { loadAuthParksAction } from "./thunk";
import { ParksReducer, ParkType } from "./types";

const initialState: ParksReducer = {
  loading: false,
  error: null,
  userParks: [],
};

export const parksSlice = createSlice({
  name: "ParksSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [loadAuthParksAction.pending.type]: (state: ParksReducer) => {
      state.loading = true;
      state.error = null;
    },
    [loadAuthParksAction.fulfilled.type]: (
      state: ParksReducer,
      { payload }: PayloadAction<ParkType[]>
    ) => {
      state.userParks = payload;
      state.loading = false;
    },
    [loadAuthParksAction.rejected.type]: (
      state: ParksReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.userParks = [];
      state.loading = false;
      state.error = payload;
    },
  },
});

export default parksSlice.reducer;
