import React from "react";
import { useAppSelector } from "../../../customHooks/useAppSelector";
import { getUniqueVolunteersQtt } from "../../../store/volunteers/selectors";
import styles from "./index.module.scss";

const VolunteersQuantity = () => {
  const uniqueVolunteers = useAppSelector(getUniqueVolunteersQtt);
  return uniqueVolunteers ? (
    <p className={styles.quantity}>{uniqueVolunteers}</p>
  ) : null;
};

export default VolunteersQuantity;
