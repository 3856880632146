import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AuthorizationStatus } from "../../const";
import { fetchUserNameById, loginAction } from "./thunk";
import { UserReducer } from "./types";
import { TAthlete } from "../types";

const initialState: UserReducer = {
    loading: false,
    error: null,
    user: null,
    authorizationStatus: AuthorizationStatus.Unknown,
};

export const userSlice = createSlice({
    name: "UserSlice",
    initialState,
    reducers: {
        // userLogin: (
        //   state: UserReducer,
        //   action: PayloadAction<TUserResponse | null>
        // ) => {
        //   state.user = action.payload;
        //   state.authorizationStatus = AuthorizationStatus.Auth;
        // },
        userLogout: (state: UserReducer) => {
            state.authorizationStatus = AuthorizationStatus.NoAuth;
            state.user = null;
        },
        setTokenFromStorage: (
            state: UserReducer,
            action: PayloadAction<string>
        ) => {
            state.user = { token: action.payload };
            state.authorizationStatus = AuthorizationStatus.Auth;
        },
    },
    extraReducers: {
        // [checkAuthAction.pending.type]: (state: UserReducer) => {
        //   state.loading = true;
        //   state.error = null;
        // },
        // [checkAuthAction.fulfilled.type]: (
        //   state: UserReducer,
        //   { payload }: PayloadAction<any>
        // ) => {
        //   state.user = {token: payload};
        //   state.authorizationStatus = AuthorizationStatus.Auth;
        //   state.loading = false;
        // },
        // [checkAuthAction.rejected.type]: (
        //   state: UserReducer,
        //   { payload }: PayloadAction<AxiosError>
        // ) => {
        //   state.user = null;
        //   state.loading = false;
        //   state.error = payload;
        // },
        [loginAction.pending.type]: (state: UserReducer) => {
            state.loading = true;
            state.error = null;
        },
        [loginAction.fulfilled.type]: (
            state: UserReducer,
            { payload }: PayloadAction<string>
        ) => {
            state.user = { token: payload };
            state.authorizationStatus = AuthorizationStatus.Auth;
            state.loading = false;
        },
        [loginAction.rejected.type]: (
            state: UserReducer,
            { payload }: PayloadAction<AxiosError>
        ) => {
            state.user = null;
            state.loading = false;
            state.error = payload;
        },
        [fetchUserNameById.pending.type]: (state: UserReducer) => {
            state.loading = true;
            state.error = null;
        },
        [fetchUserNameById.fulfilled.type]: (
            state: UserReducer,
            { payload }: PayloadAction<TAthlete>
        ) => {
            if (state.user) state.user.full_name = payload.full_name;
            state.loading = false;
        },
        [fetchUserNameById.rejected.type]: (
            state: UserReducer,
            { payload }: PayloadAction<AxiosError>
        ) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const { userLogout, setTokenFromStorage } = userSlice.actions;
export default userSlice.reducer;
